import React, { createContext } from "react";
import { shallowEqual, useSelector } from "react-redux";

export const ProductContext = createContext();
const ProductContextProvider = ({ children }) => {
  const { loaded, products } = useSelector(
    (state) => ({
      loaded: state.persistent.loaded,
      products: state.persistent.products,
    }),
    shallowEqual
  );
  const [open, setOpen] = React.useState(false);
  const [openFileModal, setOpenFileModal] = React.useState(false);
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [nameList, setNameList] = React.useState([]);
  const [featuredImageId, setfeaturedImageId] = React.useState(null);
  const [imageList, setImageList] = React.useState([]);
  const [uploadimageList, setUploadImageList] = React.useState([]);

  const [list, setList] = React.useState([]);
  const [newList, setNewList] = React.useState([]);

  const removeImageFromList = (id) => {
    setImageList((oldImageList) => {
      return oldImageList?.filter((oldImage) => oldImage.id != id);
    });
  };
  console.log("imageListimageList", imageList);
  return (
    <ProductContext.Provider
      value={{
        open,
        setOpen,
        openFileModal,
        setOpenFileModal,
        isEnabled,
        setIsEnabled,
        openSnackBar,
        setOpenSnackBar,
        nameList,
        setNameList,

        imageList,
        setImageList,

        uploadimageList,
        setUploadImageList,

        list,
        setList,
        newList,
        setNewList,
        products,

        featuredImageId,
        setfeaturedImageId,
        removeImageFromList,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
export default ProductContextProvider;
