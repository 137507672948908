import {
  SET_SUPPLIER_MODAL,
  SET_AUTH_JSESSION,
  SET_CATEGORY_MODAL,
  SET_MODEL_MODAL,
  SET_AUTH_ADMIN_JSESSION,
  SET_PRODUCTS,
  SET_INITIAL_STATE_PERSISTENT_STATE,
  SET_PAYMENTS,
  SET_FACETS,
  SET_LOADED,
  SET_ASSETS,
  SET_ACCOUNTS,
  ADD_ACCOUNTS,
  DEL_ACCOUNTS,
  SET_COMPANIES,
  ADD_COMPANIES,
  DEL_COMPANIES,
  DEL_PRODUCTS,
  DEL_FACETS,
  SET_REFERENCE_BILLS,
  DEL_REFERENCE_BILLS,
  SET_CUSTOMERS,
  ADD_CUSTOMERS,
  DEL_CUSTOMERS,
  SET_ACCOUNTING_BILLS,
  DEL_ACCOUNTING_BILLS,
  SET_ACCOUNTING_CATEGORIES,
  DEL_ACCOUNTING_CATEGORIES,
} from "./types";

export const setJsession = (session) => {
  return {
    type: SET_AUTH_JSESSION,
    payload: session,
  };
};
export const setAdminJsession = (session) => {
  return {
    type: SET_AUTH_ADMIN_JSESSION,
    payload: session,
  };
};

export const setCategoryModal = (payload) => {
  return {
    type: SET_CATEGORY_MODAL,
    payload: payload,
  };
};

export const setModelModal = (payload) => {
  return {
    type: SET_MODEL_MODAL,
    payload: payload,
  };
};

export const setProducts = (payload) => {
  return {
    type: SET_PRODUCTS,
    payload: payload,
  };
};

export const delProducts = (payload) => {
  return {
    type: DEL_PRODUCTS,
    payload: payload,
  };
};
//ACCOUNT
export const setAccountModal = (payload) => {
  return {
    type: SET_SUPPLIER_MODAL,
    payload: payload,
  };
};

export const setAccounts = (payload) => {
  return {
    type: SET_ACCOUNTS,
    payload: payload,
  };
};

export const addAccounts = (payload) => {
  return {
    type: ADD_ACCOUNTS,
    payload: payload,
  };
};

export const delAccounts = (payload) => {
  return {
    type: DEL_ACCOUNTS,
    payload: payload,
  };
};
//ENDACCOUNT
//ACCOUNTINGBILL
export const setAccountingBills = (payload) => {
  return {
    type: SET_ACCOUNTING_BILLS,
    payload: payload,
  };
};

export const delAccountingBills = (payload) => {
  return {
    type: DEL_ACCOUNTING_BILLS,
    payload: payload,
  };
};
//ENDACCOUNTINGBILL
//ACCOUNTINGBILL
export const setAccountingCategories = (payload) => {
  return {
    type: SET_ACCOUNTING_CATEGORIES,
    payload: payload,
  };
};

export const delAccountingCategories = (payload) => {
  return {
    type: DEL_ACCOUNTING_CATEGORIES,
    payload: payload,
  };
};
//ENDACCOUNTINGBILL
//CUSTOMER
export const setCustomerModal = (payload) => {
  return {
    type: SET_SUPPLIER_MODAL,
    payload: payload,
  };
};

export const setCustomers = (payload) => {
  console.log("SET_CUSTOMERS", payload);
  return {
    type: SET_CUSTOMERS,
    payload: payload,
  };
};

export const addCustomers = (payload) => {
  return {
    type: ADD_CUSTOMERS,
    payload: payload,
  };
};

export const delCustomers = (payload) => {
  return {
    type: DEL_CUSTOMERS,
    payload: payload,
  };
};
//ENDCUSTOMER

export const setPayments = (payload) => {
  return {
    type: SET_PAYMENTS,
    payload: payload,
  };
};

export const setFacets = (payload) => {
  return {
    type: SET_FACETS,
    payload: payload,
  };
};

export const delFacets = (payload) => {
  return {
    type: DEL_FACETS,
    payload: payload,
  };
};

export const setImageAssets = (payload) => {
  return {
    type: SET_ASSETS,
    payload: payload,
  };
};

export const setInitialStatePersistentState = () => {
  return {
    type: SET_INITIAL_STATE_PERSISTENT_STATE,
  };
};

export const setLoaded = (payload) => {
  return {
    type: SET_LOADED,
    payload: payload,
  };
};

export const setCompanies = (payload) => {
  return {
    type: SET_COMPANIES,
    payload: payload,
  };
};

export const addCompanies = (payload) => {
  return {
    type: ADD_COMPANIES,
    payload: payload,
  };
};

export const delCompanies = (payload) => {
  return {
    type: DEL_COMPANIES,
    payload: payload,
  };
};

export const setReferenceBills = (payload) => {
  return {
    type: SET_REFERENCE_BILLS,
    payload: payload,
  };
};

export const delReferenceBills = (payload) => {
  return {
    type: DEL_REFERENCE_BILLS,
    payload: payload,
  };
};
