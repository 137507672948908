import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useDispatch } from "react-redux";
import { setAccountModal } from "Redux/Action/action";
import API from "Services/API";

import EnhancedTable from "Components/Table/EnhancedTable";
const USERS = API.QUERY.USERS;
const DELETE_SUPPLIER = API.MUTATION.DELETE_SUPPLIER;
const AdminUser = () => {
  const dispatch = useDispatch();
  const [deleteSupplier] = useMutation(DELETE_SUPPLIER);
  const { data, error, loading, refetch } = useQuery(USERS);
  const headRows = [
    // { id: "id", numeric: true, disablePadding: true, label: "id" },

    {
      id: "userName",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "170px",
    },
    {
      id: "roles",
      numeric: false,
      disablePadding: true,
      label: "Roles",
    },
  ];
  const handleOpen = () => {};
  if (loading) return <>LOADING</>;
  console.log("DATA", data);
  const user_data = data?.users;
  const rows = user_data;
  function addFn(event) {
    event.preventDefault();
    dispatch(setAccountModal({ modal: true, data: null, type: "add" }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    dispatch(
      setAccountModal({
        modal: true,
        data: { id: id },
        type: "update",
      })
    );
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteSupplier({ variables: { id } });
    if (deleteFn?.data?.deleteSupplier?.success) {
      refetch();
    }
  }
  const refetchFn = () => {
    refetch();
  };
  return (
    <>
      <br />
      <EnhancedTable
        baseUrl={"/equipments"}
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Account"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default AdminUser;
