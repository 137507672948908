import { ExitToApp } from "@material-ui/icons";
import { Column, GridColumn, Row } from "Components/DesignComponent";
import AccountList from "./AccountList";
const Accounts = () => {
  return (
    <>
      {/* <Row>
        <Column lg={3} item>
          <div className="dashboard-box">
            <GridColumn
              component={
                <>
                  <div className="dashboard-circle">
                    <div className="flex justify-center">
                      <ExitToApp className="box-icon" />
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">0</span>
                        </Column>
                      </Row>

                      <Row spacing={2}>
                        <Column className="flex justify-end align-center">
                          <span className="boxSubtitle text-dark">
                            Available Balance
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Column>
        <Column lg={3} item>
          <div className="dashboard-box">
            <GridColumn
              component={
                <>
                  <div className="dashboard-circle">
                    <div className="flex justify-center">
                      <ExitToApp className="box-icon" />
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">0</span>
                        </Column>
                      </Row>

                      <Row spacing={2}>
                        <Column className="flex justify-end align-center">
                          <span className="boxSubtitle text-dark">
                            Used Balance
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Column>
        <Column lg={3} item>
          <div className="dashboard-box">
            <GridColumn
              component={
                <>
                  <div className="dashboard-circle">
                    <div className="flex justify-center">
                      <ExitToApp className="box-icon" />
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">0</span>
                        </Column>
                      </Row>

                      <Row spacing={2}>
                        <Column className="flex justify-end align-center">
                          <span className="boxSubtitle text-dark">
                            Invested
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Column>
      </Row> */}
      <Row>
        <Column>
          <AccountList />
        </Column>
      </Row>
    </>
  );
};
export default Accounts;
