import { Column, Row } from "Components/DesignComponent";
import React from "react";
import {
  Document,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import Bottom from "./bottom.png";
import Logo from "./logo.png";
import "./style.css";
import JSONData from "./ClinicalVisit.json";

function Report() {
  // Create styles
  console.log("JSONData", JSONData);
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    company: {
      color: "#6d64e8",
      fontWeight: "700",
      textDecoration: "none",
      verticalAlign: "baseline",
      fontSize: "20pt",
      // fontFamily: "Roboto",
      fontStyle: "normal",
    },
    address: {
      color: "#666666",
      fontWeight: "400",
      textDecoration: "none",
      verticalAlign: "baseline",
      fontSize: "10pt",
      // "fontFamily": "\"Roboto\"",
      fontStyle: "normal",
    },
    patient_title: {
      color: "#283592",
      fontWeight: "400",
      textDecoration: "none",
      verticalAlign: "baseline",
      fontSize: "34pt",
      fontStyle: "normal",
    },
    patient_name: {
      fontSize: "20pt",
      color: "#6d64e8",
      fontWeight: "700",
    },
    patient_checkout: {
      paddingTop: "10pt",
      color: "#e01b84",
      paddingLeft: "0",
      fontSize: "11pt",
      paddingBottom: "0pt",
      lineHeight: "1.4",
      marginRight: "0",
      marginLeft: "-0.8pt",
      marginTop: "0",
      orphans: "2",
      marginBottom: "0",
      widows: "2",
      textAlign: "left",
      paddingRight: "0",
    },
    patient_check: {
      color: "#283592",
      fontWeight: "400",
      textDecoration: "none",
      verticalAlign: "baseline",
      fontSize: "11pt",
      fontStyle: "normal",
    },
    patient_information: {
      fontWeight: "bold",
      marginBlockStart: "0.67em",
      marginBlockEnd: "0.67em",
      marginInlineStart: "0px",
      marginInlineEnd: "0px",
      paddingTop: "24pt",
      color: "#000000",
      paddingLeft: "0",
      fontSize: "16pt",
      paddingBottom: "0pt",
      lineHeight: "1.0",
      marginRight: "0",
      marginLeft: "-0.8pt",
      marginTop: "0",
      orphans: "2",
      marginBottom: "0",
      widows: "2",
      textAlign: "left",
      paddingRight: "0",
    },
    patient_subinformation: {
      paddingTop: "10pt",
      color: "#666666",
      paddingLeft: "0",
      fontSize: "11pt",
      paddingBottom: "0pt",
      lineHeight: "1.4",
      marginRight: "0",
      marginLeft: "-0.8pt",
      marginTop: "0",
      orphans: "2",
      marginBottom: "0",
      widows: "2",
      textAlign: "left",
      paddingRight: "0",
    },
    patient_subtopic: {
      paddingTop: "24pt",
      color: "#000000",
      paddingLeft: "0",
      fontSize: "16pt",
      paddingBottom: "0pt",
      lineHeight: "1.0",
      marginRight: "0",
      marginLeft: "-0.8pt",
      marginTop: "0",
      orphans: "2",
      marginBottom: "0",
      widows: "2",
      textAlign: "left",
      paddingRight: "0",
    },
    patient_topic: {
      paddingTop: "24pt",
      color: "#000000",
      paddingLeft: "0",
      fontSize: "16pt",
      paddingBottom: "0pt",
      lineHeight: "1.0",
      marginRight: "0",
      marginLeft: "-0.8pt",
      marginTop: "0",
      orphans: "2",
      marginBottom: "0",
      widows: "2",
      textAlign: "left",
      paddingRight: "0",
    },
    ml2: {
      marginLeft: "2rem",
    },
  });
  return (
    <div>
      <div>
        <PDFViewer width={1600} height={1200}>
          <Document size>
            <Page size="A4" style={{ padding: "10pt" }}>
              <View style={{ background: "white", padding: "60pt 60pt" }}>
                <View>
                  <Image src={Logo} style={{ width: 80 }} />
                </View>

                <View>
                  <Text style={styles.company}>Your Company</Text>
                </View>

                <View>
                  <View>
                    <View>
                      <Text style={styles.address}>123 Your Street</Text>
                    </View>
                    <View>
                      <Text style={styles.address}>Your City, ST 12345</Text>
                    </View>
                    <View>
                      <Text style={styles.address}>(123) 456 - 7890</Text>
                    </View>
                  </View>
                  <View style={{ paddingTop: "10pt" }}>
                    <View>
                      <Text style={styles.patient_title}>
                        Patient Visit Summary
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.patient_name}>
                        {/* Patient Name */}
                        {JSONData?.visitData?.patientName}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.patient_checkout}>
                    <View>
                      <Text>September 04, 20XX</Text>
                    </View>
                    <View>
                      <Text style={styles.patient_check}>
                        {/* [checkin time] - [checkout time] */}
                        {`${JSONData?.visitData?.startDateTime} - ${JSONData?.visitData?.endDateTime}`}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.patient_information}>
                    <View>
                      <Text>Patient Information</Text>
                    </View>
                  </View>
                  <View style={styles.patient_subinformation}>
                    <View>
                      <Text>Patient ID / {JSONData?.visitData?.patientId}</Text>
                    </View>
                    <View>
                      <Text>DOB / {JSONData?.visitData?.patientId}</Text>
                    </View>
                    <View>
                      <Text>Sex / {JSONData?.visitData?.patientId}</Text>
                    </View>
                    <View>
                      <Text>Address / {JSONData?.visitData?.patientId}</Text>
                    </View>
                  </View>
                  {/* Chief Complaints */}
                  <View style={styles.patient_topic}>
                    <View>
                      <Text>Chief Compaints</Text>
                    </View>
                  </View>
                  <View style={styles.patient_subinformation}>
                    <View>
                      <Text>{JSONData?.visitData?.chiefComplaints}</Text>
                    </View>
                  </View>
                  {/* End Chief Complaints */}
                  {/* Chief Complaints */}
                  <View style={styles.patient_topic}>
                    <View>
                      <Text>Vitals</Text>
                    </View>
                  </View>
                  <View style={styles.patient_subinformation}>
                    <View>
                      <Text>
                        Height (ft/in) /{" "}
                        {JSONData?.visitData?.vitals?.height?.heightText}
                      </Text>
                    </View>
                    <View>
                      <Text>
                        Weight (kg) /{" "}
                        {JSONData?.visitData?.vitals?.weight?.weight}
                      </Text>
                    </View>
                    <View>
                      <Text>
                        BMI /{" "}
                        {`${JSONData?.visitData?.vitals?.bloodPressure?.systolicPressure}/${JSONData?.visitData?.vitals?.bloodPressure?.diastolicPressure}`}
                      </Text>
                    </View>
                    <View>
                      <Text>Temperature / [temp] F</Text>
                    </View>
                    <View>
                      <Text>
                        Blood Pressure /{" "}
                        {`${JSONData?.visitData?.vitals?.bloodPressure?.systolicPressure}/${JSONData?.visitData?.vitals?.bloodPressure?.diastolicPressure}`}
                      </Text>
                    </View>
                    <View>
                      <Text>Respiration Rate / [rate]/min</Text>
                    </View>
                    <View>
                      <Text>Pulse / [pulse count]/min</Text>
                    </View>
                    <View>
                      <Text>
                        O2 Saturation /{" "}
                        {`${JSONData?.visitData?.vitals?.oxygenSaturation?.oxygenSaturation}`}
                        %
                      </Text>
                    </View>
                  </View>
                  {/* End Chief Complaints */}
                  {/* Chief observations */}
                  <View style={styles.patient_topic}>
                    <View>
                      <Text>Observations</Text>
                    </View>
                  </View>
                  <View style={styles.patient_subinformation}>
                    <View>
                      <Text> {`${JSONData?.visitData?.observations}`}</Text>
                    </View>
                  </View>
                  {/* End Chief observations */}
                  {/* Chief Labs */}
                  <View style={styles.patient_topic}>
                    <View>
                      <Text>Labs</Text>
                    </View>
                  </View>
                  <View style={styles.patient_subinformation}>
                    <View>
                      <Text> {`${JSONData?.visitData?.recommendedTests}`}</Text>
                    </View>
                  </View>
                  {/* End Chief Labs */}
                  {/* Chief Diagnosis */}
                  <View style={styles.patient_topic}>
                    <View>
                      <Text>Diagnosis</Text>
                    </View>
                  </View>
                  <View style={styles.patient_subinformation}>
                    <View>
                      <Text>
                        {" "}
                        {`${JSONData?.visitData?.diagnosis?.diagnosisDisplay}`}
                      </Text>
                    </View>
                  </View>
                  {/* End Chief Diagnosis */}
                  <View style={styles.patient_information}>
                    <View>
                      <Text>Pescription</Text>
                    </View>
                  </View>
                  <View style={styles.ml2}>
                    <View
                      style={[
                        styles.patient_subinformation,
                        styles.pescription,
                      ]}
                    >
                      {JSONData?.visitData?.prescriptions?.map(
                        (prescription, i) => {
                          return (
                            <View>
                              <View>
                                <Text>
                                  {i + 1}. Pescription {i + 1}
                                </Text>
                              </View>
                              <View>
                                <Text>{prescription?.name}</Text>
                              </View>
                            </View>
                          );
                        }
                      )}
                      {/* <View>
                        <Text>1. Pescription 1</Text>
                      </View>
                      <View>
                        <Text>Pescription Info</Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.patient_subinformation,
                        styles.pescription,
                      ]}
                    >
                      <View>
                        <Text>2. Pescription 2</Text>
                      </View>
                      <View>
                        <Text>Pescription Info</Text>
                      </View> */}
                    </View>
                    {/* Chief Followup */}
                    <View style={styles.patient_topic}>
                      <View>
                        <Text>Followup</Text>
                      </View>
                    </View>
                    <View style={styles.patient_subinformation}>
                      <View>
                        <Text>
                          {" "}
                          {JSONData?.visitData?.followupAssignedName}
                        </Text>
                      </View>
                    </View>
                    {/* End Chief Followup */}
                    {/* Chief Notes */}
                    <View style={styles.patient_topic}>
                      <View>
                        <Text>Notes</Text>
                      </View>
                    </View>
                    <View style={styles.patient_subinformation}>
                      <View>
                        <Text>{JSONData?.visitData?.followupAssignedName}</Text>
                      </View>
                    </View>
                    {/* End Chief Notes */}
                    {/* Chief Reviewd By */}
                    <View style={styles.patient_topic}>
                      <View>
                        <Text>Reviewed By</Text>
                      </View>
                    </View>
                    <View style={styles.patient_subinformation}>
                      <View>
                        <Text>{JSONData?.visitData?.reviewedBy}</Text>
                      </View>
                    </View>
                    {/* End Chief Reviewd By */}
                  </View>
                  <View>
                    <Image src={Bottom} width="100%" />
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </div>
    </div>
  );
}

export default Report;
