import { Grid } from "@material-ui/core";
import { CardTravel, ExitToApp } from "@material-ui/icons";
import { Row, Column, GridColumn } from "Components/DesignComponent";
const AdminDashboard = () => {
  return (
    <>
      <Grid container>
        <Grid lg={3} item>
          <div className="dashboard-box">
            <GridColumn
              component={
                <>
                  <div className="flex align-center">
                    <div>
                      <Row>
                        <Column className="flex align-center">
                          <span className="boxSubtitle text-dark">
                            Opening Balance
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">1,75,050</span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Grid>
        <Grid lg={3} item>
          <div className="dashboard-box">
            <GridColumn
              component={
                <>
                  <div className="flex align-center">
                    <div>
                      <Row>
                        <Column className="flex align-center">
                          <span className="boxSubtitle text-dark">
                            Today's Sales
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">20,000</span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Grid>
        <Grid lg={3} item>
          <div className="dashboard-box">
            <GridColumn
              component={
                <>
                  <div className="flex align-center">
                    <div>
                      <Row>
                        <Column className="flex align-center">
                          <span className="boxSubtitle text-dark">
                            Weekly Sales
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">20,000</span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Grid>
        <Grid lg={3} item>
          <div className="dashboard-box">
            <GridColumn
              component={
                <>
                  <div className="flex align-center">
                    <div>
                      <Row>
                        <Column className="flex align-center">
                          <span className="boxSubtitle text-dark">
                            This Month Sales
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">1,00,000</span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="10rem"
              col2="auto"
            />
          </div>
        </Grid>
        <Grid lg={3} item>
          <div className="dashboard-box">
            <GridColumn
              component={
                <>
                  <div className="flex align-center">
                    <div>
                      <Row>
                        <Column className="flex align-center">
                          <span className="boxSubtitle text-dark">
                            Last Month Sales
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">20,000</span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="12rem"
              col2="auto"
            />
          </div>
        </Grid>
        <Grid lg={3} item>
          <div className="dashboard-box">
            <GridColumn
              component={
                <>
                  <div className="flex align-center">
                    <div>
                      <Row>
                        <Column className="flex align-center">
                          <span className="boxSubtitle text-dark">
                            Expenditure
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">20,000</span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Grid>
        <Grid lg={3} item>
          <div className="dashboard-box">
            <GridColumn
              component={
                <>
                  <div className="flex align-center">
                    <div>
                      <Row>
                        <Column className="flex align-center">
                          <span className="boxSubtitle text-dark">
                            Today Purchases
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">20,000</span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Grid>
        <Grid lg={3} item>
          <div className="dashboard-box">
            <GridColumn
              component={
                <>
                  <div className="flex align-center">
                    <div>
                      <Row>
                        <Column className="flex align-center">
                          <span className="boxSubtitle text-dark">
                            Today Purchases
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">20,000</span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Grid>
      </Grid>
      {/* <div class="dasboard-body">
        <center>
          <img src={UnderConstruction} width="400px" />
        </center>
      </div> */}
    </>
  );
};
export default AdminDashboard;
