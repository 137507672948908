import { Grid, Paper, Button } from "@material-ui/core";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import JustForYou from "./JustForYou";
import ParallaxItem from "./ParralaxItem";
import ProductContent from "./ProductContent_4";
import ProductTopCategories from "./ProductTopCategories";

const BodyContainer = () => {
  const history = useHistory();
  return (
    <>
      <Grid container className="mt2 " spacing={2}>
        <Grid item md={4}>
          <Button variant="outlined" onClick={() => history.push("/supplier")}>
            Supplier
          </Button>
        </Grid>
        <Grid item md={4}>
          <Button variant="outlined" onClick={() => history.push("/category")}>
            Category
          </Button>
        </Grid>
        <Grid item md={4}>
          <Button variant="outlined" onClick={() => history.push("model")}>
            Model
          </Button>
        </Grid>
      </Grid>
      <ParallaxItem>
        <Grid className="mt1">
          <ProductTopCategories />
        </Grid>

        <Grid container className="mt2 " spacing={2}>
          <Grid item key={1} md={6}>
            <Paper className="p1 ">
              <span className="category_topic">New Arrival</span>
              <ProductContent />
            </Paper>
          </Grid>
          <Grid item key={2} md={6}>
            <Paper className="p1">
              <span className="category_topic">Top Trending</span>
              <ProductContent />
            </Paper>
          </Grid>
        </Grid>
      </ParallaxItem>
      <ParallaxItem>
        <JustForYou />
      </ParallaxItem>
    </>
  );
};
export default BodyContainer;
