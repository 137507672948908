import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setAccounts } from "Redux/Action/action";
import API from "Services/API";

export const SupplierContext = createContext();
const SUPPLIERS = API.QUERY.ACCOUNTS;
const SupplierContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { loaded, accounts } = useSelector(
    (state) => ({
      loaded: state.persistent.loaded,
      accounts: state.persistent.accounts,
    }),
    shallowEqual
  );
  const [open, setOpen] = React.useState(false);
  const [openFileModal, setOpenFileModal] = React.useState(false);
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [nameList, setNameList] = React.useState([]);
  const [imageList, setImageList] = React.useState([]);

  const [list, setList] = React.useState([]);
  const [newList, setNewList] = React.useState([]);
  const [fetchSupplier] = useLazyQuery(SUPPLIERS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ Accounts: newAccounts }) => {
      dispatch(setAccounts(newAccounts));
    },
  });

  useEffect(() => {
    if (!loaded.accounts) {
      const accountList = accounts?.sort((a, b) =>
        moment(a.updatedAt, "DD-MM-YYYY").isBefore(
          moment(b.updatedAt, "DD-MM-YYYY")
        )
          ? 1
          : -1
      );
      fetchSupplier({
        variables: {
          fromDate: accountList?.[0]?.updatedAt
            ? moment(accountList?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    }
    return () => {
      // cleanup
    };
  }, [loaded]);
  return (
    <SupplierContext.Provider
      value={{
        open,
        setOpen,
        openFileModal,
        setOpenFileModal,
        isEnabled,
        setIsEnabled,
        openSnackBar,
        setOpenSnackBar,
        nameList,
        setNameList,
        imageList,
        setImageList,
        list,
        setList,
        newList,
        setNewList,
        accounts,
      }}
    >
      {children}
    </SupplierContext.Provider>
  );
};
export default SupplierContextProvider;
