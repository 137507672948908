import { useMutation, useQuery } from "@apollo/client";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setModelModal, setAccountModal } from "Redux/Action/action";
import API from "Services/API";
import UploadFile from "Components/AdminContent/FileUpload/uploadFile";
const ADD_MODEL = API.MUTATION.ADD_MODEL;
const UPDATE_MODEL = API.MUTATION.UPDATE_MODEL;
const SUPPLIERS = API.QUERY.ACCOUNTS;
const CATEGORIES = API.QUERY.CATEGORIES;
const MODEL = API.QUERY.MODEL;

const ModelModal = ({ type, modal_data }) => {
  const dispatch = useDispatch();
  const inputEl = useRef([]);
  const [imageData, setImageData] = useState(null);

  const handleClose = () => {
    dispatch(setModelModal({ modal: false, data: null, type: null }));
  };
  const [addModel] = useMutation(ADD_MODEL);
  const [updateModel] = useMutation(UPDATE_MODEL);
  const {
    data: categoryData,
    error: categoryError,
    loading: categoryLoading,
  } = useQuery(CATEGORIES);

  const { data: supplierData, error, loading } = useQuery(SUPPLIERS);
  const {
    data: childOneCatData,
    error: childOneCatError,
    loading: childOneCatLoading,
  } = useQuery(MODEL, {
    fetchPolicy: "no-cache",
    skip: type === "add",
    variables: {
      id: modal_data?.id,
    },
  });

  const handleAdd = (e) => {
    e.preventDefault();
    const fields = {
      name: inputEl?.current["model_name"]?.value,
      description: inputEl?.current["model_description"]?.value,
      supplierId: inputEl?.current["model_supplier"]?.value,
      parentId: inputEl?.current["model_category"]?.value,
      pic: imageData,
      // adminId: 1,
    };
    console.log(fields);
    console.log(inputEl.current);
    addModel({ variables: fields });
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    const fields = {
      id: modal_data?.id,
      name: inputEl?.current["model_name"]?.value,
      description: inputEl?.current["model_description"]?.value,
      supplierId: inputEl?.current["model_supplier"]?.value,
      parentId: inputEl?.current["model_category"]?.value,
      pic: imageData,
    };
    // console.log(fields);
    if (modal_data?.id) updateModel({ variables: fields });
  };
  if (loading || childOneCatLoading) return "Loading";
  const model_data = childOneCatData?.childOneCategory;
  console.log("model_data", model_data?.name);
  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-modal-a"
        aria-describedby="simple-modal-descraiption"
      >
        <div className="modalCenter">
          <Paper>
            <h1>{type === "add" ? "ADD MODEL" : "UPDATE MODEL"}</h1>
            <div className="flex flex.wrap">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div>
                    <img
                      src={
                        imageData
                          ? URL.createObjectURL(imageData)
                          : model_data?.image_file_url
                          ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/${model_data?.image_file_url}`
                          : "https://picsum.photos/125/125"
                      }
                      class="circle-animation"
                      alt={
                        model_data?.image_file_url
                          ? model_data?.image_file_url
                          : "MODEL"
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <UploadFile setImageData={setImageData} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="standard-full-width"
                    label="Name"
                    placeholder="Write Model name."
                    helperText="Model name must be unique."
                    defaultValue={model_data?.name}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={(el) => (inputEl.current["model_name"] = el)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    id="margin-description"
                    defaultValue={model_data?.description}
                    helperText="Some important text"
                    inputRef={(el) =>
                      (inputEl.current["model_description"] = el)
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel id="supplier_demo">Supplier</InputLabel>
                    <Select
                      labelId="supplier_demo"
                      id="demo-simple-select-helper"
                      fullWidth
                      inputRef={(el) =>
                        (inputEl.current["model_supplier"] = el)
                      }
                      defaultValue={model_data?.supplierId}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {supplierData?.suppliers?.map((ele) => {
                        return <MenuItem value={ele.id}>{ele.name}</MenuItem>;
                      })}
                      {/* <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                    <FormHelperText>Some important helper text</FormHelperText>
                  </FormControl>
                  {/* <TextField
                    label="Supplier"
                    id="margin-none"
                    defaultValue={model_data?.supplier}
                    helperText="Some important text"
                    inputRef={(el) => (inputEl.current["model_supplier"] = el)}
                  /> */}
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel id="category_label">Category</InputLabel>
                    <Select
                      labelId="category_label"
                      id="demo-simple-select-helper"
                      fullWidth
                      defaultValue={model_data?.parentId}
                      inputRef={(el) =>
                        (inputEl.current["model_category"] = el)
                      }
                      // value={age}
                      // onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {categoryData?.parentCategories?.map((ele) => {
                        return <MenuItem value={ele.id}>{ele.name}</MenuItem>;
                      })}
                    </Select>
                    <FormHelperText>Some important helper text</FormHelperText>
                  </FormControl>

                  {/* <TextField
                    label="Category"
                    id="margin-none"
                    defaultValue={model_data?.category}
                    helperText="Some important text"
                    inputRef={(el) => (inputEl.current["model_category"] = el)}
                  /> */}
                </Grid>

                <Grid item xs={12}>
                  {type === "add" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAdd}
                    >
                      ADD
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdate}
                    >
                      UPDATE
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
      </Modal>
    </>
  );
};
export default ModelModal;
