import React from "react";
const GridColumnTemplate = ({ component, col1, col2, col3, col4 }) => {
  const gridTemplateColumns = `${col1 ? col1 : ""} ${col2 ? col2 : ""} ${
    col3 ? col3 : ""
  } ${col4 ? col4 : ""}`;
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns
        }}
      >
        {component}
      </div>
    </>
  );
};
export default GridColumnTemplate;
