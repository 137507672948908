import { useMutation } from "@apollo/client";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import TabCustom from "Components/Tab/TabCustom";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setLoaded } from "Redux/Action/action";
import API from "Services/API";
import AccountingBillContextProvider, {
  AccountingBillContext,
} from "./Context";
const AccountBillCreate = () => {
  return (
    <AccountingBillContextProvider>
      <AccountingBillCreateContent />
    </AccountingBillContextProvider>
  );
};
const ADD_ACCOUNTING_BILL = API.MUTATION.ADD_ACCOUNTING_BILL;
const UPDATE_ACCOUNTING_BILL = API.MUTATION.UPDATE_ACCOUNTING_BILL;
const AccountingBillCreateContent = () => {
  const {
    openSnackBar,
    setOpenSnackBar,
    accountingBills,
    accountingCategories,
    payments,
  } = useContext(AccountingBillContext);
  const dispatch = useDispatch();
  const formEl = React.useRef();
  const { id } = useParams();
  const [isChecked, setIsChecked] = useState(true);
  const [accountingCategoryId, setAccountingCategoryId] = useState(null);
  const [accountingPaymentTypeId, setAccountingPaymentTypeId] = useState(null);
  const [entrytype, setEntrytype] = useState(null);
  const getData = accountingBills?.find((ele) => ele.id === parseInt(id));
  useEffect(() => {
    if (getData) {
      const { date, amount, note } = formEl.current;
      date.value = moment(getData?.date)?.format("YYYY-MM-DD") || "";
      amount.value = getData?.amount || "";
      // accounting_cat_id.value = getData?.accounting_cat_id || "";
      console.log("getData", getData);
      setEntrytype(getData?.entry_type);

      setAccountingCategoryId(getData?.accounting_cat_id || null);
      setAccountingPaymentTypeId(getData?.payment_type_id || null);
      note.value = getData?.note || "";
      setIsChecked(getData?.isSupplier ? getData?.isSupplier : false);
    }

    return () => {};
  }, [getData]);
  const [createAccount] = useMutation(ADD_ACCOUNTING_BILL, {
    onCompleted: ({ createAccount }) => {
      const { date, amount, note } = formEl.current;
      setOpenSnackBar(true);
      date.value = moment()?.format("YYYY-MM-DD");
      amount.value = null;
      note.value = null;
      setEntrytype(null);
      setAccountingCategoryId(null);
      setAccountingPaymentTypeId(null);
      dispatch(setLoaded({ accountingBills: false }));

      // account_refetch();
    },
  });
  const [updateAccount] = useMutation(UPDATE_ACCOUNTING_BILL, {
    onCompleted: ({ updateAccount }) => {
      setOpenSnackBar(true);
      dispatch(setLoaded({ accountingBills: false }));

      // account_refetch();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const { date, amount, note } = formEl.current;

    const accountCategoryVariable = {
      date: moment(date?.value)?.format(),
      amount: amount?.value ? parseFloat(amount?.value) : 0,
      note: note?.value,
      entry_type: entrytype,

      payment_type_id: accountingPaymentTypeId,
      accounting_cat_id: accountingCategoryId,
    };
    if (id) {
      updateAccount({
        variables: { ...accountCategoryVariable, id: parseInt(id) },
      });
    } else
      createAccount({
        variables: accountCategoryVariable,
      });
  };
  const TabList = [
    {
      label: (
        <> {id ? "Account Categories Update" : "Account Categories Create"}</>
      ),
      component: (
        <>
          <form ref={formEl} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper className="p2">
                  <TextField
                    required
                    type="date"
                    id="date"
                    defaultValue={moment()?.format("YYYY-MM-DD")}
                    label="Date"
                    InputLabelProps={{ shrink: true }}
                  />
                  <br />

                  <Select
                    id="entry_type"
                    label="Entry type"
                    labelId="accounting_debit_credit-standard-label"
                    style={{ minWidth: "200px" }}
                    value={entrytype}
                    onChange={(e) => {
                      setEntrytype(e.target.value);
                    }}
                  >
                    <MenuItem value={"debit"}>DEBIT</MenuItem>
                    <MenuItem value={"credit"}>CREDIT</MenuItem>
                  </Select>
                  <br />
                  <TextField
                    id="amount"
                    label="Amount"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                  />
                  <br />

                  <InputLabel id="accounting_payment_type-standard-label">
                    Payment type
                  </InputLabel>

                  <Select
                    id="payment_type_id"
                    label="Accounting type"
                    labelId="accounting_payment_type-standard-label"
                    style={{ minWidth: "200px" }}
                    value={
                      accountingPaymentTypeId
                        ? parseInt(accountingPaymentTypeId)
                        : null
                    }
                    onChange={(e) => {
                      setAccountingPaymentTypeId(e.target.value);
                    }}
                  >
                    {payments?.map((ele) => {
                      return (
                        <MenuItem value={parseInt(ele?.id)}>
                          {ele?.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <br />
                  <InputLabel id="accounting_category-standard-label">
                    Accounting Category
                  </InputLabel>

                  <Select
                    id="accounting_cat_id"
                    label="Accounting Category"
                    labelId="accounting_category-standard-label"
                    style={{ minWidth: "200px" }}
                    value={
                      accountingCategoryId
                        ? parseInt(accountingCategoryId)
                        : null
                    }
                    onChange={(e) => {
                      setAccountingCategoryId(e.target.value);
                    }}
                  >
                    {accountingCategories?.map((ele) => {
                      return (
                        <MenuItem value={parseInt(ele?.id)}>
                          {ele?.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <br />
                  <TextField
                    multiline
                    rows={3}
                    id="note"
                    label="Note"
                    InputLabelProps={{ shrink: true }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<Add />}
                  type="submit"
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      ),
    },
  ];
  return (
    <>
      <TabCustom TabList={TabList} />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="success">
          Accounting bill successfully {`${id ? "updated." : "created."}`}!
        </Alert>
      </Snackbar>
    </>
  );
};
export default AccountBillCreate;
