import { Divider, Switch, Tabs, Tab, Box, Typography } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TabCustom = ({ TabList }) => {
  const { id } = useParams();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        {TabList?.map((ele, i) => (
          <Tab label={ele.label} {...a11yProps(i)} />
        ))}
      </Tabs>
      {TabList?.map((ele, i) => (
        <TabPanel value={value} index={i}>
          <div>{ele.component}</div>
        </TabPanel>
      ))}
    </>
  );
};
export default TabCustom;
