import {
  Divider,
  Switch,
  TextField,
  Grid,
  Paper,
  FormControlLabel,
  Button,
  Snackbar,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Add } from "@material-ui/icons";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import TabCustom from "../../Tab/TabCustom";
import CustomerContextProvider, { CustomerContext } from "./customer.context";
import API from "Services/API";
import { useMutation } from "@apollo/client";
import { Alert } from "@material-ui/lab";
import { createSelector } from "reselect";
import { setLoaded } from "Redux/Action/action";
const CustomerCreate = () => {
  return (
    <CustomerContextProvider>
      <CustomerCreateContent />
    </CustomerContextProvider>
  );
};
const ADD_CUSTOMER = API.MUTATION.ADD_CUSTOMER;
const UPDATE_CUSTOMER = API.MUTATION.UPDATE_CUSTOMER;
const CustomerCreateContent = () => {
  const {
    open,
    setOpen,
    openFileModal,
    setOpenFileModal,
    isEnabled,
    setIsEnabled,
    openSnackBar,
    setOpenSnackBar,
    nameList,
    setNameList,
    imageList,
    setImageList,
    list,
    setList,
    newList,
    setNewList,
    customers,
  } = useContext(CustomerContext);
  const dispatch = useDispatch();
  const formEl = React.useRef();
  const { id } = useParams();
  const getData = customers?.find((ele) => ele.id === parseInt(id));
  useEffect(() => {
    if (getData) {
      const { customer_name, customer_phone_no, customer_note } =
        formEl.current;
      customer_name.value = getData?.name || "";
      customer_phone_no.value = getData?.phone_no || "";
      customer_note.value = getData?.note || "";
    }
    return () => {};
  }, [getData]);
  const [createCustomer] = useMutation(ADD_CUSTOMER, {
    onCompleted: ({ createCustomer }) => {
      const { customer_name, customer_phone_no, customer_note } =
        formEl.current;
      setOpenSnackBar(true);
      setList([]);
      setNewList([]);
      setIsEnabled(true);
      customer_name.value = null;
      customer_phone_no.value = null;
      customer_note.value = null;
      dispatch(setLoaded({ customers: false }));

      // customer_refetch();
    },
  });
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: ({ updateCustomer }) => {
      setOpenSnackBar(true);
      dispatch(setLoaded({ customers: false }));

      // customer_refetch();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const { customer_name, customer_phone_no, customer_note } = formEl.current;
    const customerVariable = {
      name: customer_name?.value,
      phone_no: customer_phone_no?.value,
      note: customer_note?.value,
    };
    if (id) {
      updateCustomer({
        variables: { ...customerVariable, id: parseInt(id) },
      });
    } else
      createCustomer({
        variables: customerVariable,
      });
  };
  const TabList = [
    {
      label: <> {id ? "Customer Update" : "Customer Create"}</>,
      component: (
        <>
          <form ref={formEl} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper className="p2">
                  <TextField
                    required
                    id="customer_name"
                    label="Customer Name"
                    InputLabelProps={{ shrink: !!getData }}
                  />

                  <br />
                  <TextField
                    required
                    id="customer_phone_no"
                    label="Phone no."
                    InputLabelProps={{ shrink: !!getData }}
                  />

                  <br />
                  <TextField
                    id="customer_note"
                    label="Note"
                    InputLabelProps={{ shrink: !!getData }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<Add />}
                  type="submit"
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      ),
    },
  ];
  return (
    <>
      <TabCustom TabList={TabList} />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="success">
          Customer successfully {`${id ? "updated." : "created."}`}!
        </Alert>
      </Snackbar>
    </>
  );
};
export default CustomerCreate;
