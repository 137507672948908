import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import React, { createContext, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setCustomers } from "Redux/Action/action";
import API from "Services/API";

export const CustomerContext = createContext();
const CUSTOMERS = API.QUERY.CUSTOMERS;
const CustomerContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { loaded, customers } = useSelector(
    (state) => ({
      loaded: state.persistent.loaded,
      customers: state.persistent.customers,
    }),
    shallowEqual
  );
  const [open, setOpen] = React.useState(false);
  const [openFileModal, setOpenFileModal] = React.useState(false);
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [nameList, setNameList] = React.useState([]);
  const [imageList, setImageList] = React.useState([]);

  const [list, setList] = React.useState([]);
  const [newList, setNewList] = React.useState([]);

  const [fetchCustomer] = useLazyQuery(CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ customers: newCustomers }) => {
      dispatch(setCustomers(newCustomers));
    },
  });

  useEffect(() => {
    if (!loaded.customers)
      fetchCustomer({
        variables: {
          fromDate: customers?.[0]?.updatedAt
            ? moment(customers?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    return () => {
      // cleanup
    };
  }, [loaded]);

  return (
    <CustomerContext.Provider
      value={{
        open,
        setOpen,
        openFileModal,
        setOpenFileModal,
        isEnabled,
        setIsEnabled,
        openSnackBar,
        setOpenSnackBar,
        nameList,
        setNameList,
        imageList,
        setImageList,
        list,
        setList,
        newList,
        setNewList,
        customers,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
export default CustomerContextProvider;
