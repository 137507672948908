import { useMutation } from "@apollo/client";
import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import TabCustom from "Components/Tab/TabCustom";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setLoaded } from "Redux/Action/action";
import API from "Services/API";
import AccountingCategoryContextProvider, {
  AccountingCategoryContext,
} from "./Context";
const AccountCategoryCreate = () => {
  return (
    <AccountingCategoryContextProvider>
      <AccountingCategoryCreateContent />
    </AccountingCategoryContextProvider>
  );
};
const ADD_ACCOUNTING_CATEGORY = API.MUTATION.ADD_ACCOUNTING_CATEGORY;
const UPDATE_ACCOUNTING_CATEGORY = API.MUTATION.UPDATE_ACCOUNTING_CATEGORY;
const AccountingCategoryCreateContent = () => {
  const { openSnackBar, setOpenSnackBar, accountingCategories } = useContext(
    AccountingCategoryContext
  );
  const dispatch = useDispatch();
  const formEl = React.useRef();
  const { id } = useParams();
  const [isChecked, setIsChecked] = useState(true);
  const getData = accountingCategories?.find((ele) => ele.id === parseInt(id));
  useEffect(() => {
    if (getData) {
      const { title, note } = formEl.current;
      title.value = getData?.title || "";

      note.value = getData?.note || "";
      setIsChecked(getData?.isSupplier ? getData?.isSupplier : false);
    }

    return () => {};
  }, [getData]);
  const [createAccount] = useMutation(ADD_ACCOUNTING_CATEGORY, {
    onCompleted: ({ createAccount }) => {
      const { title, note } = formEl.current;
      setOpenSnackBar(true);
      title.value = null;

      note.value = null;
      dispatch(setLoaded({ accountingCategories: false }));

      // account_refetch();
    },
  });
  const [updateAccount] = useMutation(UPDATE_ACCOUNTING_CATEGORY, {
    onCompleted: ({ updateAccount }) => {
      setOpenSnackBar(true);
      dispatch(setLoaded({ accountingCategories: false }));

      // account_refetch();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const { title, note } = formEl.current;

    const accountCategoryVariable = {
      title: title?.value,
      note: note?.value,
    };
    if (id) {
      updateAccount({
        variables: { ...accountCategoryVariable, id: parseInt(id) },
      });
    } else
      createAccount({
        variables: accountCategoryVariable,
      });
  };
  const TabList = [
    {
      label: (
        <> {id ? "Account Categories Update" : "Account Categories Create"}</>
      ),
      component: (
        <>
          <form ref={formEl} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper className="p2">
                  <TextField
                    required
                    id="title"
                    label="Title"
                    InputLabelProps={{ shrink: true }}
                  />
                  <br />
                  <TextField
                    multiline
                    id="note"
                    rows={3}
                    label="Note"
                    InputLabelProps={{ shrink: true }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<Add />}
                  type="submit"
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      ),
    },
  ];
  return (
    <>
      <TabCustom TabList={TabList} />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="success">
          Account successfully {`${id ? "updated." : "created."}`}!
        </Alert>
      </Snackbar>
    </>
  );
};
export default AccountCategoryCreate;
