import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useDispatch } from "react-redux";
import { setCategoryModal } from "Redux/Action/action";
import API from "Services/API";

import EnhancedTable from "Components/Table/EnhancedTable";
const CATEGORIES = API.QUERY.CATEGORIES;
const DELETE_CATEGORY = API.MUTATION.DELETE_CATEGORY;
const Category = () => {
  const dispatch = useDispatch();
  const [deleteCategory] = useMutation(DELETE_CATEGORY);
  const { data, error, loading, refetch } = useQuery(CATEGORIES);
  const headRows = [
    // { id: "id", numeric: true, disablePadding: true, label: "id" },
    {
      id: "image_file_url",
      numeric: false,
      disablePadding: true,
      label: "Image"
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "170px"
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: "Description",
      width: "170px"
    }
  ];
  const handleOpen = () => {};
  if (loading) return <>LOADING</>;
  console.log("DATA", data);
  const supplier_data = data?.parentCategories;
  const rows = supplier_data?.map((ele) => {
    return {
      id: ele.id,
      name: ele.name,
      description: ele.description,
      image_file_url: ele.image_file_url
    };
  });
  function addFn(event) {
    event.preventDefault();
    dispatch(setCategoryModal({ modal: true, data: null, type: "add" }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    dispatch(
      setCategoryModal({
        modal: true,
        data: { id: id },
        type: "update"
      })
    );
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteCategory({ variables: { id } });
    if (deleteFn?.data?.deleteParentCategory?.success) {
      refetch();
    }
  }
  const refetchFn = () => {
    refetch();
  };
  return (
    <>
      <br />
      <EnhancedTable
        baseUrl={"/Category"}
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Category"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default Category;
