import { useLazyQuery, useQuery } from "@apollo/client";
import React, { createContext, useEffect, useState } from "react";
import API from "Services/API";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setCompanies } from "Redux/Action/action";
import moment from "moment";

export const CompanyContext = createContext();
const COMPANIES = API.QUERY.COMPANIES;
const CompanyContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { loaded, companies } = useSelector(
    (state) => ({
      loaded: state.persistent.loaded,
      companies: state.persistent.companies
    }),
    shallowEqual
  );
  const [open, setOpen] = React.useState(false);
  const [openFileModal, setOpenFileModal] = React.useState(false);
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [nameList, setNameList] = React.useState([]);
  const [imageList, setImageList] = React.useState([]);

  const [list, setList] = React.useState([]);
  const [newList, setNewList] = React.useState([]);
  const [fetchCompanies] = useLazyQuery(COMPANIES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ companies: newCompanies }) => {
      dispatch(setCompanies(newCompanies));
    }
  });
  useEffect(() => {
    if (!loaded.companies)
      fetchCompanies({
        variables: {
          fromDate: companies?.[0]?.updatedAt
            ? moment(companies?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC"
        }
      });
    return () => {
      // cleanup
    };
  }, [loaded]);
  return (
    <CompanyContext.Provider
      value={{
        open,
        setOpen,
        openFileModal,
        setOpenFileModal,
        isEnabled,
        setIsEnabled,
        openSnackBar,
        setOpenSnackBar,
        nameList,
        setNameList,
        imageList,
        setImageList,
        list,
        setList,
        newList,
        setNewList,
        companies
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
export default CompanyContextProvider;
