import {
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useContext } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { SaleContext } from "../sale.context";

function ccyFormat(num) {
  return `${num?.toFixed(2)}`;
}

function createRow(desc, qty, rate) {
  const price = qty * rate;
  return { desc, qty, rate, price };
}

// const saleRows = [
//   createRow("Paperclips (Box)", 100, 1.15),
//   createRow("Paper (Case)", 10, 45.99),
//   createRow("Waste Basket", 2, 17.99)
// ];

export default function SaleEditable() {
  const {
    handleSaleRemove,
    saleRows,
    invoiceSubtotal,
    invoiceTaxes,
    invoiceTotal,
    discount,
    tax,
    setTax,
    setDiscount,
    TAX_RATE,
    setSaleRows,
  } = useContext(SaleContext);
  const { products, suppliers } = useSelector(
    (state) => ({
      products: state.persistent.products,
      accounts: state.persistent.accounts,
    }),
    shallowEqual
  );

  const [mouseOverCheck, setMouseOverCheck] = React.useState(null);

  function handleMouseOver(event, id) {
    if (mouseOverCheck !== id) setMouseOverCheck(id);
  }
  function handleMouseOut(event, id) {
    if (mouseOverCheck === id) setMouseOverCheck(null);
  }
  const changeProduct = (e, row, index) => {
    const product_id = e.target.value;
    const newSalesRows = saleRows.slice();
    const newData = products.find((ele) => ele.id === product_id);
    console.log("products", products);
    newSalesRows[index] = {
      ...row,
      barcode: newData.code,
      unit: newData.unit,
      product: newData.name,
      product_id: newData.id,
      rate: newData.marketPrice,
      price: parseInt(row.quantity) * parseInt(newData.marketPrice),
    };
    setSaleRows(newSalesRows);
  };
  const changeQuantity = (e, row, index) => {
    const quantity = parseInt(e.target.value);
    const newSalesRows = saleRows.slice();
    console.log("newSalesRows[row]", row);

    newSalesRows[index] = {
      ...row,
      quantity,
      price: parseInt(quantity) * parseInt(row.rate),
    };
    setSaleRows(newSalesRows);
  };
  const changeRate = (e, row, index) => {
    const rate = parseInt(e.target.value);

    const newSalesRows = saleRows.slice();

    newSalesRows[index] = {
      ...row,
      rate,
      price: parseInt(rate) * parseInt(row.quantity),
    };
    setSaleRows(newSalesRows);
  };
  return (
    <TableContainer>
      <div className="flex justify-end">
        <IconButton
          color="primary"
          aria-label="Add sale"
          component="span"
          onClick={() => {
            setSaleRows((saleRow) => [...saleRow, {}]);
          }}
        >
          <Add />
        </IconButton>
      </div>
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={4}>
              Details
            </TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right" colSpan={2}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Barcode</TableCell>
            <TableCell>Product</TableCell>
            <TableCell align="right">Unit</TableCell>
            <TableCell align="right">Qty.</TableCell>
            <TableCell align="right">Rate</TableCell>
            <TableCell align="right">Sum</TableCell>
            <TableCell align="right" colSpan={2}>
              {/* Actions */}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {saleRows?.map((row, index) => (
            <TableRow
              key={row.product}
              // onMouseEnter={(event) => handleMouseOver(event, index)}
              // onMouseLeave={(event) => handleMouseOut(event, index)}
              style={{ background: "lightgoldenrodyellow" }}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell align="right">{row.barcode}</TableCell>
              <TableCell>
                {/* <TextField
                  id="outlined-basic"
                  defaultValue={row.product}
                  variant="outlined"
                /> */}
                <Select
                  labelId="product_name_select_label"
                  id="product_name_select"
                  // value={age}
                  defaultValue={row.product_id}
                  label="Product"
                  style={{ minWidth: "5rem" }}
                  onChange={(e) => changeProduct(e, row, index)}
                  // onChange={handleProductChange}
                  // ref={product_id_ref}
                >
                  <MenuItem value={0}>Select Product</MenuItem>
                  {products?.map((ele, i) => {
                    return (
                      <MenuItem
                        value={ele.id}
                        selected={ele.name === row.product}
                      >
                        {ele.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
              <TableCell align="right">{row.unit}</TableCell>

              <TableCell align="right">
                <TextField
                  id="product_quantity"
                  label="Qty"
                  InputProps={{ inputProps: { min: 1 } }}
                  type="number"
                  value={row.quantity}
                  onChange={(e) => changeQuantity(e, row, index)}
                  InputLabelProps={{ shrink: true }}
                />
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="product_rate"
                  label="Rate"
                  type="number"
                  value={row.rate}
                  onChange={(e) => changeRate(e, row, index)}
                  //   onChange={handleProductQuantityChange}
                  InputLabelProps={{ shrink: true }}
                />
              </TableCell>
              <TableCell align="right">
                {row.price ? ccyFormat(row.price) : ""}
              </TableCell>
              {/* 
              <TableCell padding="checkbox">
                {mouseOverCheck === index ? (
                  <>
                    <Tooltip title="Edit">
                      <IconButton
                        aria-label="Edit"
                        // onClick={(event) => updateFn(event, index)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : null}
              </TableCell> */}

              <TableCell padding="checkbox">
                <>
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="sd"
                      onClick={(event) => handleSaleRemove(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              </TableCell>
            </TableRow>
          ))}

          {saleRows?.length > 0 ? (
            <>
              <TableRow>
                <TableCell rowSpan={4} colSpan={2} />
                <TableCell colSpan={4}>Discount</TableCell>
                {/* <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
                  0
                )} %`}</TableCell> */}
                <TableCell align="right">
                  {/* <TextField type="number" id="subtotal_discount" /> */}-
                  <TextField
                    type="number"
                    id="subtotal_discount"
                    defaultValue={discount}
                    onChange={(e) => {
                      setDiscount(parseInt(e.target.value));
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                  {/* {ccyFormat(invoiceTaxes)} */}
                </TableCell>
                <TableCell rowSpan={4} colSpan={2} />
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>Subtotal</TableCell>
                <TableCell colSpan={2} align="right">
                  {invoiceTotal}
                  {/* {ccyFormat(invoiceSubtotal)} */}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={4}>Tax</TableCell>
                {/* <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
                  0
                )} %`}</TableCell> */}
                <TableCell align="right">
                  {/* <TextField type="number" id="subtotal_discount" /> */}
                  <TextField
                    type="number"
                    id="subtotal_tax"
                    defaultValue={tax}
                    onChange={(e) => {
                      setTax(parseInt(e.target.value));
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                  {/* {ccyFormat(invoiceTaxes)} */}
                </TableCell>

                {/* <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell> */}
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>Total</TableCell>
                <TableCell align="right">
                  <TextField value={invoiceTotal} disabled id="total" />
                </TableCell>
              </TableRow>
            </>
          ) : (
            <>
              <TableRow style={{ background: "lightgoldenrodyellow" }}>
                <TableCell colSpan={6} />
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
