import { useMutation } from "@apollo/client";
import { ExitToApp } from "@material-ui/icons";
import EnhancedTable from "Components/Table/EnhancedTable";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { delProducts, setLoaded } from "Redux/Action/action";
import API from "Services/API";
import Column from "../../DesignComponent/Column";
import GridColumnTemplate from "../../DesignComponent/GridColumn";
import Row from "../../DesignComponent/Row";
import ProductContextProvider, { ProductContext } from "./product.context";
const DELETE_PRODUCT = API.MUTATION.DELETE_PRODUCT;

const Product = () => {
  return (
    <ProductContextProvider>
      <ProductContent />
    </ProductContextProvider>
  );
};
const ProductContent = () => {
  const { products } = useContext(ProductContext);
  const history = useHistory();
  const dispatch = useDispatch();

  const [deleteProduct] = useMutation(DELETE_PRODUCT);
  const headRows = [
    // { id: "id", numeric: true, disablePadding: true, label: "id" },
    {
      id: "featuredImage",
      numeric: false,
      disablePadding: true,
      label: "Image",
      width: "170px",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "170px",
    },
    {
      id: "code",
      numeric: false,
      disablePadding: true,
      label: "Code",
    },
    {
      id: "barcode_symbology",
      numeric: false,
      disablePadding: true,
      label: "Barcode",
    },
    {
      id: "unit",
      numeric: false,
      disablePadding: true,
      label: "Unit",
    },
    {
      id: "marketPrice",
      numeric: true,
      disablePadding: true,
      label: "Market Price",
    },
    {
      id: "costPrice",
      numeric: true,
      disablePadding: true,
      label: "Cost Price",
    },
    {
      id: "enabled",
      numeric: false,
      disablePadding: true,
      label: "Enabled",
    },
    {
      id: "facet_values",
      numeric: false,
      disablePadding: true,
      label: "Facet Values",
    },
  ];
  const handleOpen = () => {};
  const product_data = products;
  console.log("**8product_data***", product_data);
  const rows = product_data;
  function addFn(event) {
    event.preventDefault();
    history.push("products/create");
    // dispatch(setAccountModal({ modal: true, data: null, type: "add" }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    history.push({
      pathname: `products/${id}`,
      // state: {
      //   row: rows?.find((ele) => ele.id === id)
      // }
    });
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteProduct({ variables: { id } });
    if (deleteFn?.data?.deleteProduct) {
      dispatch(delProducts(parseInt(id)));
      // refetch();
    }
  }
  const refetchFn = () => {
    // refetch();
    console.log("REFETCY");
    // refetchProducts();
    dispatch(setLoaded({ products: false }));
  };
  return (
    <>
      <Row>
        <Column lg={4}>
          <div className="dashboard-box">
            <GridColumnTemplate
              component={
                <>
                  <div className="dashboard-circle">
                    <div className="flex justify-center">
                      <ExitToApp className="box-icon" />
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">0</span>
                        </Column>
                      </Row>

                      <Row spacing={2}>
                        <Column className="flex justify-end align-center">
                          <span className="boxSubtitle text-dark">
                            Today Purchases
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Column>
        <Column lg={4}>
          <div className="dashboard-box">
            <GridColumnTemplate
              component={
                <>
                  <div className="dashboard-circle">
                    <div className="flex justify-center">
                      <ExitToApp className="box-icon" />
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">0</span>
                        </Column>
                      </Row>

                      <Row spacing={2}>
                        <Column className="flex justify-end align-center">
                          <span className="boxSubtitle text-dark">
                            Weekly Purchases
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Column>
        <Column lg={4}>
          <div className="dashboard-box">
            <GridColumnTemplate
              component={
                <>
                  <div className="dashboard-circle">
                    <div className="flex justify-center">
                      <ExitToApp className="box-icon" />
                    </div>
                  </div>
                  <div className="flex justify-end align-center">
                    <div>
                      <Row className="flex justify-end align-center">
                        <Column>
                          <span className="text-dark boxTitle">0</span>
                        </Column>
                      </Row>

                      <Row spacing={2}>
                        <Column className="flex justify-end align-center">
                          <span className="boxSubtitle text-dark">
                            Monthly Purchases
                          </span>
                        </Column>
                      </Row>
                    </div>
                  </div>
                </>
              }
              col1="5rem"
              col2="auto"
            />
          </div>
        </Column>
      </Row>

      <br />
      <EnhancedTable
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Product"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default Product;
