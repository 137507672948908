import { useLazyQuery, useQuery } from "@apollo/client";
import React, { createContext, useState } from "react";
import API from "Services/API";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setReferenceBills } from "Redux/Action/action";
import moment from "moment";
const TAX_RATE = 0 / 100;

export const PurchaseContext = createContext();
const REFERENCE_BILLS = API.QUERY.REFERENCE_BILLS;
const PurchaseContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { loaded, referenceBills } = useSelector(
    (state) => ({
      loaded: state.persistent.loaded,
      referenceBills: state.persistent.referenceBills,
    }),
    shallowEqual
  );
  const [purchaseRows, setPurchaseRows] = React.useState([]);
  const [discount, setDiscount] = React.useState(0);
  const [tax, setTax] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [openFileModal, setOpenFileModal] = React.useState(false);
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [nameList, setNameList] = React.useState([]);
  const [imageList, setImageList] = React.useState([]);

  const [list, setList] = React.useState([]);
  const [newList, setNewList] = React.useState([]);

  const [fetchReferenceBills] = useLazyQuery(REFERENCE_BILLS, {
    fetchPolicy: "no-cache",
    onError: () => {},
    onCompleted: (arg) => {
      if (arg) {
        const newReferenceBills = arg?.referenceBills;
        if (newReferenceBills?.length > 0)
          dispatch(setReferenceBills(newReferenceBills));
      }
    },
  });

  const handlePurchaseAdd = (productObject) => {
    setPurchaseRows([...purchaseRows, productObject]);
  };
  const handlePurchaseRemove = (index) => {
    const filteredPurchaseRow = purchaseRows?.filter((ele, i) => i !== index);
    setPurchaseRows(filteredPurchaseRow);
  };
  React.useEffect(() => {
    if (!loaded.referenceBills) {
      fetchReferenceBills({
        variables: {
          fromDate: referenceBills?.[0]?.updatedAt
            ? moment(referenceBills?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    }
    return () => {};
  }, [loaded]);
  function subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
  }
  const invoiceSubtotal = subtotal(purchaseRows) - discount;
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  const invoiceTotal = invoiceTaxes + invoiceSubtotal + tax;
  return (
    <PurchaseContext.Provider
      value={{
        open,
        setOpen,
        openFileModal,
        setOpenFileModal,
        isEnabled,
        setIsEnabled,
        openSnackBar,
        setOpenSnackBar,
        nameList,
        setNameList,
        imageList,
        setImageList,
        list,
        setList,
        newList,
        setNewList,
        referenceBills,
        purchaseRows,
        setPurchaseRows,
        handlePurchaseAdd,
        handlePurchaseRemove,
        invoiceSubtotal,
        invoiceTaxes,
        invoiceTotal,
        setTax,
        tax,
        discount,
        setDiscount,
        TAX_RATE,
      }}
    >
      {children}
    </PurchaseContext.Provider>
  );
};
export default PurchaseContextProvider;
