import { useMutation } from "@apollo/client";
import EnhancedTable from "Components/Table/EnhancedTable";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { delAccountingBills, setLoaded } from "Redux/Action/action";
import API from "Services/API";
import AccountingBillContextProvider, {
  AccountingBillContext,
} from "./Context";

const DELETE_ACCOUNTING_BILL = API.MUTATION.DELETE_ACCOUNTING_BILL;
const AccountingBills = () => {
  return (
    <AccountingBillContextProvider>
      <AccountingBillsContent />
    </AccountingBillContextProvider>
  );
};
const AccountingBillsContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteAccountingBill] = useMutation(DELETE_ACCOUNTING_BILL);
  const { accountingBills } = useContext(AccountingBillContext);
  const headRows = [
    // { id: "id", numeric: true, disablePadding: true, label: "id" },

    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: "Date",
      width: "170px",
    },
    {
      id: "entry_type",
      numeric: false,
      disablePadding: true,
      label: "Entry Type",
      width: "170px",
    },
    {
      id: "payment_type_id",
      numeric: false,
      disablePadding: true,
      label: "Payment Type",
      width: "170px",
    },
    {
      id: "accounting_cat_id",
      numeric: false,
      disablePadding: true,
      label: "Accounting Category",
      width: "170px",
    },
    {
      id: "note",
      numeric: false,
      disablePadding: true,
      label: "Note",
    },
  ];
  const handleOpen = () => {};
  const Accounting_data = accountingBills?.filter(
    (account) => !account.isSupplier
  );
  const rows = Accounting_data;
  function addFn(event) {
    event.preventDefault();
    history.push("accountingBills/create");
    dispatch(setLoaded({ accountingBills: false }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    history.push(`accountingBills/${id}`);
    // dispatch(
    //   setAccountModal({
    //     modal: true,
    //     data: { id: id },
    //     type: "update"
    //   })
    // );
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    console.log("===]n");

    const deleteFn = await deleteAccountingBill({ variables: { id } });
    console.log("===]n", deleteFn);

    if (deleteFn?.data?.deleteAccountingBill) {
      // refetch();

      if (id) dispatch(delAccountingBills(id));
      // dispatch(setLoaded({ accountingBills: false }));
    }
  }
  const refetchFn = () => {
    // refetch();
    dispatch(setLoaded({ accountingBills: false }));
  };
  return (
    <>
      <br />
      <EnhancedTable
        headRow={headRows}
        rows={rows}
        title={"Accounting Bill"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default AccountingBills;
