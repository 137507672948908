import { useMutation } from "@apollo/client";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Column, Row } from "Components/DesignComponent";
import moment from "moment";
import NepaliDate from "nepali-date-converter";
import React, { useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setLoaded } from "Redux/Action/action";
import API from "Services/API";
import TabCustom from "../../../Tab/TabCustom";
import AccountingService from "./new";
import ProductSelect from "./product.select";
import PurchaseContextProvider, { PurchaseContext } from "./purchase.context";
import PurchaseTable from "./purchase.table";
import PurchaseEditable from "./PurchaseEditable";
const ADD_REFERENCE_BILL = API.MUTATION.ADD_REFERENCE_BILL;
const UPDATE_REFERENCE_BILL = API.MUTATION.UPDATE_REFERENCE_BILL;

const PurchaseCreate = () => {
  return (
    <>
      <AccountingService />
      <PurchaseContextProvider>
        <PurchaseCreateContent />
      </PurchaseContextProvider>
    </>
  );
};
const PurchaseCreateContent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { products, suppliers } = useSelector(
    (state) => ({
      products: state.persistent.products,
      accounts: state.persistent.accounts,
    }),
    shallowEqual
  );
  const [engDateState, setEngDateState] = React.useState(
    moment().format("ddd, DD MMMM YYYY")
  );
  const [nepaliDateState, setNepaliDateState] = React.useState(
    new NepaliDate(moment.utc().valueOf()).format("ddd, DD MMMM YYYY")
  );
  const [purchaseSupplier, setPurchaseSupplier] = React.useState(null);
  const [payment_type, setPayment_type] = React.useState(null);
  const [payment_status, setPayment_status] = React.useState(null);
  const formEl = React.useRef();
  const [createReferenceBill] = useMutation(ADD_REFERENCE_BILL, {
    onCompleted: ({ createReferenceBill }) => {
      dispatch(setLoaded({ referenceBills: false }));
      history.push("/admin/purchases");
    },
  });
  const [updateReferenceBill] = useMutation(UPDATE_REFERENCE_BILL, {
    onCompleted: ({ updateReferenceBill }) => {
      dispatch(setLoaded({ referenceBills: false }));
      history.push("/admin/purchases");
    },
  });
  const {
    open,
    setOpen,
    openFileModal,
    setOpenFileModal,
    isEnabled,
    setIsEnabled,
    openSnackBar,
    setOpenSnackBar,
    nameList,
    setNameList,
    imageList,
    setImageList,
    list,
    setList,
    newList,
    setNewList,
    referenceBills,
    purchaseRows,
    setPurchaseRows,
    setDiscount,
    setTax,
    tax,
  } = useContext(PurchaseContext);
  const getData = referenceBills?.find((ele) => ele.id === parseInt(id));
  React.useEffect(() => {
    if (getData) {
      const { bill_id, subtotal_discount, subtotal_tax } = formEl.current;
      bill_id.value = getData?.bill_id;
      // timeStamp.value = getData?.timeStamp;
      setDiscount(parseInt(getData?.discount));
      setTax(parseInt(getData?.tax));

      const purchaseOldData = getData?.Purchases;
      const supplier_id = getData?.supplier_id;
      const payment_status_id = getData?.payment_status_id;
      const payment_type_id = getData?.payment_type_id;
      setPurchaseRows(
        purchaseOldData?.map((ele) => {
          const product = products?.find((el) => el.id === ele.product_id);
          return {
            product: product?.name,
            quantity: ele.quantity,
            product_id: ele.product_id,
            price: ele.quantity * ele.rate,
            rate: ele.rate,
            unit: product?.unit,
            barcode: product?.barcode_symbology,
          };
        })
      );
      setPurchaseSupplier(supplier_id);
      setPayment_status(payment_status_id);
      setPayment_type(payment_type_id);
    }
    return () => {};
  }, [getData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      supplier_name,
      supplier_pan_no,
      supplier_phone_no,
      supplier_note,
      product_quantity,
      product_name_select,
      product_name_select_label,
      product_name_select_fm,
      timeStamp,

      bill_id,
      subtotal_discount,
      subtotal_tax,
      total,
    } = formEl.current;
    const bill_id_value = bill_id?.value;
    const subtotal_discount_value = subtotal_discount?.value;
    const subtotal_tax_value = subtotal_tax?.value;
    const timeStamp_value = timeStamp?.value;
    const total_value = total?.value;
    if (purchaseRows?.length > 0) {
      const dataVar = {
        bill_id: parseInt(bill_id_value),
        user_id: 1,
        biller_id: 1,
        discount: subtotal_discount_value
          ? parseFloat(subtotal_discount_value)
          : 0,
        tax: subtotal_tax_value ? parseFloat(subtotal_tax_value) : 0,
        grand_total: total_value ? parseFloat(total_value) : 0,
        Purchases: purchaseRows?.map((ele) => {
          return {
            product_id: ele.product_id,
            quantity: ele.quantity,
            rate: ele.rate,
          };
        }),
        timeStamp: timeStamp_value,
        payment_status_id: payment_status,
        supplier_id: purchaseSupplier,
        payment_type_id: payment_type,
      };

      if (id && id !== "create") {
        dataVar.id = parseInt(id);
        updateReferenceBill({
          variables: dataVar,
        });
      } else {
        createReferenceBill({
          variables: dataVar,
        });
      }
    } else {
      setOpenSnackBar(true);
    }
    // const supplierVariable = {
    //   name: supplier_name?.value,
    //   pan_no: parseInt(supplier_pan_no?.value),
    //   phone_no: supplier_phone_no?.value,
    //   note: supplier_note?.value
    // };

    // if (id) {
    //   updateSupplier({
    //     variables: { ...supplierVariable, id: parseInt(id) }
    //   });
    // } else
    //   createSupplier({
    //     variables: supplierVariable
    //   });
  };

  const TabList = [
    {
      label: <> {id ? "Purchase Update" : "Purchase Create"}</>,
      component: (
        <>
          <form ref={formEl} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper>
                  <Paper elevation={8} className="p3">
                    <Grid container spacing={1} className="p1">
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          required
                          id="bill_id"
                          type="number"
                          label="Bill no."
                          defaultValue={
                            referenceBills?.length > 0
                              ? referenceBills?.[0]?.bill_id + 1
                              : ""
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <TextField
                              size="small"
                              id="timeStamp"
                              label="Date"
                              type="date"
                              defaultValue={moment(engDateState).format(
                                "YYYY-MM-DD"
                              )}
                              InputLabelProps={{ shrink: true }}
                              helperText="Purchase date"
                              autoWidth
                              onChange={(e) => {
                                const currentDate = e.target.value;
                                setEngDateState(currentDate);
                                setNepaliDateState(
                                  new NepaliDate(
                                    moment
                                      .utc(formEl.current?.timeStamp?.value)
                                      .valueOf()
                                  ).format("ddd, DD MMMM YYYY")
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              size="small"
                              id="timeStamp-nepali"
                              label="Nepali Date"
                              value={nepaliDateState}
                              InputLabelProps={{ shrink: true }}
                              disabled
                              autoWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>

                  <Divider />

                  <Grid
                    container
                    spacing={1}
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  >
                    <Grid item xs={6}>
                      <Paper elevation={8} className="p4">
                        <Grid container>
                          <Grid item xs={12} lg={4}>
                            <FormControl
                              key={
                                purchaseSupplier
                                  ? `purchaseSupplier-${purchaseSupplier}`
                                  : `purchaseSupplier-${0}`
                              }
                              required
                            >
                              <InputLabel
                                id="purchase_supplier_id_label"
                                required
                              >
                                Supplier
                              </InputLabel>
                              <Select
                                labelId="purchase_supplier_id_label"
                                id="purchase_supplier_id"
                                // value={age}
                                label="Supplier"
                                autoWidth
                                style={{ minWidth: "100px" }}
                                required
                                // onChange={handleChange}
                                defaultValue={purchaseSupplier}
                                onChange={(e) => {
                                  setPurchaseSupplier(e.target.value);
                                }}
                              >
                                {suppliers?.map((ele) => {
                                  return (
                                    <MenuItem value={ele?.id}>
                                      {ele.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <FormControl
                              key={
                                payment_status
                                  ? `payment_status-${payment_status}`
                                  : `payment_status-${0}`
                              }
                              required
                            >
                              <InputLabel id="purchase_payment_status_id-label">
                                Payment Status
                              </InputLabel>
                              <Select
                                labelId="purchase_payment_status_id-label"
                                id="purchase_payment_status_id"
                                // value={age}
                                defaultValue={payment_status}
                                label="Payment Status"
                                autoWidth
                                style={{ minWidth: "150px" }}
                                required
                                onChange={(e) => {
                                  setPayment_status(e.target.value);

                                  console.log("Payment Status", e.target.value);
                                }}
                              >
                                <MenuItem value={1}>Paid</MenuItem>
                                <MenuItem value={2}>Pending</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <FormControl
                              key={
                                payment_type
                                  ? `payment_type-${payment_type}`
                                  : `payment_type-${0}`
                              }
                              required
                            >
                              <InputLabel id="purchase_payment_type_id-label">
                                Payment Type
                              </InputLabel>
                              <Select
                                labelId="purchase_payment_type_id-label"
                                id="purchase_payment_type_id"
                                // value={age}
                                label="Payment Type"
                                autoWidth
                                style={{ minWidth: "150px" }}
                                required
                                defaultValue={payment_type}
                                onChange={(e) => {
                                  setPayment_type(e.target.value);
                                }}
                              >
                                <MenuItem value={1}>Cash</MenuItem>
                                <MenuItem value={2}>Credit Card</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper elevation={8} className="p4">
                        <Grid container>
                          <Grid item xs={12}>
                            <TextField fullWidth label="Barcode" />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    {/* <Grid item xs={6}>
                        <Paper elevation={8} className="p2">
                          <span style={{ fontSize: "1.25rem" }}>
                            Order Items
                          </span>

                          <ProductSelect products={products} formEl={formEl} />
                        </Paper>
                      </Grid> */}
                  </Grid>

                  {/* <TextField
                  size="small"
                    id="biller_id"
                    type="number"
                    label="Biller"
                    InputLabelProps={{ shrink: !!getData }}
                  /> */}
                  {/* <Paper elevation={8} className="m2 p2">
                    <PurchaseTable
                      purchaseRows={purchaseRows}
                      setPurchaseRows={setPurchaseRows}
                    />
                  </Paper>
                  <Divider /> */}
                  <PurchaseEditable
                    purchaseRows={purchaseRows}
                    setPurchaseRows={setPurchaseRows}
                  />
                  {/* <Paper elevation={8} className="m2 p2">
                    <Grid item xs={12} lg={6}>
                      <TextField
                        size="small"
                        id="purchase_discount_id"
                        label="Discount"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Paper> */}
                  <Row style={{ justifyContent: "end" }}>
                    <Column className="pt2 pr2 pb2">
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        type="submit"
                      >
                        SUBMIT
                      </Button>
                    </Column>
                  </Row>
                </Paper>
              </Grid>
            </Grid>
          </form>
        </>
      ),
    },
  ];
  return (
    <>
      {/* <FormControlLabel
        control={
          <Switch
            checked={true}
            // onChange={handleChange}
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label="Enabled"
      /> */}

      <Divider />
      <TabCustom TabList={TabList} />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="error">No products are selected.</Alert>
      </Snackbar>
    </>
  );
};
export default PurchaseCreate;
