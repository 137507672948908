import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setAccountingBills } from "Redux/Action/action";
import API from "Services/API";

export const AccountingBillContext = createContext();
const ACCOUNTING_BILLS = API.QUERY.ACCOUNTING_BILLS;
const AccountingBillContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { loaded, accountingBills, accountingCategories, payments } =
    useSelector(
      (state) => ({
        loaded: state.persistent.loaded,
        accountingBills: state.persistent.accountingBills,
        accountingCategories: state.persistent.accountingCategories,
        payments: state.persistent.payments,
      }),
      shallowEqual
    );

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [fetchAccountingBills] = useLazyQuery(ACCOUNTING_BILLS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ AccountingBills: newAccountingBills }) => {
      dispatch(setAccountingBills(newAccountingBills));
    },
  });

  useEffect(() => {
    if (!loaded.accountingBills)
      fetchAccountingBills({
        variables: {
          fromDate: accountingBills?.[0]?.updatedAt
            ? moment(accountingBills?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    return () => {
      // cleanup
    };
  }, [loaded]);
  return (
    <AccountingBillContext.Provider
      value={{
        openSnackBar,
        setOpenSnackBar,
        accountingBills,
        accountingCategories,
        payments,
      }}
    >
      {children}
    </AccountingBillContext.Provider>
  );
};
export default AccountingBillContextProvider;
