import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setAccountingCategories } from "Redux/Action/action";
import API from "Services/API";

export const AccountingCategoryContext = createContext();
const ACCOUNTING_CATEGORIES = API.QUERY.ACCOUNTING_CATEGORIES;
const AccountingCategoryContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { loaded, accountingCategories } = useSelector(
    (state) => ({
      loaded: state.persistent.loaded,
      accountingCategories: state.persistent.accountingCategories,
    }),
    shallowEqual
  );

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [fetchAccountingCategories] = useLazyQuery(ACCOUNTING_CATEGORIES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ accountingCategories: newAccountingCategories }) => {
      dispatch(setAccountingCategories(newAccountingCategories));
    },
  });

  useEffect(() => {
    if (!loaded.accountingCategories)
      fetchAccountingCategories({
        variables: {
          fromDate: accountingCategories?.[0]?.updatedAt
            ? moment(accountingCategories?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    return () => {
      // cleanup
    };
  }, [loaded]);
  return (
    <AccountingCategoryContext.Provider
      value={{
        openSnackBar,
        setOpenSnackBar,
        accountingCategories,
      }}
    >
      {children}
    </AccountingCategoryContext.Provider>
  );
};
export default AccountingCategoryContextProvider;
