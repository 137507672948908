import { useMutation } from "@apollo/client";
import { Button, Grid, Paper, Snackbar, TextField } from "@material-ui/core";
import {
  Add,
  AddCircleOutline,
  DeleteOutline,
  RemoveCircleOutline,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Column, Row } from "Components/DesignComponent";
import GridColumnTemplate from "Components/DesignComponent/GridColumn";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setLoaded } from "Redux/Action/action";
import API from "Services/API";
import TabCustom from "../../Tab/TabCustom";
import FacetContextProvider, { FacetContext } from "./facets.context";
const ADD_FACET = API.MUTATION.ADD_FACET;
const UPDATE_FACET = API.MUTATION.UPDATE_FACET;

const FacetsCreate = () => {
  return (
    <FacetContextProvider>
      <FacetCreateContent />
    </FacetContextProvider>
  );
};
const FacetCreateContent = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const formEl = React.useRef();
  const [categoryList, setCategoryList] = useState([]);
  const {
    open,
    setOpen,
    openFileModal,
    setOpenFileModal,
    isEnabled,
    setIsEnabled,
    openSnackBar,
    setOpenSnackBar,
    nameList,
    setNameList,
    imageList,
    setImageList,
    list,
    setList,
    newList,
    setNewList,
    facets,
  } = useContext(FacetContext);

  const getData = facets?.find((ele) => ele.id === parseInt(id));
  useEffect(() => {
    if (id && !getData) {
      history.push("/admin/facets");
    }
    if (getData) {
      setList(getData?.FacetValues?.map((ele) => ele.id));
      const { facet_code } = formEl.current;
      facet_code.value = getData.code;

      setIsEnabled(getData.enabled);
      if (getData?.FacetValues) setCategoryList(getData?.FacetValues);
      // refetchProducts();
    }
  }, [getData, id]);
  const [createFacet] = useMutation(ADD_FACET, {
    onCompleted: ({ createFacet }) => {
      const { facet_code } = formEl.current;
      setOpenSnackBar(true);
      setList([]);
      setNewList([]);
      setIsEnabled(true);
      facet_code.value = null;
      dispatch(setLoaded({ facets: false }));

      // refetchProducts();
    },
  });
  const [updateFacet] = useMutation(UPDATE_FACET, {
    onCompleted: ({ updateFacet }) => {
      setOpenSnackBar(true);
      dispatch(setLoaded({ facets: false }));

      // refetchProducts();
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    // const {
    //   product_name,
    //   product_slug,
    //   product_barcode,
    //   product_unit,
    //   product_cost_price,
    //   product_market_price
    // } = e.target;
    const { facet_code } = formEl.current;
    const facetVariable = {
      code: facet_code?.value,
      FacetValues: categoryList?.map((ele) => {
        return { id: ele.id, code: ele.code };
      }),
    };
    if (id) {
      updateFacet({
        variables: { ...facetVariable, id: parseInt(id) },
      });
    } else
      createFacet({
        variables: facetVariable,
      });
  };
  console.log("categoryList", categoryList);
  const TabList = [
    {
      label: <> {id ? "Facet Update" : "Facet Create"}</>,
      component: (
        <>
          <form ref={formEl} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper>
                  <div className="p2">
                    <TextField required id="facet_code" label="Facet Code" />
                    <div className="pt2">
                      <span>Categories</span>
                      <div className="mt1 ml1">
                        <Row>
                          <Column>
                            {categoryList?.map((ele, i) => {
                              return (
                                <>
                                  <Row>
                                    <Column>
                                      <GridColumnTemplate
                                        col1="10rem"
                                        col2="auto"
                                        component={
                                          <>
                                            <div>
                                              <TextField
                                                required
                                                id={i}
                                                defaultValue={ele.code}
                                                onChange={(e) => {
                                                  ele.code = e.target.value;
                                                  setCategoryList([
                                                    ...categoryList,
                                                  ]);
                                                }}
                                              />
                                            </div>
                                            <div>
                                              <span
                                                className="pointer-cursor"
                                                onClick={(e) => {
                                                  setCategoryList(
                                                    categoryList?.filter(
                                                      (ele, index) =>
                                                        i !== index
                                                    )
                                                  );
                                                }}
                                              >
                                                <DeleteOutline />
                                              </span>
                                            </div>
                                          </>
                                        }
                                      />
                                    </Column>
                                  </Row>
                                </>
                              );
                            })}
                            <div>
                              <GridColumnTemplate
                                col1="10rem"
                                col2="auto"
                                component={
                                  <>
                                    <div></div>
                                    <div>
                                      <span
                                        className="pointer-cursor"
                                        onClick={(e) => {
                                          setCategoryList([
                                            ...categoryList,
                                            { code: "" },
                                          ]);
                                        }}
                                      >
                                        <AddCircleOutline />
                                      </span>
                                    </div>
                                  </>
                                }
                              />
                            </div>
                          </Column>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="default"
                startIcon={<Add />}
                type="submit"
              >
                SUBMIT
              </Button>
            </Grid>
          </form>
        </>
      ),
    },
  ];
  return (
    <>
      {/* <FormControlLabel
        control={
          <Switch
            checked={true}
            // onChange={handleChange}
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label="Enabled"
      />

      <Divider /> */}
      <TabCustom TabList={TabList} />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="success">
          Product successfully {`${id ? "updated." : "created."}`}!
        </Alert>
      </Snackbar>
    </>
  );
};
export default FacetsCreate;
