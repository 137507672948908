import { useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  delSuppliers,
  setLoaded,
  setAccountModal,
  delAccounts,
} from "Redux/Action/action";
import API from "Services/API";

import EnhancedTable from "Components/Table/EnhancedTable";
import SupplierContextProvider, { SupplierContext } from "./supplier.context";
const SUPPLIERS = API.QUERY.ACCOUNTS;
const DELETE_ACCOUNT = API.MUTATION.DELETE_ACCOUNT;
const Supplier = () => {
  return (
    <SupplierContextProvider>
      <SupplierContent />
    </SupplierContextProvider>
  );
};
const SupplierContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteAccount] = useMutation(DELETE_ACCOUNT);
  const { accounts } = useContext(SupplierContext);
  const headRows = [
    // { id: "id", numeric: true, disablePadding: true, label: "id" },

    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "170px",
    },
    {
      id: "pan_no",
      numeric: false,
      disablePadding: true,
      label: "Pan Number",
    },
    {
      id: "phone_no",
      numeric: false,
      disablePadding: true,
      label: "Phone Number",
    },
    {
      id: "note",
      numeric: false,
      disablePadding: true,
      label: "Note",
    },
  ];
  const handleOpen = () => {};
  const supplier_data = accounts?.filter((account) => account.isSupplier);
  const rows = supplier_data;
  function addFn(event) {
    event.preventDefault();
    history.push("suppliers/create");
    dispatch(setLoaded({ accounts: false }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    history.push(`suppliers/${id}`);
    // dispatch(
    //   setAccountModal({
    //     modal: true,
    //     data: { id: id },
    //     type: "update"
    //   })
    // );
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteAccount({ variables: { id } });
    if (deleteFn?.data?.deleteAccount) {
      // refetch();
      if (id) dispatch(delAccounts(id));
      // dispatch(setLoaded({ accounts: false }));
    }
  }
  const refetchFn = () => {
    // refetch();
    dispatch(setLoaded({ accounts: false }));
  };
  return (
    <>
      <br />
      <EnhancedTable
        baseUrl={"/equipments"}
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Suppliers"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default Supplier;
