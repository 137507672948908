import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { createContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { setLoaded, setReferenceBills } from "Redux/Action/action";
import API from "Services/API";

export const AccountingPurchase_Context = createContext();
const REFERENCE_BILLS = API.QUERY.REFERENCE_BILLS;
const ADD_REFERENCE_BILL = API.MUTATION.ADD_REFERENCE_BILL;
const UPDATE_REFERENCE_BILL = API.MUTATION.UPDATE_REFERENCE_BILL;

const AccountingPurchase_Provider = ({ children }) => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const history = useHistory();
  const { loaded, referenceBills, products, suppliers } = useSelector(
    (state) => ({
      loaded: state.persistent.loaded,
      referenceBills: state.persistent.referenceBills,
      products: state.persistent.products,
      accounts: state.persistent.accounts,
    }),
    shallowEqual
  );
  const [purchaseRows, setPurchaseRows] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [supplierId, setAccountId] = useState(null);
  const [note, setNote] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const [createReferenceBill] = useMutation(ADD_REFERENCE_BILL, {
    onCompleted: (arg) => {
      dispatch(setLoaded({ referenceBills: false }));
      history.push("/admin/purchases");
    },
  });
  const [updateReferenceBill] = useMutation(UPDATE_REFERENCE_BILL, {
    onCompleted: ({ updateReferenceBill }) => {
      dispatch(setLoaded({ referenceBills: false }));
      history.push("/admin/purchases");
    },
  });
  //FETCH DATA
  const [fetchReferenceBills] = useLazyQuery(REFERENCE_BILLS, {
    fetchPolicy: "no-cache",
    onError: () => {},
    onCompleted: (arg) => {
      if (arg) {
        const newReferenceBills = arg?.referenceBills;
        if (newReferenceBills?.length > 0)
          dispatch(setReferenceBills(newReferenceBills));
      }
    },
  });
  useEffect(() => {
    if (!loaded.referenceBills) {
      fetchReferenceBills({
        variables: {
          fromDate: referenceBills?.[0]?.updatedAt
            ? moment(referenceBills?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    }
    return () => {};
  }, [loaded]);
  //FETCH DATA - END
  //PURCHASE
  const handlePurchaseAdd = (productObject) => {
    const productData = products.find(
      (ele) => ele.id === productObject.product_id
    );
    const unit = productData?.unit;
    productObject.unit = unit;
    setPurchaseRows([...purchaseRows, productObject]);
  };
  const handlePurchaseRemove = (index) => {
    const filteredPurchaseRow = purchaseRows?.filter((ele, i) => i !== index);
    setPurchaseRows(filteredPurchaseRow);
  };
  //PURCHASE-END
  //Check if old Data
  useEffect(() => {
    if (id && id !== "create") {
      const getData = referenceBills?.find((ele) => ele.id === parseInt(id));
      if (getData) {
        const formattedPurchases = getData?.Purchases?.map((ele) => {
          return {
            ...ele,
            price: parseFloat(ele.quantity) * parseFloat(ele.rate),
          };
        });
        setPurchaseRows(formattedPurchases);
        setDiscount(getData?.discount);
        setNote(getData?.note);

        const getSupplier = suppliers?.find(
          (ele) => ele.id == getData?.Supplier?.id
        );
        setSelectedSupplier(getSupplier);
      }
    }
  }, [id]);
  //End check if old Data
  //NET
  function subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
  }
  function subtotalQuantity(items) {
    return items.map(({ quantity }) => quantity).reduce((sum, i) => sum + i, 0);
  }
  const TAX_RATE = 0.13;
  const invoice_quantity = subtotalQuantity(purchaseRows);
  const invoice_Subtotal = subtotal(purchaseRows);
  const invoice_TaxableAmount = invoice_Subtotal - discount;
  const invoice_Taxes = (TAX_RATE * invoice_TaxableAmount)?.toFixed(2);

  const invoice_Total = parseFloat(
    parseFloat(invoice_Taxes) + parseFloat(invoice_TaxableAmount)
  )?.toFixed(2);
  {
    /* <TextField type="number" id="subtotal_discount" /> */
  }
  //END NET

  const handleSubmit = (e) => {
    e.preventDefault();
    const timeStamp_value = moment().format();
    if (purchaseRows?.length > 0) {
      const dataVar = {
        user_id: 1,
        biller_id: 1,
        discount: discount ? parseFloat(discount) : 0,
        tax: invoice_Taxes ? parseFloat(invoice_Taxes) : 0,
        grand_total: invoice_Total ? parseFloat(invoice_Total) : 0,
        Purchases: purchaseRows?.map((ele) => {
          return {
            product_id: ele.product_id,
            quantity: parseInt(ele.quantity),
            rate: ele.rate,
          };
        }),
        timeStamp: timeStamp_value,
        payment_status_id: 1,
        supplier_id: supplierId,
        payment_type_id: 1,
        note,
      };

      if (id && id !== "create") {
        dataVar.id = parseInt(id);
        updateReferenceBill({
          variables: dataVar,
        });
      } else {
        createReferenceBill({
          variables: dataVar,
        });
      }
    }
  };
  return (
    <>
      <AccountingPurchase_Context.Provider
        value={{
          setPurchaseRows,
          purchaseRows,
          handlePurchaseAdd,
          handlePurchaseRemove,
          discount,
          setDiscount,

          invoice_quantity,
          invoice_Subtotal,
          invoice_TaxableAmount,
          invoice_Taxes,
          invoice_Total,

          supplierId,
          setAccountId,

          selectedSupplier,
          setSelectedSupplier,

          handleSubmit,
          note,
          setNote,
        }}
      >
        {children}
      </AccountingPurchase_Context.Provider>
    </>
  );
};

export default AccountingPurchase_Provider;
