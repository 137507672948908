import { useMutation } from "@apollo/client";
import EnhancedTable from "Components/Table/EnhancedTable";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { delAccountingCategories, setLoaded } from "Redux/Action/action";
import API from "Services/API";
import AccountingCategoryContextProvider, {
  AccountingCategoryContext,
} from "./Context";

const DELETE_ACCOUNTING_CATEGORY = API.MUTATION.DELETE_ACCOUNTING_CATEGORY;
const AccountingCategoryList = () => {
  return (
    <AccountingCategoryContextProvider>
      <AccountingCategoryListContent />
    </AccountingCategoryContextProvider>
  );
};
const AccountingCategoryListContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteAccountingCategory] = useMutation(DELETE_ACCOUNTING_CATEGORY);
  const { accountingCategories } = useContext(AccountingCategoryContext);
  const headRows = [
    // { id: "id", numeric: true, disablePadding: true, label: "id" },

    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Title",
      width: "170px",
    },

    {
      id: "note",
      numeric: false,
      disablePadding: true,
      label: "Note",
    },
  ];
  const handleOpen = () => {};
  const Accounting_data = accountingCategories?.filter(
    (account) => !account.isSupplier
  );
  const rows = Accounting_data;
  function addFn(event) {
    event.preventDefault();
    history.push("accountingCategories/create");
    dispatch(setLoaded({ accountingCategories: false }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    history.push(`accountingCategories/${id}`);
    // dispatch(
    //   setAccountModal({
    //     modal: true,
    //     data: { id: id },
    //     type: "update"
    //   })
    // );
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteAccountingCategory({ variables: { id } });
    if (deleteFn?.data?.deleteAccountingCategory) {
      // refetch();
      if (id) {
        console.log("DELETED_id", id);
        dispatch(delAccountingCategories(id));
      }
      // dispatch(setLoaded({ accountingCategories: false }));
    }
  }
  const refetchFn = () => {
    // refetch();
    dispatch(setLoaded({ accountingCategories: false }));
  };
  return (
    <>
      <br />
      <EnhancedTable
        baseUrl={"/equipments"}
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Accounting Category"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default AccountingCategoryList;
