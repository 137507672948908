import { TextField } from "@material-ui/core";
import { Column, Row } from "Components/DesignComponent";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import API from "Services/API";
import { AccountingPurchase_Context } from "../Context/Accounting.context";

const REFERENCE_BILLS = API.QUERY.REFERENCE_BILLS;
function SupplierContainer() {
  const {
    register,
    watch,
    control,
    setValue,

    formState: { errors },
  } = useForm();
  const { supplierId, setAccountId, selectedSupplier, setSelectedSupplier } =
    useContext(AccountingPurchase_Context);
  const [openDropdown, setOpenDropdown] = useState(false);

  const { suppliers } = useSelector(
    (state) => ({
      accounts: state.persistent.accounts,
    }),
    shallowEqual
  );
  const { supplierName: supplierNameSearch, supplierNo, pan_no } = watch();

  const handleContactNumberChange = (ele) => {
    setOpenDropdown(false);
    setValue("supplierName", ele.name);
    setSelectedSupplier(ele);
  };
  const handleSupplierNo = (supplierNo) => {
    const findSupplier = suppliers?.find(
      (ele) => ele.phone_no === supplierNo + ""
    );
    if (findSupplier) {
      setSelectedSupplier(findSupplier);
    } else {
      setSelectedSupplier({
        phone_no: supplierNo,
      });
    }
  };
  const handlePanNumber = (pan_no) => {
    const findSupplier = suppliers?.find((ele) => ele.pan_no == pan_no);
    if (findSupplier) {
      setSelectedSupplier(findSupplier);
    } else {
      setSelectedSupplier({
        pan_no: pan_no,
      });
    }
  };
  useEffect(() => {
    if (supplierNo) {
      handleSupplierNo(supplierNo);
    }
  }, [supplierNo]);
  useEffect(() => {
    if (pan_no) {
      handlePanNumber(pan_no);
    }
  }, [pan_no]);

  useMemo(() => {
    if (selectedSupplier) {
      console.log("selectedSupplier", selectedSupplier);
      setValue("supplierNo", selectedSupplier.phone_no);
      setValue("supplierName", selectedSupplier.name);
      setValue("pan_no", selectedSupplier.pan_no);
      setAccountId(selectedSupplier.id);
    }
  }, [selectedSupplier]);
  return (
    <div className="payment-container ">
      <div className="payment-title">
        <span>Supplier</span>
      </div>
      <div className="payment-body">
        <div className="payment-customer">
          <Row>
            <Column className="w-100">
              <div className="product-search-container">
                <div>
                  <TextField
                    variant="outlined"
                    label="Supplier Name"
                    id="supplierName"
                    required
                    size="small"
                    className="payment-customerName"
                    onFocus={(e) => {
                      setOpenDropdown(true);
                    }}
                    //after register for blur effect
                    InputLabelProps={{ shrink: true }}
                    {...register("supplierName")}
                    onBlur={(e) => {
                      setOpenDropdown(false);
                    }}
                  />
                </div>
                {openDropdown && (
                  <div className="product-search-content">
                    <div className="product-search-dropdown-heading">
                      <div>
                        <span
                          style={{
                            color: "#262626",

                            fontWeight: 600,
                          }}
                        >
                          {/* Recent */}
                        </span>
                      </div>
                      <div
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          // dispatch(setRemoveConnectionURLAll());
                        }}
                      >
                        <span
                          style={{ color: "#0095f6" }}
                          className="pointer-cursor"
                        >
                          Add
                        </span>
                      </div>
                    </div>
                    <ul>
                      {suppliers
                        ?.sort(function (a, b) {
                          return moment
                            .utc(a.updatedAt)
                            .diff(moment.utc(b.updatedAt));
                        })
                        ?.map((ele) => {
                          let position = ele?.name
                            ?.toLowerCase()
                            ?.search(supplierNameSearch?.toLowerCase());
                          if (position >= 0)
                            return (
                              <div
                                className="connection-url-dropdown-content "
                                onMouseDown={(e) => {
                                  // dispatch(
                                  //   setAddConnectionURL({
                                  //     timestamp: moment.now(),
                                  //     url: ele.url,
                                  //   })
                                  // );

                                  handleContactNumberChange(ele);
                                }}
                              >
                                <span>{ele.name}</span>
                              </div>
                            );
                        })}
                    </ul>
                  </div>
                )}
              </div>
            </Column>
          </Row>
          <Row className="mt1">
            <Column className="w-100">
              <TextField
                variant="outlined"
                label="Pan number"
                id="pan_no"
                InputLabelProps={{ shrink: true }}
                size="small"
                className="payment-customerNo"
                {...register("pan_no")}
              />
            </Column>
          </Row>
          <Row className="mt1">
            <Column className="w-100">
              <TextField
                variant="outlined"
                label="Contact no"
                id="supplierNo"
                InputLabelProps={{ shrink: true }}
                size="small"
                className="payment-customerNo"
                {...register("supplierNo")}
              />
            </Column>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default SupplierContainer;
