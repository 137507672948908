const { gql } = require("@apollo/client");
const CATEGORY_QUERY = {
  CATEGORIES: gql`
    query {
      parentCategories {
        id
        name
        description
        image_file_url
      }
    }
  `,
  CATEGORY: gql`
    query ($id: Int!) {
      parentCategory(id: $id) {
        id
        name
        description
        image_file_url
      }
    }
  `,
};
const USER_MUTATION = {
  SIGN_ON: gql`
    mutation ($login: String!, $password: String!) {
      signIn(login: $login, password: $password) {
        token
      }
    }
  `,
};
const CATEGORY_MUTATION = {
  ADD_CATEGORY: gql`
    mutation ($name: String!, $description: String, $pic: Upload) {
      addParentCategory(name: $name, description: $description, pic: $pic) {
        success
        msg
      }
    }
  `,
  UPDATE_CATEGORY: gql`
    mutation ($name: String!, $description: String, $id: Int!, $pic: Upload) {
      updateParentCategory(
        id: $id
        name: $name
        description: $description
        pic: $pic
      ) {
        success
        msg
      }
    }
  `,
  DELETE_CATEGORY: gql`
    mutation ($id: Int!) {
      deleteParentCategory(id: $id) {
        success
        msg
      }
    }
  `,
};
const MODEL_QUERY = {
  MODELS: gql`
    query {
      childOneCategories {
        id
        name
        description
        parentId
        AccountId
        image_file_url
        ParentCat {
          id
          name
          description
        }
        Account {
          id
          name
        }
      }
    }
  `,
  MODEL: gql`
    query ($id: Int!) {
      childOneCategory(id: $id) {
        id
        name
        description
        image_file_url
        parentId
        AccountId
      }
    }
  `,
};

const MODEL_MUTATION = {
  ADD_MODEL: gql`
    mutation (
      $name: String!
      $description: String
      $parentId: Int!
      $AccountId: Int!
      $pic: Upload
    ) {
      addChildOneCategory(
        name: $name
        description: $description
        parentId: $parentId
        AccountId: $AccountId
        pic: $pic
      ) {
        success
        msg
      }
    }
  `,
  UPDATE_MODEL: gql`
    mutation (
      $name: String!
      $description: String
      $id: Int!
      $parentId: Int!
      $AccountId: Int!
      $pic: Upload
    ) {
      updateChildOneCategory(
        id: $id
        name: $name
        description: $description
        parentId: $parentId
        AccountId: $AccountId
        pic: $pic
      ) {
        success
        msg
      }
    }
  `,
  DELETE_MODEL: gql`
    mutation ($id: Int!) {
      deleteChildOneCategory(id: $id) {
        success
        msg
      }
    }
  `,
};

const ACCOUNT_QUERY = {
  ACCOUNTS: gql`
    query ($orderBy: SortOrder, $fromDate: String) {
      Accounts(orderBy: $orderBy, fromDate: $fromDate) {
        id
        name
        pan_no
        phone_no
        note
        updatedAt
        isSupplier
      }
    }
  `,
  ACCOUNT: gql`
    query ($id: Int!) {
      Account(id: $id) {
        id
        name
        pan_no
        phone_no
        note
        isSupplier
      }
    }
  `,
};
const ACCOUNTING_BILL_QUERY = {
  ACCOUNTING_BILLS: gql`
    query ($orderBy: SortOrder, $fromDate: String) {
      accountingBills(orderBy: $orderBy, fromDate: $fromDate) {
        id
        date
        entry_type
        amount
        note
        payment_type_id
        accounting_cat_id
        updatedAt
      }
    }
  `,
  ACCOUNTING_BILL: gql`
    query ($id: Int!) {
      accountingBill(id: $id) {
        id
        date
        entry_type
        amount
        note
        payment_type_id
        accounting_cat_id
        updatedAt
      }
    }
  `,
};
const ACCOUNTING_CATEGORY_QUERY = {
  ACCOUNTING_CATEGORIES: gql`
    query ($orderBy: SortOrder, $fromDate: String) {
      accountingCategories(orderBy: $orderBy, fromDate: $fromDate) {
        id
        title
        note
        updatedAt
      }
    }
  `,
  ACCOUNTING_CATEGORY: gql`
    query ($id: Int!) {
      accountingCategory(id: $id) {
        id
        title
        note
      }
    }
  `,
};
const CUSTOMER_QUERY = {
  CUSTOMERS: gql`
    query ($orderBy: SortOrder, $fromDate: String) {
      customers(orderBy: $orderBy, fromDate: $fromDate) {
        id
        name
        phone_no
        note
        updatedAt
      }
    }
  `,
  CUSTOMER: gql`
    query ($id: Int!) {
      customer(id: $id) {
        id
        name
        phone_no
        note
      }
    }
  `,
};
const FACET_QUERY = {
  FACETS: gql`
    query ($orderBy: SortOrder, $fromDate: String) {
      facets(orderBy: $orderBy, fromDate: $fromDate) {
        id
        isPrivate
        code
        FacetValues {
          id
          code
        }
        updatedAt
      }
    }
  `,
  FACET: gql`
    query ($id: Int!) {
      facet(id: $id) {
        id
        isPrivate
        code
        FacetValues {
          id
          code
        }
      }
    }
  `,
};
const PAYMENT_QUERY = {
  PAYMENTS: gql`
    query {
      payments {
        id
        title
      }
    }
  `,
  PAYMENT: gql`
    query ($id: Int!) {
      payment(id: $id) {
        id
        title
      }
    }
  `,
};
const PURCHASE_QUERY = {
  PURCHASES: gql`
    query {
      purchases {
        id
        product_id
        timeStamp
        quantity
        rate
        expiry_date
        note
        reference_id
      }
    }
  `,
  PURCHASE: gql`
    query ($id: Int!) {
      purchase(id: $id) {
        id
        product_id
        timeStamp
        quantity
        rate
        expiry_date
        note
        reference_id
      }
    }
  `,
};
const SALE_QUERY = {
  SALES: gql`
    query {
      Accounts {
        id
        name
        pan_no
        phone_no
        note
      }
    }
  `,
  SALE: gql`
    query ($id: Int!) {
      sale(id: $id) {
        id
        name
        pan_no
        phone_no
        note
      }
    }
  `,
};
const REFERENCE_BILL_QUERY = {
  REFERENCE_BILLS: gql`
    query ($orderBy: SortOrder, $fromDate: String) {
      referenceBills(orderBy: $orderBy, fromDate: $fromDate) {
        id
        bill_id
        biller_id
        discount
        tax
        grand_total
        note
        payment_type_id
        user_id
        company_id
        supplier_id
        shipping_id
        timeStamp
        status
        store_id
        customer_id
        payment_status_id
        ReferenceBillType {
          id
          title
        }
        Purchases {
          id
          product_id
          quantity
          rate
          expiry_date
          note
          reference_id
        }
        Sales {
          id
          product_id
          quantity
          rate
          note
          reference_id
        }
        User {
          userName
        }
        Company {
          name
        }
        Account {
          id
          name
        }
        PaymentStatus {
          title
        }
        updatedAt
      }
    }
  `,
  REFERENCE_BILL: gql`
    query ($id: Int!) {
      referenceBill(id: $id) {
        id
        bill_id
        biller_id
        discount
        tax
        grand_total
        note
        payment_type_id
        user_id
        company_id
        supplier_id
        shipping_id
        timeStamp
        status
        store_id
        customer_id
        payment_status_id
        ReferenceBillType {
          id
          title
        }
      }
    }
  `,
};
const COMPANY_QUERY = {
  COMPANIES: gql`
    query ($orderBy: SortOrder, $fromDate: String) {
      companies(orderBy: $orderBy, fromDate: $fromDate) {
        id
        name
        pan_no
        phone_no
        note
        updatedAt
      }
    }
  `,
  COMPANY: gql`
    query ($id: Int!) {
      company(id: $id) {
        id
        name
        pan_no
        phone_no
        note
      }
    }
  `,
};
const PRODUCT_QUERY = {
  FETCH_PRODUCTS_ASSET_ID: gql`
    query ($product_id: Int!) {
      fetchProductAssetId(product_id: $product_id)
    }
  `,
  PRODUCTS: gql`
    query ($orderBy: SortOrder, $fromDate: String) {
      products(orderBy: $orderBy, fromDate: $fromDate) {
        id
        name
        code
        barcode_symbology
        unit
        costPrice
        marketPrice
        enabled
        updatedAt
        FacetValues {
          id
          code
        }
        featuredImageId
        featuredImage {
          id
          filename
        }
        ProductwithProductAssets {
          id
          product_asset_id
        }
        ProductAssets {
          id
          filename
        }
      }
    }
  `,
  PRODUCT: gql`
    query ($id: Int!) {
      product(id: $id) {
        id
        name
        code
        barcode_symbology
        unit
        costPrice
        marketPrice
        enabled
        FacetValues {
          id
          code
        }
        ProductAssets {
          id
          filename
        }
      }
    }
  `,
  STOCK_LISTS_QUERY: gql`
    query {
      stockLists {
        name
        sale_quantity
        purchase_quantity
        total_quantity
      }
    }
  `,
};
const USER_QUERY = {
  USERS: gql`
    query {
      users {
        userName
        Roles {
          description
        }
        userDetails {
          FileUpload {
            filename
          }
        }
      }
    }
  `,
  USER: gql`
    query ($id: Int!) {
      user(id: $id) {
        userName
        Roles {
          description
        }
        userDetails {
          FileUpload {
            filename
          }
        }
      }
    }
  `,
};
const ACCOUNT_MUTATION = {
  ADD_ACCOUNT: gql`
    mutation (
      $name: String!
      $pan_no: Int!
      $phone_no: String
      $note: String
      $isSupplier: Boolean
    ) {
      createAccount(
        name: $name
        pan_no: $pan_no
        phone_no: $phone_no
        note: $note
        isSupplier: $isSupplier
      ) {
        id
        name
        pan_no
        phone_no
        note
        isSupplier
      }
    }
  `,
  UPDATE_ACCOUNT: gql`
    mutation (
      $name: String!
      $pan_no: Int!
      $phone_no: String
      $note: String
      $id: Int!
      $isSupplier: Boolean
    ) {
      updateAccount(
        id: $id
        name: $name
        pan_no: $pan_no
        phone_no: $phone_no
        note: $note
        isSupplier: $isSupplier
      ) {
        id
        name
        pan_no
        phone_no
        note
        isSupplier
      }
    }
  `,
  DELETE_ACCOUNT: gql`
    mutation ($id: Int!) {
      deleteAccount(id: $id)
    }
  `,
};
const ACCOUNTING_BILL_MUTATION = {
  ADD_ACCOUNTING_BILL: gql`
    mutation (
      $date: Date
      $entry_type: String
      $amount: Float
      $note: String
      $payment_type_id: Int
      $accounting_cat_id: Int
      $updatedAt: Date
    ) {
      createAccountingBill(
        date: $date
        entry_type: $entry_type
        amount: $amount
        note: $note
        payment_type_id: $payment_type_id
        accounting_cat_id: $accounting_cat_id
        updatedAt: $updatedAt
      ) {
        id
        date
        entry_type
        amount
        note
        payment_type_id
        accounting_cat_id
        updatedAt
      }
    }
  `,
  UPDATE_ACCOUNTING_BILL: gql`
    mutation (
      $id: Int!
      $date: Date
      $entry_type: String
      $amount: Float
      $note: String
      $payment_type_id: Int
      $accounting_cat_id: Int
      $updatedAt: Date
    ) {
      updateAccountingBill(
        id: $id
        date: $date
        entry_type: $entry_type
        amount: $amount
        note: $note
        payment_type_id: $payment_type_id
        accounting_cat_id: $accounting_cat_id
        updatedAt: $updatedAt
      ) {
        id
        date
        entry_type
        amount
        note
        payment_type_id
        accounting_cat_id
        updatedAt
      }
    }
  `,
  DELETE_ACCOUNTING_BILL: gql`
    mutation ($id: Int!) {
      deleteAccountingBill(id: $id)
    }
  `,
};
const ACCOUNTING_CATEGORY_MUTATION = {
  ADD_ACCOUNTING_CATEGORY: gql`
    mutation ($title: String!, $note: String) {
      createAccountingCategory(title: $title, note: $note) {
        id
        title
        note
      }
    }
  `,
  UPDATE_ACCOUNTING_CATEGORY: gql`
    mutation ($title: String!, $note: String, $id: Int!) {
      updateAccountingCategory(id: $id, title: $title, note: $note) {
        id
        title
        note
      }
    }
  `,
  DELETE_ACCOUNTING_CATEGORY: gql`
    mutation ($id: Int!) {
      deleteAccountingCategory(id: $id)
    }
  `,
};
const CUSTOMER_MUTATION = {
  ADD_CUSTOMER: gql`
    mutation ($name: String!, $phone_no: String, $note: String) {
      createCustomer(name: $name, phone_no: $phone_no, note: $note) {
        id
        name
        phone_no
        note
      }
    }
  `,
  UPDATE_CUSTOMER: gql`
    mutation ($name: String!, $phone_no: String, $note: String, $id: Int!) {
      updateCustomer(id: $id, name: $name, phone_no: $phone_no, note: $note) {
        id
        name
        phone_no
        note
      }
    }
  `,
  DELETE_CUSTOMER: gql`
    mutation ($id: Int!) {
      deleteCustomer(id: $id)
    }
  `,
};
const COMPANY_MUTATION = {
  ADD_COMPANY: gql`
    mutation ($name: String!, $pan_no: Int!, $phone_no: String, $note: String) {
      createCompany(
        name: $name
        pan_no: $pan_no
        phone_no: $phone_no
        note: $note
      ) {
        id
        name
        pan_no
        phone_no
        note
      }
    }
  `,
  UPDATE_COMPANY: gql`
    mutation (
      $name: String!
      $pan_no: Int!
      $phone_no: String
      $note: String
      $id: Int!
    ) {
      updateCompany(
        id: $id
        name: $name
        pan_no: $pan_no
        phone_no: $phone_no
        note: $note
      ) {
        id
        name
        pan_no
        phone_no
        note
      }
    }
  `,
  DELETE_COMPANY: gql`
    mutation ($id: Int!) {
      deleteCompany(id: $id)
    }
  `,
};
const PRODUCT_MUTATION = {
  ADD_PRODUCT: gql`
    mutation (
      $name: String
      $code: String
      $barcode_symbology: String
      $unit: String
      $costPrice: Float
      $marketPrice: Float
      $enabled: Int
      $ProductFacetValues: [FacetValueId]
      $uploadProductAssets: [Upload]
      $featuredImageId: Int
    ) {
      createProduct(
        name: $name
        code: $code
        barcode_symbology: $barcode_symbology
        unit: $unit
        enabled: $enabled
        costPrice: $costPrice
        marketPrice: $marketPrice
        ProductFacetValues: $ProductFacetValues
        uploadProductAssets: $uploadProductAssets
        featuredImageId: $featuredImageId
      ) {
        name
        code
        barcode_symbology
        unit
        costPrice
        marketPrice
        enabled
        featuredImageId
      }
    }
  `,
  UPDATE_PRODUCT: gql`
    mutation (
      $id: Int!
      $name: String
      $code: String
      $barcode_symbology: String
      $unit: String
      $costPrice: Float
      $marketPrice: Float
      $enabled: Int
      $ProductFacetValues: [FacetValueId]
      $uploadProductAssets: [Upload]
      $ProductwithProductAssets: [idList]
      $featuredImageId: Int
    ) {
      updateProduct(
        id: $id
        name: $name
        code: $code
        barcode_symbology: $barcode_symbology
        unit: $unit
        enabled: $enabled
        costPrice: $costPrice
        marketPrice: $marketPrice
        ProductFacetValues: $ProductFacetValues
        uploadProductAssets: $uploadProductAssets
        ProductwithProductAssets: $ProductwithProductAssets
        featuredImageId: $featuredImageId
      ) {
        id
        name
        code
        barcode_symbology
        unit
        costPrice
        marketPrice
        enabled
      }
    }
  `,
  DELETE_PRODUCT: gql`
    mutation ($id: Int!) {
      deleteProduct(id: $id)
    }
  `,
  UPLOAD_PRODUCT_ASSETS: gql`
    mutation ($files: [Upload!]!) {
      uploadProductAssets(files: $files)
    }
  `,
  UPLOAD_PRODUCT_PRODUCT_ASSETS: gql`
    mutation ($idList: [idList!]!) {
      uploadProductProductAsset(idList: $idList)
    }
  `,
};

const FILE_MUTATION = {
  MULTIPLE_UPLOAD: gql`
    mutation ($files: [Upload!]!) {
      uploadMultiple(files: $files)
    }
  `,
  SINGLE_UPLOAD: gql`
    mutation ($file: Upload!) {
      singleUpload(file: $file) {
        filename
        mimetype
        encoding
        url
      }
    }
  `,
};
const FILE_QUERY = {
  FETCH_ASSETS: gql`
    query ($assets: [idInputList]) {
      fetchAsset(assets: $assets) {
        id
        filename
      }
    }
  `,
};
const FACET_MUTATION = {
  ADD_FACET: gql`
    mutation ($code: String!, $FacetValues: [FacetValueInput]) {
      createFacet(code: $code, FacetValues: $FacetValues) {
        isPrivate
        code
        id
      }
    }
  `,
  UPDATE_FACET: gql`
    mutation ($code: String!, $id: Int!, $FacetValues: [FacetValueInput]) {
      updateFacet(id: $id, code: $code, FacetValues: $FacetValues) {
        id
        isPrivate
        code
      }
    }
  `,
  DELETE_FACET: gql`
    mutation ($id: Int!) {
      deleteFacet(id: $id)
    }
  `,
};
const REFERENCE_BILL_MUTATION = {
  ADD_REFERENCE_BILL: gql`
    mutation (
      $biller_id: Int
      $discount: Float
      $tax: Float
      $grand_total: Float
      $note: String
      $payment_type_id: Int
      $user_id: Int
      $company_id: Int
      $supplier_id: Int
      $shipping_id: Int
      $timeStamp: String
      $status: String
      $store_id: Int
      $customer_id: Int
      $Purchases: [PurchaseInput]
      $Sales: [SaleInput]
      $payment_status_id: Int
    ) {
      createReferenceBill(
        biller_id: $biller_id
        discount: $discount
        tax: $tax
        grand_total: $grand_total
        note: $note
        payment_type_id: $payment_type_id
        user_id: $user_id
        company_id: $company_id
        supplier_id: $supplier_id
        shipping_id: $shipping_id
        timeStamp: $timeStamp
        status: $status
        store_id: $store_id
        customer_id: $customer_id
        Purchases: $Purchases
        Sales: $Sales
        payment_status_id: $payment_status_id
      ) {
        id
        bill_id
        biller_id
        discount
        tax
        grand_total
        note
        payment_type_id
        user_id
        company_id
        supplier_id
        shipping_id
        timeStamp
        status
        store_id
        customer_id
        payment_status_id
        Purchases {
          id
          product_id
          timeStamp
          quantity
          rate
          expiry_date
          note
          reference_id
        }
        Sales {
          id
          product_id
          quantity
          rate
          note
          reference_id
        }
      }
    }
  `,
  UPDATE_REFERENCE_BILL: gql`
    mutation (
      $id: Int!
      $bill_id: Int
      $biller_id: Int
      $discount: Float
      $tax: Float
      $grand_total: Float
      $note: String
      $payment_type_id: Int
      $user_id: Int
      $company_id: Int
      $supplier_id: Int
      $shipping_id: Int
      $timeStamp: String
      $status: String
      $store_id: Int
      $customer_id: Int
      $Purchases: [PurchaseInput]
      $Sales: [SaleInput]
      $payment_status_id: Int
    ) {
      updateReferenceBill(
        id: $id
        bill_id: $bill_id
        biller_id: $biller_id
        discount: $discount
        tax: $tax
        grand_total: $grand_total
        note: $note
        payment_type_id: $payment_type_id
        user_id: $user_id
        company_id: $company_id
        supplier_id: $supplier_id
        shipping_id: $shipping_id
        timeStamp: $timeStamp
        status: $status
        store_id: $store_id
        customer_id: $customer_id
        Purchases: $Purchases
        Sales: $Sales
        payment_status_id: $payment_status_id
      ) {
        id
        bill_id
        biller_id
        discount
        tax
        grand_total
        note
        payment_type_id
        user_id
        company_id
        supplier_id
        shipping_id
        timeStamp
        status
        store_id
        customer_id
        payment_status_id
        Purchases {
          id
          product_id
          timeStamp
          quantity
          rate
          expiry_date
          note
          reference_id
        }
        Sales {
          id
          product_id
          quantity
          rate
          note
          reference_id
        }
      }
    }
  `,
  // UPDATE_REFERENCE_BILL: gql`
  //   mutation ($code: String!, $id: Int!) {
  //     updateFacet(id: $id, code: $code) {
  //       id
  //       isPrivate
  //       code
  //     }
  //   }
  // `,
  DELETE_REFERENCE_BILL: gql`
    mutation ($id: Int!) {
      deleteReferenceBill(id: $id)
    }
  `,
};
export default {
  QUERY: {
    ...ACCOUNTING_CATEGORY_QUERY,
    ...ACCOUNTING_BILL_QUERY,
    ...ACCOUNT_QUERY,
    ...CUSTOMER_QUERY,
    ...CATEGORY_QUERY,
    ...MODEL_QUERY,
    ...PRODUCT_QUERY,
    ...COMPANY_QUERY,
    ...USER_QUERY,
    ...REFERENCE_BILL_QUERY,
    ...FACET_QUERY,
    ...PAYMENT_QUERY,
    ...FILE_QUERY,
    ...PURCHASE_QUERY,
    ...SALE_QUERY,
  },
  MUTATION: {
    ...ACCOUNTING_CATEGORY_MUTATION,
    ...ACCOUNTING_BILL_MUTATION,
    ...ACCOUNT_MUTATION,
    ...CATEGORY_MUTATION,
    ...MODEL_MUTATION,
    ...FILE_MUTATION,
    ...USER_MUTATION,
    ...PRODUCT_MUTATION,
    ...COMPANY_MUTATION,
    ...FACET_MUTATION,
    ...REFERENCE_BILL_MUTATION,
    ...CUSTOMER_MUTATION,
  },
};
