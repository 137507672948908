import { useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setAccounts } from "Redux/Action/action";
import API from "Services/API";

export const FacetContext = createContext();
const FACETS = API.QUERY.FACETS;
const FacetContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { loaded, facets } = useSelector(
    (state) => ({
      loaded: state.persistent.loaded,
      facets: state.persistent.facets,
    }),
    shallowEqual
  );
  const [open, setOpen] = React.useState(false);
  const [openFileModal, setOpenFileModal] = React.useState(false);
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [nameList, setNameList] = React.useState([]);
  const [imageList, setImageList] = React.useState([]);

  const [list, setList] = React.useState([]);
  const [newList, setNewList] = React.useState([]);
  //   const [fetchFacets] = useLazyQuery(FACETS, {
  //     fetchPolicy: "no-cache",
  //     onCompleted: ({ facets }) => {
  //       dispatch(setAccounts(facets));
  //     }
  //   });

  //   useEffect(() => {
  //     if (!loaded.facets)
  //       fetchFacets({
  //         variables: {
  //           fromDate: facets?.[0]?.updatedAt
  //             ? moment(facets?.[0]?.updatedAt)?.toDate()
  //             : "",
  //           orderBy: "DESC"
  //         }
  //       });
  //     return () => {
  //       // cleanup
  //     };
  //   }, [loaded]);
  return (
    <FacetContext.Provider
      value={{
        open,
        setOpen,
        openFileModal,
        setOpenFileModal,
        isEnabled,
        setIsEnabled,
        openSnackBar,
        setOpenSnackBar,
        nameList,
        setNameList,
        imageList,
        setImageList,
        list,
        setList,
        newList,
        setNewList,
        facets,
      }}
    >
      {children}
    </FacetContext.Provider>
  );
};
export default FacetContextProvider;
