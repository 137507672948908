import { useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { createContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setFacets,
  setPayments,
  setImageAssets,
  setProducts,
  setAccounts,
  setCustomers,
  setAccountingBills,
  setAccountingCategories,
} from "Redux/Action/action";
import API from "../API";
const PRODUCTS = API.QUERY.PRODUCTS;
const PAYMENTS = API.QUERY.PAYMENTS;
const ACCOUNTS = API.QUERY.ACCOUNTS;
const ACCOUNTING_BILLS = API.QUERY.ACCOUNTING_BILLS;
const ACCOUNTING_CATEGORIES = API.QUERY.ACCOUNTING_CATEGORIES;
const CUSTOMERS = API.QUERY.CUSTOMERS;
const FACETS = API.QUERY.FACETS;
const FETCH_ASSETS = API.QUERY.FETCH_ASSETS;

export const ApiContext = createContext();
const ApiContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const {
    loaded,
    products,
    facets,
    accounts,
    customers,
    accountingBills,
    accountingCategories,
  } = useSelector(
    (state) => ({
      loaded: state.persistent.loaded,
      products: state.persistent.products,
      facets: state.persistent.facets,
      accounts: state.persistent.accounts,
      accountingBills: state.persistent.accountingBills,
      accountingCategories: state.persistent.accountingCategories,
      customers: state.persistent.customers,
    }),
    shallowEqual
  );
  const field = {};
  let old_products = [];

  React.useMemo(() => {
    if (products) {
      old_products = products?.splice();
      if (old_products?.[0]?.updatedAt) {
        field.fromDate = moment(old_products?.[0]?.updatedAt)?.toDate();
        field.orderBy = "DESC";
      }
    }
  }, [products]);

  const [
    refetchProducts,
    { loading: re_product_loading, error: re_product_error },
  ] = useLazyQuery(PRODUCTS, {
    fetchPolicy: "no-cache",
    variables: field,
    onCompleted: (value) => {
      const reproducts = value?.products;
      if (reproducts) {
        reproducts?.map((ele) => {
          const foundIndex = old_products?.findIndex(
            (element) => element.id === ele.id
          );
          old_products[foundIndex] = ele;
        });
        dispatch(setProducts([...reproducts, ...old_products]));
      }
    },
  });
  const [fetchAccounts] = useLazyQuery(ACCOUNTS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ Accounts: newAccounts }) => {
      dispatch(setAccounts(newAccounts));
    },
  });
  const [fetchAccountingBills] = useLazyQuery(ACCOUNTING_BILLS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ accountingBills: newAccountingBills }) => {
      dispatch(setAccountingBills(newAccountingBills));
    },
  });

  const [fetchAccountingCategories] = useLazyQuery(ACCOUNTING_CATEGORIES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ accountingCategories: newAccountingCategories }) => {
      dispatch(setAccountingCategories(newAccountingCategories));
    },
  });

  const [fetchCustomer] = useLazyQuery(CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ customers: newCustomers }) => {
      dispatch(setCustomers(newCustomers));
    },
  });
  const [
    fetchProducts,
    {
      loading: product_loading,
      error: product_error,
      refetch: product_refetch,
    },
  ] = useLazyQuery(PRODUCTS, {
    fetchPolicy: "no-cache",
    variables: {
      orderBy: "DESC",
      // fromDate: moment().format()
    },
    onCompleted: (arg) => {
      if (arg) dispatch(setProducts(arg?.products));
    },
  });

  const [fetchPayments, { loading: payment_loading, error: payment_error }] =
    useLazyQuery(PAYMENTS, {
      fetchPolicy: "no-cache",

      onCompleted: (arg) => {
        if (arg?.payments) dispatch(setPayments(arg?.payments));
      },
    });

  const [fetchFacets, { loading: facet_loading, error: facet_error }] =
    useLazyQuery(FACETS, {
      fetchPolicy: "no-cache",

      onCompleted: (arg) => {
        if (arg) dispatch(setFacets(arg?.facets));
      },
    });
  const [fetchAssets, { loading: assets_loading, error: assets_error }] =
    useLazyQuery(FETCH_ASSETS, {
      variables: { assets: [] },
      onCompleted: (args) => {
        if (args?.fetchAsset) dispatch(setImageAssets(args?.fetchAsset));
      },
    });
  React.useEffect(() => {
    if (!loaded.products) {
      fetchProducts({
        variables: {
          fromDate: products?.[0]?.updatedAt
            ? moment(products?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    }
    if (!loaded.payments) {
      fetchPayments();
    }
    if (!loaded.accounts)
      fetchAccounts({
        variables: {
          fromDate: accounts?.[0]?.updatedAt
            ? moment(accounts?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    if (!loaded.accountingBills)
      fetchAccountingBills({
        variables: {
          fromDate: accountingBills?.[0]?.updatedAt
            ? moment(accountingBills?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    if (!loaded.accountingCategories)
      fetchAccountingCategories({
        variables: {
          fromDate: accountingCategories?.[0]?.updatedAt
            ? moment(accountingCategories?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });

    if (!loaded.customers)
      fetchCustomer({
        variables: {
          fromDate: customers?.[0]?.updatedAt
            ? moment(customers?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    if (!loaded.facets) {
      fetchFacets({
        variables: {
          fromDate: facets?.[0]?.updatedAt
            ? moment(facets?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    }
    if (!loaded.assets) {
      fetchAssets();
    }
    return () => {};
  }, [loaded]);
  if (product_loading || product_error || facet_loading || facet_error) {
    return (
      <>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: " flex",
            justifyContent: "center",
            alignItems: " center",
            minHeight: "calc(100vh - 4rem)",
          }}
        >
          <CircularProgress size={80} color="secondary" />
        </div>
      </>
    );
  }
  return (
    <ApiContext.Provider
      value={[refetchProducts, fetchAssets, { re_product_loading }]}
    >
      {children}
    </ApiContext.Provider>
  );
};
export default ApiContextProvider;
