import { useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAdminJsession, setLoaded } from "Redux/Action/action";
import API from "Services/API";
import EnhancedTable from "Components/Table/EnhancedTable";
import SaleContextProvider, { SaleContext } from "./sale.context";
const REFERENCE_BILLS = API.QUERY.REFERENCE_BILLS;
const DELETE_ACCOUNT = API.MUTATION.DELETE_ACCOUNT;
const Sale = () => {
  return (
    <SaleContextProvider>
      <SaleContent />
    </SaleContextProvider>
  );
};
const SaleContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteProduct] = useMutation(DELETE_ACCOUNT);
  const { referenceBills } = useContext(SaleContext);

  const headRows = [
    {
      id: "timeStamp",
      numeric: false,
      disablePadding: true,
      label: "Date/Time",
    },
    {
      id: "bill_id",
      numeric: false,
      disablePadding: true,
      label: "Bill Id",
      width: "170px",
    },
    {
      id: "payment_type_id",
      numeric: true,
      disablePadding: true,
      label: "Payment Type",
    },
    {
      id: "company_name",
      numeric: false,
      disablePadding: true,
      label: "Company",
    },
    {
      id: "user_name",
      numeric: false,
      disablePadding: true,
      label: "User Name",
    },

    {
      id: "Purchases",
      numeric: false,
      disablePadding: true,
      label: "Purchases",
    },

    {
      id: "supplier_name",
      numeric: false,
      disablePadding: true,
      label: "Supplier",
    },
    {
      id: "biller_id",
      numeric: false,
      disablePadding: true,
      label: "Biller",
    },
    {
      id: "payment_status_id",
      numeric: false,
      disablePadding: true,
      label: "Payment Status",
    },
    {
      id: "note",
      numeric: true,
      disablePadding: true,
      label: "Note",
    },
  ];
  const handleOpen = () => {};

  const sale_data = referenceBills;
  const rows = sale_data?.filter(
    (ele) => ele?.ReferenceBillType?.title?.toLowerCase() === "sale"
  );
  function addFn(event) {
    event.preventDefault();
    history.push("sales/create");
  }
  function updateFn(event, id) {
    event.preventDefault();
    history.push(`sales/${id}`);
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteProduct({ variables: { id } });
    if (deleteFn?.data?.deleteProduct) {
      dispatch(setLoaded({ referenceBills: false }));
    }
  }
  const refetchFn = () => {
    dispatch(setLoaded({ referenceBills: false }));
  };
  return (
    <>
      <br />
      <EnhancedTable
        baseUrl={"/equipments"}
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Sales"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default Sale;
