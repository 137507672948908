import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  Chip,
  FormControlLabel,
  Grid,
  Input,
  Modal,
  Paper,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { setLoaded } from "Redux/Action/action";
import API from "Services/API";
import ImageContainer from "../../ImageContainer/ImageContainer";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";
import TabCustom from "Components/Tab/TabCustom";
import ProductContextProvider, { ProductContext } from "./product.context";
import ProductListAssets from "./product.listAssets";
import { Column, Row } from "Components/DesignComponent";
import ImageUploadFn from "../FileUpload/ImageUpload";
const ADD_PRODUCT = API.MUTATION.ADD_PRODUCT;
const UPDATE_PRODUCT = API.MUTATION.UPDATE_PRODUCT;
const FETCH_PRODUCTS_ASSET_ID = API.QUERY.FETCH_PRODUCTS_ASSET_ID;

const ProductCreate = () => {
  return (
    <ProductContextProvider>
      <ProductCreateContent />
    </ProductContextProvider>
  );
};

const ProductCreateContent = () => {
  const { id } = useParams();
  const { facets, image_assets } = useSelector(
    (state) => ({
      facets: state.persistent.facets,
      image_assets: state.persistent.image_assets,
    }),
    shallowEqual
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const formEl = React.useRef();
  const {
    open,
    setOpen,
    openFileModal,
    setOpenFileModal,
    isEnabled,
    setIsEnabled,
    openSnackBar,
    setOpenSnackBar,
    nameList,
    setNameList,
    imageList,
    setImageList,
    list,
    setList,
    newList,
    uploadimageList,
    setUploadImageList,

    setNewList,
    products,

    featuredImageId,
    setfeaturedImageId,
  } = useContext(ProductContext);

  const product_id = parseInt(id);
  const getData = products?.find((ele) => ele.id === product_id);

  // const [
  //   fetchProductAssetId,
  //   {
  //     loading: product_asset_loading,
  //     error: product_asset_error,
  //     refetch: product_asset_refetch,
  //   },
  // ] = useLazyQuery(FETCH_PRODUCTS_ASSET_ID, {
  //   fetchPolicy: "no-cache",
  //   variables: { product_id: parseInt(product_id) },
  //   onCompleted: (arg) => {
  //     if (arg) {
  //       const fetchProductAssetId = arg?.fetchProductAssetId;
  //       if (fetchProductAssetId?.length > 0) {
  //         let imageData = [];
  //         fetchProductAssetId.map((id) => {
  //           const product_assets_image = image_assets?.find(
  //             (ele) => ele.id === id
  //           );
  //           imageData.push(product_assets_image);
  //         });
  //         setImageList(imageData);
  //       }
  //     }
  //   },
  // });
  useEffect(() => {
    if (product_id && getData) {
      setImageList(getData?.ProductAssets);
      setfeaturedImageId(getData?.featuredImageId);
    }
    return () => {
      setImageList([]);
      setfeaturedImageId(null);
    };
  }, [getData]);
  const row = getData;

  useEffect(() => {
    if (id && !row) {
      history.push("/admin/products");
    }
    if (row) {
      setList(row?.FacetValues?.map((ele) => ele.id));
      const {
        product_name,
        product_slug,
        product_barcode,
        product_unit,
        product_cost_price,
        product_market_price,
      } = formEl.current;
      product_name.value = row.name;
      product_slug.value = row.code;
      product_barcode.value = row.barcode_symbology;
      product_unit.value = row.unit;
      product_cost_price.value = row.costPrice;
      product_market_price.value = row.marketPrice;

      // setImageList(imageData);
      setIsEnabled(row.enabled);
      // refetchProducts();
    }
  }, [row, id]);

  const [createProduct] = useMutation(ADD_PRODUCT, {
    onCompleted: (arg) => {
      const {
        product_name,
        product_slug,
        product_barcode,
        product_unit,
        product_cost_price,
        product_market_price,
      } = formEl.current;
      setOpenSnackBar(true);
      setList([]);
      setNewList([]);
      setIsEnabled(true);
      product_name.value = null;
      product_slug.value = null;
      product_barcode.value = null;
      product_unit.value = null;
      product_cost_price.value = null;
      product_market_price.value = null;
      dispatch(setLoaded({ products: false }));

      // refetchProducts();
    },
  });
  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    onCompleted: (arg) => {
      setOpenSnackBar(true);
      dispatch(setLoaded({ products: false }));

      // refetchProducts();
    },
  });
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    let nameList = [];
    facets?.map((ele) => {
      const facetValues = ele.FacetValues;
      facetValues.map((elem) => {
        nameList.push({
          id: elem.id,
          code: `${ele.code}: ${elem.code}`,
        });
      });
    });
    setNameList(nameList);
  }, [facets]);

  React.useEffect(() => {
    if (list) {
      const handleList = list?.map((ele) => {
        return {
          facetValue_id: ele,
          code: nameList?.find((elem) => elem.id === ele)?.code,
        };
      });
      setNewList(handleList);
    }
  }, [list, nameList]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // const {
    //   product_name,
    //   product_slug,
    //   product_barcode,
    //   product_unit,
    //   product_cost_price,
    //   product_market_price
    // } = e.target;
    const {
      product_name,
      product_slug,
      product_barcode,
      product_unit,
      product_cost_price,
      product_market_price,
    } = formEl.current;
    const productVariable = {
      name: product_name?.value,
      code: product_slug?.value,
      barcode_symbology: product_barcode?.value,
      unit: product_unit?.value,
      costPrice: parseFloat(product_cost_price?.value),
      marketPrice: parseFloat(product_market_price?.value),
      enabled: isEnabled ? 1 : 0,
      ProductFacetValues: newList?.map((ele) => {
        return { facetValue_id: ele.facetValue_id };
      }),
      ProductwithProductAssets: imageList?.map((ele) => {
        return {
          product_asset_id: ele.id,
          product_id: parseInt(id),
        };
      }),
      uploadProductAssets: uploadimageList,
      featuredImageId: featuredImageId,
    };
    if (id) {
      updateProduct({
        variables: { ...productVariable, id: parseInt(id) },
      });
    } else
      createProduct({
        variables: productVariable,
      });
  };
  return (
    <div className="p2">
      <>
        <Row>
          <Column>
            <form ref={formEl} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Paper className="p2">
                    <span>
                      <h1>PRODUCT {id ? "UPDATE" : "CREATE"}</h1>
                    </span>
                    <Grid container spacing={4} className="p2">
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="product_name"
                          label="Product Name"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="product_slug"
                          label="Slug"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="product_barcode"
                          label="Barcode Symbology"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="product_unit"
                          label="Unit"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          type="number"
                          id="product_cost_price"
                          label="Cost Price"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          type="number"
                          id="product_market_price"
                          label="Market Price"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="default"
                          startIcon={<Add />}
                          onClick={handleOpen}
                        >
                          FACETS
                        </Button>
                      </Grid>
                    </Grid>

                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="product-facet-title"
                      aria-describedby="product-facet-description"
                    >
                      <div
                        style={{
                          minWidth: "450px",
                          padding: "1rem",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: `translate(-${50}%, -${50}%)`,
                        }}
                      >
                        <Paper className="p1">
                          <h2 id="product-facet-title">Add facets</h2>
                          <MultipleSelect
                            list={list}
                            setList={setList}
                            names={nameList}
                          />
                        </Paper>
                      </div>
                    </Modal>
                    {newList?.map((value) => {
                      return (
                        <>
                          <Chip key={value?.id} label={value?.code} />
                        </>
                      );
                    })}
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Modal
                    open={openFileModal}
                    onClose={() => setOpenFileModal(false)}
                    aria-labelledby="product-asset-title"
                    aria-describedby="product-asset-description"
                  >
                    <ProductListAssets
                      setOpenFileModal={setOpenFileModal}
                      imageList={imageList}
                    />
                  </Modal>
                  <Paper>
                    <ImageUploadFn />
                    {/* <ImageContainer
                    setOpenFileModal={setOpenFileModal}
                    product_id={id}
                    featuredImageId={row?.featuredImageId}
                    imageList={imageList}
                    setImageList={setImageList}
                    product_asset_refetch={product_asset_refetch}
                  /> */}
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isEnabled}
                        onChange={() => setIsEnabled(!isEnabled)}
                        name="checkedA"
                        id="product_enabled"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label="Enabled"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    type="submit"
                  >
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Column>
        </Row>
      </>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="success">
          Product successfully {`${id ? "updated." : "created."}`}!
        </Alert>
      </Snackbar>
    </div>
  );
};
export default ProductCreate;
