import {
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useContext, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { AccountingSale_Context } from "../Context/Accounting.context";

function ccyFormat(num) {
  return `${num?.toFixed(2)}`;
}

export default function SaleEditable() {
  const {
    handleSaleRemove,
    saleRows,
    invoice_quantity,
    invoice_Subtotal,
    invoice_TaxableAmount,
    invoice_Taxes,
    invoice_Total,
    discount,
    // tax,
    // setTax,
    setDiscount,
    setSaleRows,
  } = useContext(AccountingSale_Context);
  const { products, suppliers } = useSelector(
    (state) => ({
      products: state.persistent.products,
      accounts: state.persistent.accounts,
    }),
    shallowEqual
  );

  const [mouseOverCheck, setMouseOverCheck] = React.useState(null);

  const changeProduct = (e, row, index) => {
    const product_id = e.target.value;
    const newSalesRows = saleRows.slice();
    const newData = products.find((ele) => ele.id === product_id);
    newSalesRows[index] = {
      ...row,
      barcode: newData.code,
      unit: newData.unit,
      product: newData.name,
      product_id: newData.id,
      rate: newData.marketPrice,
      price: parseInt(row.quantity) * parseInt(newData.marketPrice),
    };
    setSaleRows(newSalesRows);
  };
  const changeQuantity = (e, row, index) => {
    const quantity = parseInt(e.target.value);
    const newSalesRows = saleRows.slice();
    console.log("newSalesRows[row]", row);

    newSalesRows[index] = {
      ...row,
      quantity,
      price: parseFloat(quantity) * parseFloat(row.rate),
    };
    setSaleRows(newSalesRows);
  };
  const changeRate = (e, row, index) => {
    const rate = parseInt(e.target.value);

    const newSalesRows = saleRows.slice();

    newSalesRows[index] = {
      ...row,
      rate,
      price: parseInt(rate) * parseInt(row.quantity),
    };
    setSaleRows(newSalesRows);
  };
  return (
    <TableContainer>
      {/* <div className="flex justify-end">
        <IconButton
          color="primary"
          aria-label="Add sale"
          component="span"
          onClick={() => {
            setSaleRows((saleRow) => [...saleRow, {}]);
          }}
        >
          <Add />
        </IconButton>
      </div> */}
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell>SN</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell align="right">Unit</TableCell>
            <TableCell align="right">Qty.</TableCell>
            <TableCell align="right">Rate</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right" colSpan={2}>
              {/* Actions */}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {saleRows?.map((row, index) => (
            <TableRow key={row.product}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                {products?.find((ele) => ele.id == row.product_id)?.name}
              </TableCell>
              <TableCell align="right">{row.unit}</TableCell>

              <TableCell align="right">
                <TextField
                  id="product_quantity"
                  label="Qty"
                  InputProps={{ inputProps: { min: 1 } }}
                  type="number"
                  value={row.quantity}
                  onChange={(e) => changeQuantity(e, row, index)}
                  InputLabelProps={{ shrink: true }}
                />
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="product_rate"
                  label="Rate"
                  type="number"
                  value={row.rate}
                  onChange={(e) => changeRate(e, row, index)}
                  //   onChange={handleProductQuantityChange}
                  InputLabelProps={{ shrink: true }}
                />
              </TableCell>
              <TableCell align="right">
                {row.price ? ccyFormat(row.price) : ""}
              </TableCell>

              <TableCell padding="checkbox">
                <>
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="sd"
                      onClick={(event) => handleSaleRemove(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              </TableCell>
            </TableRow>
          ))}

          {true ? (
            <>
              <TableRow className="netTotal">
                <TableCell colSpan={3}>Net Total</TableCell>
                <TableCell colSpan={1} align="right">
                  {invoice_quantity}
                </TableCell>
                <TableCell colSpan={2} align="right">
                  {invoice_Subtotal}
                </TableCell>
                {/* for delete or edit space */}
                <TableCell colSpan={1}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} colSpan={3} />
                <TableCell colSpan={2}>Discount</TableCell>

                <TableCell align="right">
                  <TextField
                    type="number"
                    id="subtotal_discount"
                    value={discount ? discount : 0}
                    onChange={(e) => {
                      setDiscount(parseInt(e.target.value));
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                  {/* {ccyFormat(invoiceTaxes)} */}
                </TableCell>
                {/* <TableCell rowSpan={3} colSpan={2} /> */}
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Taxable amount</TableCell>
                <TableCell colSpan={1} align="right">
                  {invoice_TaxableAmount}
                  {/* {ccyFormat(invoice_Subtotal)} */}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>Tax (13%)</TableCell>

                <TableCell align="right">{invoice_Taxes}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Grand Total</TableCell>
                <TableCell width={"70px"} align="right">
                  {invoice_Total}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <>
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
