import React, { useEffect, useState } from "react";

const initialThemeState = {
  theme: "theme-1",
  setTheme: () => null,
};

export const ThemeContext = React.createContext(initialThemeState);

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(initialThemeState.theme);
  useEffect(() => {
    const savedThemeLocal = localStorage.getItem("globalTheme");

    if (!!savedThemeLocal) {
      setTheme(savedThemeLocal);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("globalTheme", theme);
  }, [theme]);
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={` height-full-vh theme--${theme} `}>
        <div className="height-full-vh layout-container">{children}</div>
      </div>
    </ThemeContext.Provider>
  );
};
export default ThemeProvider;
