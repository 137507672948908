import { Button, Paper } from "@material-ui/core";
import { Column, Row } from "Components/DesignComponent";
import React, { useContext } from "react";
import AccountingSale_Provider, {
  AccountingPurchase_Context,
} from "./Context/Accounting.context";
import Note from "./Note";
import PaymentContainer from "./PaymentContainer";
import ProductSearch from "./ProductSearch";
import SupplierContainer from "./SupplierContainer";
import TransactionContainer from "./TransactionContainer";

function AccountingServiceContent() {
  const { handleSubmit } = useContext(AccountingPurchase_Context);
  return (
    <>
      <div className="container-account">
        <div className="container-account-title">
          <span>Purchases</span>
        </div>
        <div className="container-account-body">
          <div className="container-account-body-productSearch-parent">
            <ProductSearch />
          </div>
          <div className="mt2">
            <Row>
              <Column lg={8}>
                <TransactionContainer />
                <div className="mt3 accounting-note">
                  <Note />
                </div>
              </Column>
              <Column lg={4}>
                <div>
                  <SupplierContainer />
                </div>
                <div className="mt3">
                  <PaymentContainer />
                </div>
              </Column>
            </Row>
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function AccountingService() {
  return (
    <AccountingSale_Provider>
      <AccountingServiceContent />
    </AccountingSale_Provider>
  );
}
export default AccountingService;
