import { useMutation } from "@apollo/client";
import { Button, Grid, Paper, Snackbar, TextField } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setLoaded } from "Redux/Action/action";
import API from "Services/API";
import TabCustom from "../../Tab/TabCustom";
import CompanyContextProvider, { CompanyContext } from "./company.context";
const CompanyCreate = () => {
  return (
    <CompanyContextProvider>
      <CompanyCreateContent />
    </CompanyContextProvider>
  );
};
const ADD_COMPANY = API.MUTATION.ADD_COMPANY;
const UPDATE_COMPANY = API.MUTATION.UPDATE_COMPANY;
const CompanyCreateContent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const formEl = React.useRef();
  const {
    open,
    setOpen,
    openFileModal,
    setOpenFileModal,
    isEnabled,
    setIsEnabled,
    openSnackBar,
    setOpenSnackBar,
    nameList,
    setNameList,
    imageList,
    setImageList,
    list,
    setList,
    newList,
    setNewList,
    companies
  } = useContext(CompanyContext);
  const getData = companies?.find((ele) => ele.id === parseInt(id));
  useEffect(() => {
    if (getData) {
      const { company_name, company_pan_no, company_phone_no, company_note } =
        formEl.current;
      company_name.value = getData?.name || "";
      company_pan_no.value = getData?.pan_no || "";
      company_phone_no.value = getData?.phone_no || "";
      company_note.value = getData?.note || "";
    }
    return () => {};
  }, [getData]);
  const [createCompany] = useMutation(ADD_COMPANY, {
    onCompleted: ({ createCompany }) => {
      const { company_name, company_pan_no, company_phone_no, company_note } =
        formEl.current;
      setOpenSnackBar(true);
      setList([]);
      setNewList([]);
      setIsEnabled(true);
      company_name.value = null;
      company_pan_no.value = null;
      company_phone_no.value = null;
      company_note.value = null;

      dispatch(setLoaded({ companies: false }));
    }
  });
  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    onCompleted: ({ updateCompany }) => {
      setOpenSnackBar(true);
      dispatch(setLoaded({ companies: false }));
    }
  });
  const handleSubmit = (e) => {
    e.preventDefault();

    const { company_name, company_pan_no, company_phone_no, company_note } =
      formEl.current;
    const companyVariable = {
      name: company_name?.value,
      pan_no: parseInt(company_pan_no?.value),
      phone_no: company_phone_no?.value,
      note: company_note?.value
    };
    if (id) {
      updateCompany({
        variables: { ...companyVariable, id: parseInt(id) }
      });
    } else
      createCompany({
        variables: companyVariable
      });
  };
  const TabList = [
    {
      label: <> {id ? "Company Update" : "Company Create"}</>,
      component: (
        <>
          <form ref={formEl} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper>
                  <TextField
                    required
                    id="company_name"
                    label="Company Name"
                    InputLabelProps={{ shrink: !!getData }}
                  />
                  <br />
                  <TextField
                    id="company_pan_no"
                    type="number"
                    label="pan_no"
                    InputLabelProps={{ shrink: !!getData }}
                  />
                  <br />
                  <TextField
                    required
                    id="company_phone_no"
                    label="Phone no."
                    InputLabelProps={{ shrink: !!getData }}
                  />

                  <br />
                  <TextField
                    id="company_note"
                    label="Note"
                    InputLabelProps={{ shrink: !!getData }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<Add />}
                  type="submit"
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )
    }
  ];
  return (
    <>
      {/* <FormControlLabel
        control={
          <Switch
            checked={true}
            // onChange={handleChange}
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label="Enabled"
      />

      <Divider /> */}
      <TabCustom TabList={TabList} />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="success">
          Company successfully {`${id ? "updated." : "created."}`}!
        </Alert>
      </Snackbar>
    </>
  );
};
export default CompanyCreate;
