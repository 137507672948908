import { useLazyQuery } from "@apollo/client";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Column, Row } from "Components/DesignComponent";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setReferenceBills } from "Redux/Action/action";
import API from "Services/API";
import { AccountingSale_Context } from "../Context/Accounting.context";

const REFERENCE_BILLS = API.QUERY.REFERENCE_BILLS;
function PaymentContainer() {
  const dispatch = useDispatch();
  const {
    register,
    watch,
    control,
    setValue,

    formState: { errors },
  } = useForm();
  const { invoice_Total } = useContext(AccountingSale_Context);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const { customers } = useSelector(
    (state) => ({
      customers: state.persistent.customers,
    }),
    shallowEqual
  );
  const { customerName: customerNameSearch, customerNo } = watch();

  const handleContactNumberChange = (ele) => {
    setOpenDropdown(false);
    setValue("customerName", ele.name);
    setSelectedCustomer(ele);
  };
  const handleCustomerNo = (customerNo) => {
    console.log("customers", customers);

    const findCustomer = customers?.find(
      (ele) => ele.phone === customerNo + ""
    );
    if (findCustomer) {
      setSelectedCustomer(findCustomer);
    } else {
      setSelectedCustomer({
        phone: customerNo,
      });
    }
  };

  useEffect(() => {
    if (customerNo) {
      handleCustomerNo(customerNo);
    }
  }, [customerNo]);
  useMemo(() => {
    if (selectedCustomer) {
      setValue("customerNo", selectedCustomer.phone);
      setValue("customerName", selectedCustomer.name);
    }
  }, [selectedCustomer]);
  console.log("invoice_Total", invoice_Total);
  return (
    <div className="payment-container ">
      <div className="payment-title">
        <span>Payment</span>
      </div>
      <div className="payment-body">
        <div className="payment-customer">
          <Row>
            <Column className="w-100">
              <div className="product-search-container">
                <div>
                  <TextField
                    variant="outlined"
                    label="Customer Name"
                    id="customerName"
                    size="small"
                    className="payment-customerName"
                    onFocus={(e) => {
                      setOpenDropdown(true);
                    }}
                    //after register for blur effect
                    InputLabelProps={{ shrink: true }}
                    {...register("customerName")}
                    onBlur={(e) => {
                      setOpenDropdown(false);
                    }}
                  />
                </div>
                {openDropdown && (
                  <div className="product-search-content">
                    <div className="product-search-dropdown-heading">
                      <div>
                        <span
                          style={{
                            color: "#262626",

                            fontWeight: 600,
                          }}
                        >
                          {/* Recent */}
                        </span>
                      </div>
                      <div
                        onMouseDown={(e) => {
                          e.stopPropagation();
                          // dispatch(setRemoveConnectionURLAll());
                        }}
                      >
                        <span
                          style={{ color: "#0095f6" }}
                          className="pointer-cursor"
                        >
                          Add
                        </span>
                      </div>
                    </div>
                    <ul>
                      {customers
                        ?.sort(function (a, b) {
                          return moment
                            .utc(a.updatedAt)
                            .diff(moment.utc(b.updatedAt));
                        })
                        ?.map((ele) => {
                          let position = ele?.name
                            ?.toLowerCase()
                            ?.search(customerNameSearch?.toLowerCase());
                          if (position >= 0)
                            return (
                              <div
                                className="connection-url-dropdown-content "
                                onMouseDown={(e) => {
                                  // dispatch(
                                  //   setAddConnectionURL({
                                  //     timestamp: moment.now(),
                                  //     url: ele.url,
                                  //   })
                                  // );

                                  handleContactNumberChange(ele);
                                }}
                              >
                                <span>{ele.name}</span>
                              </div>
                            );
                        })}
                    </ul>
                  </div>
                )}
              </div>
            </Column>
          </Row>
          <Row className="mt1">
            <Column className="w-100">
              <TextField
                variant="outlined"
                label="Contact no"
                id="customerNo"
                InputLabelProps={{ shrink: true }}
                size="small"
                className="payment-customerNo"
                {...register("customerNo")}
              />
            </Column>
          </Row>
        </div>

        <div className="payment-module mt4 container-subtitle">
          <div>
            <Row>
              <Column>
                <div className="container-title">Payment Method</div>
              </Column>
            </Row>
            <Row>
              <Column>
                <div>
                  <FormControl>
                    <Controller
                      {...register("payment-method")}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          aria-labelledby="payment-radio-buttons-group-label"
                          {...field}
                        >
                          <FormControlLabel
                            value="cash"
                            id="cash"
                            control={<Radio />}
                            label="Cash"
                          />
                          <FormControlLabel
                            value="bank"
                            id="bank"
                            control={<Radio />}
                            label="Bank Transfer"
                          />
                          <FormControlLabel
                            value="wallet"
                            id="wallet"
                            control={<Radio />}
                            label="E-Wallet"
                          />
                        </RadioGroup>
                      )}
                      control={control}
                      name="payment-method-group"
                    />
                  </FormControl>
                </div>
              </Column>
            </Row>
          </div>
          <div className="mt4">
            <Row>
              <Column className="flex center" sm={12} md={6}>
                <div>Amount Received</div>
              </Column>
              <Column sm={12} md={6}>
                <TextField
                  variant="outlined"
                  id="amt-rec"
                  size="small"
                  type={"number"}
                  className="payment-amt-rec w-100"
                  defaultValue={invoice_Total}
                  {...register("amt-rec")}
                />
              </Column>
            </Row>
            <Row className="mt2">
              <Column className="flex center" sm={12} md={6}>
                <div>Change</div>
              </Column>
              <Column sm={12} md={6}>
                <TextField
                  variant="outlined"
                  id="change"
                  size="small"
                  type={"number"}
                  className="payment-change w-100"
                  {...register("change")}
                />
              </Column>
            </Row>
          </div>
          <div className="payment-total mt4">
            <Row>
              <Column sm={6} className="container-title">
                Total Payment
              </Column>
              <Column sm={6} className="container-title">
                <div>932.95</div>
              </Column>
            </Row>
            <Row className="mt2">
              <Column sm={12}>
                <Button className="btn-payment w-100" variant="contained">
                  PAYMENT
                </Button>
              </Column>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentContainer;
