import { useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  delReferenceBills,
  setAdminJsession,
  setLoaded,
  setAccountModal,
} from "Redux/Action/action";
import API from "Services/API";

import EnhancedTable from "Components/Table/EnhancedTable";
import PurchaseContextProvider, { PurchaseContext } from "./purchase.context";
const REFERENCE_BILLS = API.QUERY.REFERENCE_BILLS;
const DELETE_REFERENCE_BILL = API.MUTATION.DELETE_REFERENCE_BILL;
const Purchase = () => {
  return (
    <PurchaseContextProvider>
      <PurchaseContent />
    </PurchaseContextProvider>
  );
};
const PurchaseContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteReferenceBill] = useMutation(DELETE_REFERENCE_BILL);
  const { referenceBills } = useContext(PurchaseContext);

  const headRows = [
    {
      id: "timeStamp",
      numeric: false,
      disablePadding: true,
      label: "Date/Time",
    },
    {
      id: "bill_id",
      numeric: false,
      disablePadding: true,
      label: "Bill Id",
      width: "170px",
    },
    {
      id: "payment_type_id",
      numeric: true,
      disablePadding: true,
      label: "Payment Type",
    },
    {
      id: "company_name",
      numeric: false,
      disablePadding: true,
      label: "Company",
    },
    {
      id: "user_name",
      numeric: false,
      disablePadding: true,
      label: "User Name",
    },

    {
      id: "Purchases",
      numeric: false,
      disablePadding: true,
      label: "Purchases",
    },

    {
      id: "supplier_name",
      numeric: false,
      disablePadding: true,
      label: "Supplier",
    },
    {
      id: "biller_id",
      numeric: false,
      disablePadding: true,
      label: "Biller",
    },
    {
      id: "payment_status_id",
      numeric: false,
      disablePadding: true,
      label: "Payment Status",
    },
    {
      id: "note",
      numeric: true,
      disablePadding: true,
      label: "Note",
    },
  ];
  const handleOpen = () => {};
  // if (reference_bill_loading) return <>LOADING</>;
  const purchase_data = referenceBills;

  const rows = purchase_data?.filter(
    (ele) => ele?.ReferenceBillType?.title?.toLowerCase() === "purchase"
  );

  function addFn(event) {
    event.preventDefault();
    history.push("purchases/create");
  }
  function updateFn(event, id) {
    event.preventDefault();
    history.push(`purchases/${id}`);
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteReferenceBill({ variables: { id } });
    if (deleteFn?.data?.deleteReferenceBill) {
      dispatch(delReferenceBills(parseInt(id)));

      // reference_bill_refetch();
    }
  }
  const refetchFn = () => {
    dispatch(setLoaded({ referenceBills: false }));
    // reference_bill_refetch();
  };
  return (
    <>
      <br />
      <EnhancedTable
        baseUrl={"/equipments"}
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Purchase"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default Purchase;
