import React from "react";
import SaleEditable from "../SaleEditable";

function TransactionContainer() {
  return (
    <div className="transaction-container">
      <SaleEditable />
    </div>
  );
}

export default TransactionContainer;
