import { useMutation, useQuery } from "@apollo/client";
import { Grid, Modal, Paper, TextField, Button } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setCategoryModal } from "Redux/Action/action";
import UploadFile from "Components/AdminContent/FileUpload/uploadFile";
import API from "Services/API";
const ADD_CATEGORY = API.MUTATION.ADD_CATEGORY;
const UPDATE_CATEGORY = API.MUTATION.UPDATE_CATEGORY;
const CATEGORY = API.QUERY.CATEGORY;
const CategoryModal = ({ type, modal_data }) => {
  const dispatch = useDispatch();
  const inputEl = useRef([]);
  const [imageData, setImageData] = useState(null);

  const handleClose = () => {
    dispatch(setCategoryModal({ modal: false, data: null, type: null }));
  };
  const [addCategory] = useMutation(ADD_CATEGORY);
  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  const { data, error, loading } = useQuery(CATEGORY, {
    skip: type === "add",
    variables: {
      id: modal_data?.id
    }
  });
  const handleAdd = (e) => {
    e.preventDefault();
    const fields = {
      name: inputEl?.current["category_name"]?.value,
      description: inputEl?.current["category_description"]?.value,
      pic: imageData
    };
    console.log(fields);
    addCategory({ variables: fields });
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    const fields = {
      id: modal_data?.id,
      name: inputEl?.current["category_name"]?.value,
      description: inputEl?.current["category_description"]?.value,
      pic: imageData
    };
    console.log(fields);
    updateCategory({ variables: fields });
  };
  if (loading) return "Loading";
  const category_data = data?.parentCategory;
  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-modal-a"
        aria-describedby="simple-modal-descraiption"
      >
        <div className="modalCenter">
          <Paper>
            <h1>{type === "add" ? "ADD CATEGORY" : "UPDATE CATEGORY"}</h1>
            <div className="flex flex.wrap">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div>
                    <img
                      src={
                        imageData
                          ? URL.createObjectURL(imageData)
                          : category_data?.image_file_url
                          ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/${category_data?.image_file_url}`
                          : "https://picsum.photos/125/125"
                      }
                      class="circle-animation"
                      alt={
                        category_data?.image_file_url
                          ? category_data?.image_file_url
                          : "CATEGORY"
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <UploadFile setImageData={setImageData} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="standard-full-width"
                    label="Name"
                    placeholder="Write Category name."
                    helperText="Category name must be unique."
                    defaultValue={category_data?.name}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputRef={(el) => (inputEl.current["category_name"] = el)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    id="margin-none"
                    defaultValue={category_data?.description}
                    helperText="Some important text"
                    inputRef={(el) =>
                      (inputEl.current["category_description"] = el)
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  {type === "add" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAdd}
                    >
                      ADD
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdate}
                    >
                      UPDATE
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
      </Modal>
    </>
  );
};
export default CategoryModal;
