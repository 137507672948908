import {
  Divider,
  Switch,
  TextField,
  Grid,
  Paper,
  FormControlLabel,
  Button,
  Snackbar,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Add } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TabCustom from "../../Tab/TabCustom";
import SupplierContextProvider, { SupplierContext } from "./supplier.context";
import API from "Services/API";
import { useMutation } from "@apollo/client";
import { Alert } from "@material-ui/lab";
import { createSelector } from "reselect";
import { setLoaded } from "Redux/Action/action";
const SupplierCreate = () => {
  return (
    <SupplierContextProvider>
      <SupplierCreateContent />
    </SupplierContextProvider>
  );
};
const ADD_ACCOUNT = API.MUTATION.ADD_ACCOUNT;
const UPDATE_ACCOUNT = API.MUTATION.UPDATE_ACCOUNT;
const SupplierCreateContent = () => {
  const {
    open,
    setOpen,
    openFileModal,
    setOpenFileModal,
    isEnabled,
    setIsEnabled,
    openSnackBar,
    setOpenSnackBar,
    nameList,
    setNameList,
    imageList,
    setImageList,
    list,
    setList,
    newList,
    setNewList,
    accounts,
  } = useContext(SupplierContext);
  const dispatch = useDispatch();
  const formEl = React.useRef();
  const { id } = useParams();
  const [isChecked, setIsChecked] = useState(true);

  const getData = accounts?.find((ele) => ele.id === parseInt(id));
  useEffect(() => {
    if (getData) {
      const {
        supplier_name,
        supplier_pan_no,
        supplier_phone_no,
        supplier_note,
      } = formEl.current;
      supplier_name.value = getData?.name || "";
      supplier_pan_no.value = getData?.pan_no || "";
      supplier_phone_no.value = getData?.phone_no || "";
      supplier_note.value = getData?.note || "";
      setIsChecked(getData?.isSupplier ? getData?.isSupplier : false);
    }
    return () => {};
  }, [getData]);
  const [createAccount] = useMutation(ADD_ACCOUNT, {
    onCompleted: ({ createAccount }) => {
      const {
        supplier_name,
        supplier_pan_no,
        supplier_phone_no,
        supplier_note,
      } = formEl.current;
      setOpenSnackBar(true);
      setList([]);
      setNewList([]);
      setIsEnabled(true);
      supplier_name.value = null;
      supplier_pan_no.value = null;
      supplier_phone_no.value = null;
      supplier_note.value = null;
      dispatch(setLoaded({ accounts: false }));

      // supplier_refetch();
    },
  });
  const [updateAccount] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: ({ updateAccount }) => {
      setOpenSnackBar(true);
      dispatch(setLoaded({ accounts: false }));

      // supplier_refetch();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const { supplier_name, supplier_pan_no, supplier_phone_no, supplier_note } =
      formEl.current;
    const accountVariable = {
      name: supplier_name?.value,
      pan_no: parseInt(supplier_pan_no?.value),
      phone_no: supplier_phone_no?.value,
      note: supplier_note?.value,
      isSupplier: isChecked,
    };
    if (id) {
      updateAccount({
        variables: { ...accountVariable, id: parseInt(id) },
      });
    } else
      createAccount({
        variables: accountVariable,
      });
  };
  const TabList = [
    {
      label: <> {id ? "Supplier Update" : "Supplier Create"}</>,
      component: (
        <>
          <form ref={formEl} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper className="p2">
                  <TextField
                    required
                    id="supplier_name"
                    label="Supplier Name"
                    InputLabelProps={{ shrink: !!getData }}
                  />
                  <br />
                  <TextField
                    id="supplier_pan_no"
                    type="number"
                    label="pan_no"
                    InputLabelProps={{ shrink: !!getData }}
                  />
                  <br />
                  <TextField
                    required
                    id="supplier_phone_no"
                    label="Phone no."
                    InputLabelProps={{ shrink: !!getData }}
                  />

                  <br />
                  <TextField
                    id="supplier_note"
                    label="Note"
                    InputLabelProps={{ shrink: !!getData }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isChecked}
                        onChange={(e) => {
                          // console.log("e", e.target);
                          setIsChecked(!isChecked);
                        }}
                        InputLabelProps={{ shrink: !!getData }}
                        id="is_supplier"
                      />
                    }
                    label="Is Supplier?"
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<Add />}
                  type="submit"
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      ),
    },
  ];
  return (
    <>
      <TabCustom TabList={TabList} />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="success">
          Supplier successfully {`${id ? "updated." : "created."}`}!
        </Alert>
      </Snackbar>
    </>
  );
};
export default SupplierCreate;
