import { useMutation } from "@apollo/client";
import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import TabCustom from "Components/Tab/TabCustom";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setLoaded } from "Redux/Action/action";
import API from "Services/API";
import AccountContextProvider, { AccountContext } from "./context";
const AccountingCreate = () => {
  return (
    <AccountContextProvider>
      <AccountingCreateContent />
    </AccountContextProvider>
  );
};
const ADD_ACCOUNT = API.MUTATION.ADD_ACCOUNT;
const UPDATE_ACCOUNT = API.MUTATION.UPDATE_ACCOUNT;
const AccountingCreateContent = () => {
  const {
    open,
    setOpen,
    openFileModal,
    setOpenFileModal,
    isEnabled,
    setIsEnabled,
    openSnackBar,
    setOpenSnackBar,
    nameList,
    setNameList,
    imageList,
    setImageList,
    list,
    setList,
    newList,
    setNewList,
    accounts,
  } = useContext(AccountContext);
  const dispatch = useDispatch();
  const formEl = React.useRef();
  const { id } = useParams();
  const [isChecked, setIsChecked] = useState(true);
  const getData = accounts?.find((ele) => ele.id === parseInt(id));
  useEffect(() => {
    if (getData) {
      const { account_name, account_pan_no, account_phone_no, account_note } =
        formEl.current;
      account_name.value = getData?.name || "";
      account_pan_no.value = getData?.pan_no || "";
      account_phone_no.value = getData?.phone_no || "";
      account_note.value = getData?.note || "";
      setIsChecked(getData?.isSupplier ? getData?.isSupplier : false);
    }

    return () => {};
  }, [getData]);
  const [createAccount] = useMutation(ADD_ACCOUNT, {
    onCompleted: ({ createAccount }) => {
      const { account_name, account_pan_no, account_phone_no, account_note } =
        formEl.current;
      setOpenSnackBar(true);
      setList([]);
      setNewList([]);
      setIsEnabled(true);
      account_name.value = null;
      account_pan_no.value = null;
      account_phone_no.value = null;
      account_note.value = null;
      dispatch(setLoaded({ accounts: false }));

      // account_refetch();
    },
  });
  const [updateAccount] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: ({ updateAccount }) => {
      setOpenSnackBar(true);
      dispatch(setLoaded({ accounts: false }));

      // account_refetch();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const { account_name, account_pan_no, account_phone_no, account_note } =
      formEl.current;

    const accountVariable = {
      name: account_name?.value,
      pan_no: parseInt(account_pan_no?.value),
      phone_no: account_phone_no?.value,
      note: account_note?.value,
      isSupplier: isChecked,
    };
    if (id) {
      updateAccount({
        variables: { ...accountVariable, id: parseInt(id) },
      });
    } else
      createAccount({
        variables: accountVariable,
      });
  };
  const TabList = [
    {
      label: <> {id ? "Account Update" : "Account Create"}</>,
      component: (
        <>
          <form ref={formEl} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper className="p2">
                  <TextField
                    required
                    id="account_name"
                    label="Account Name"
                    InputLabelProps={{ shrink: !!getData }}
                  />
                  <br />
                  <TextField
                    id="account_pan_no"
                    type="number"
                    label="pan_no"
                    InputLabelProps={{ shrink: !!getData }}
                  />
                  <br />
                  <TextField
                    required
                    id="account_phone_no"
                    label="Phone no."
                    InputLabelProps={{ shrink: !!getData }}
                  />

                  <br />
                  <TextField
                    id="account_note"
                    label="Note"
                    InputLabelProps={{ shrink: !!getData }}
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isChecked}
                        onChange={(e) => {
                          // console.log("e", e.target);
                          setIsChecked(!isChecked);
                        }}
                        InputLabelProps={{ shrink: !!getData }}
                        id="is_supplier"
                      />
                    }
                    label="Is Supplier?"
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<Add />}
                  type="submit"
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      ),
    },
  ];
  return (
    <>
      <TabCustom TabList={TabList} />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="success">
          Account successfully {`${id ? "updated." : "created."}`}!
        </Alert>
      </Snackbar>
    </>
  );
};
export default AccountingCreate;
