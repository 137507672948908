import React from "react";
import PurchaseEditable from "../PurchaseEditable";

function TransactionContainer() {
  return (
    <div className="transaction-container">
      <PurchaseEditable />
    </div>
  );
}

export default TransactionContainer;
