import { useLazyQuery } from "@apollo/client";
import React, { createContext } from "react";
import API from "Services/API";

export const StockListContext = createContext();
const STOCK_LISTS_QUERY = API.QUERY.STOCK_LISTS_QUERY;
const StockListContextProvider = ({ children }) => {
  const [productStockLists, setProductStockLists] = React.useState([]);
  const [fetchStockList] = useLazyQuery(STOCK_LISTS_QUERY, {
    fetchPolicy: "no-cache",

    onCompleted: ({ stockLists }) => {
      if (stockLists?.length > 0) setProductStockLists(stockLists);
    }
  });
  React.useEffect(() => {
    fetchStockList();
  }, []);

  return (
    <StockListContext.Provider
      value={{
        productStockLists
      }}
    >
      {children}
    </StockListContext.Provider>
  );
};
export default StockListContextProvider;
