import { TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { AccountingPurchase_Context } from "../Context/Accounting.context";

function Note() {
  const { note, setNote } = useContext(AccountingPurchase_Context);
  return (
    <div>
      <TextField
        id="note-multiline-static"
        label="Note"
        multiline
        rows={4}
        value={note}
        fullWidth
        onChange={(e) => {
          setNote(e.target.value);
        }}
      />
    </div>
  );
}

export default Note;
