import { useMutation } from "@apollo/client";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import NepaliDate from "nepali-date-converter";
import React, { useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { setLoaded } from "Redux/Action/action";
import API from "Services/API";
import TabCustom from "../../../Tab/TabCustom";
import AccountingService from "./new";
import ProductSelect from "./product.select";
import SaleContextProvider, { SaleContext } from "./sale.context";
import SaleTable from "./sale.table";
import SaleEditable from "./SaleEditable";
const ADD_REFERENCE_BILL = API.MUTATION.ADD_REFERENCE_BILL;
const UPDATE_REFERENCE_BILL = API.MUTATION.UPDATE_REFERENCE_BILL;

const SaleCreate = () => {
  return (
    <>
      <AccountingService />
      <SaleContextProvider>
        <SaleCreateContent />
      </SaleContextProvider>
    </>
  );
};
const SaleCreateContent = () => {
  const { id } = useParams();
  console.log("===id===", id);

  const dispatch = useDispatch();
  const history = useHistory();
  const { products, suppliers } = useSelector(
    (state) => ({
      products: state.persistent.products,
      accounts: state.persistent.accounts,
    }),
    shallowEqual
  );
  const [nepaliDateState, setNepaliDateState] = React.useState(
    new NepaliDate(moment.utc().valueOf()).format("ddd, DD MMMM YYYY")
  );
  const [saleSupplier, setSaleSupplier] = React.useState(null);
  const [payment_type, setPayment_type] = React.useState(null);
  const [payment_status, setPayment_status] = React.useState(null);
  const [bill_id_val, setBill_id_val] = React.useState(null);
  const formEl = React.useRef();
  const [createReferenceBill] = useMutation(ADD_REFERENCE_BILL, {
    onCompleted: (arg) => {
      dispatch(setLoaded({ referenceBills: false }));
      history.push("/admin/sales");
    },
  });
  const [updateReferenceBill] = useMutation(UPDATE_REFERENCE_BILL, {
    onCompleted: ({ updateReferenceBill }) => {
      dispatch(setLoaded({ referenceBills: false }));
      history.push("/admin/sales");
    },
  });
  const {
    open,
    setOpen,
    openFileModal,
    setOpenFileModal,
    isEnabled,
    setIsEnabled,
    openSnackBar,
    setOpenSnackBar,
    nameList,
    setNameList,
    imageList,
    setImageList,
    list,
    setList,
    newList,
    setNewList,
    referenceBills,
    saleRows,
    setSaleRows,
    setDiscount,
    setTax,
    tax,
  } = useContext(SaleContext);
  const getData = referenceBills?.find((ele) => ele.id === parseInt(id));
  React.useEffect(() => {
    if (getData) {
      const { subtotal_discount, subtotal_tax } = formEl.current;
      // bill_id.value = getData?.bill_id;
      // timeStamp.value = getData?.timeStamp;
      setDiscount(parseInt(getData?.discount));
      setTax(parseInt(getData?.tax));

      const saleOldData = getData?.Sales;
      const supplier_id = getData?.supplier_id;
      const payment_status_id = getData?.payment_status_id;
      const payment_type_id = getData?.payment_type_id;
      setSaleRows(
        saleOldData?.map((ele) => {
          const product = products?.find((el) => el.id === ele.product_id);
          return {
            product: product?.name,
            quantity: ele.quantity,
            product_id: ele.product_id,
            price: ele.quantity * ele.rate,
            rate: ele.rate,
            unit: product?.unit,
            barcode: product?.barcode_symbology,
          };
        })
      );
      setSaleSupplier(supplier_id);
      setPayment_status(payment_status_id);
      setPayment_type(payment_type_id);
      setBill_id_val(getData?.bill_id);
    }
    return () => {};
  }, [getData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      supplier_name,
      supplier_pan_no,
      supplier_phone_no,
      supplier_note,
      product_quantity,
      product_name_select,
      product_name_select_label,
      product_name_select_fm,
      timeStamp,
      bill_id,
      subtotal_discount,
      subtotal_tax,
      total,
    } = formEl.current;
    // const bill_id_value = bill_id?.value;
    const subtotal_discount_value = subtotal_discount?.value;
    const subtotal_tax_value = subtotal_tax?.value;
    const timeStamp_value = timeStamp?.value;
    const total_value = total?.value;
    if (saleRows?.length > 0) {
      const dataVar = {
        // bill_id: parseInt(bill_id_value),
        user_id: 1,
        biller_id: 1,
        discount: subtotal_discount_value
          ? parseFloat(subtotal_discount_value)
          : 0,
        tax: subtotal_tax_value ? parseFloat(subtotal_tax_value) : 0,
        grand_total: total_value ? parseFloat(total_value) : 0,
        Sales: saleRows?.map((ele) => {
          return {
            product_id: ele.product_id,
            quantity: ele.quantity,
            rate: ele.rate,
          };
        }),
        timeStamp: timeStamp_value,
        payment_status_id: payment_status,
        supplier_id: saleSupplier,
        payment_type_id: payment_type,
      };
      console.log("dataVar", dataVar);

      if (id && id !== "create") {
        console.log("id", id);
        dataVar.id = parseInt(id);
        updateReferenceBill({
          variables: dataVar,
        });
      } else {
        createReferenceBill({
          variables: dataVar,
        });
      }
    } else {
      setOpenSnackBar(true);
    }
    // const supplierVariable = {
    //   name: supplier_name?.value,
    //   pan_no: parseInt(supplier_pan_no?.value),
    //   phone_no: supplier_phone_no?.value,
    //   note: supplier_note?.value
    // };

    // if (id) {
    //   updateSupplier({
    //     variables: { ...supplierVariable, id: parseInt(id) }
    //   });
    // } else
    //   createSupplier({
    //     variables: supplierVariable
    //   });
  };
  console.log("bill_id_val", bill_id_val);

  const TabList = [
    {
      label: <> {id === "create" ? "Sale Create" : "Sale Update"}</>,
      component: (
        <>
          <form ref={formEl} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper>
                  <Paper elevation={8} className="p3">
                    <Grid container spacing={1} className="p1">
                      <Grid item xs={6}>
                        {id !== "create" && (
                          <TextField
                            size="small"
                            required
                            id="bill_id"
                            type="number"
                            label="Bill no."
                            value={bill_id_val}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <TextField
                              size="small"
                              id="timeStamp"
                              label="Date"
                              type="date"
                              defaultValue={moment().format("YYYY-MM-DD")}
                              InputLabelProps={{ shrink: true }}
                              helperText="Sale date"
                              autoWidth
                              onChange={(e) => {
                                setNepaliDateState(
                                  new NepaliDate(
                                    moment
                                      .utc(formEl.current?.timeStamp?.value)
                                      .valueOf()
                                  ).format("ddd, DD MMMM YYYY")
                                );
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              size="small"
                              id="timeStamp-nepali"
                              label="Nepali Date"
                              value={nepaliDateState}
                              InputLabelProps={{ shrink: true }}
                              disabled
                              autoWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>

                  <Divider />
                  <Paper elevation={8} className="m1 p2">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Paper elevation={8} className="p4">
                          <Grid container>
                            <Grid item xs={12} lg={4}>
                              <FormControl
                                key={
                                  saleSupplier
                                    ? `saleSupplier-${saleSupplier}`
                                    : `saleSupplier-${0}`
                                }
                                required
                              >
                                <InputLabel
                                  id="sale_supplier_id_label"
                                  required
                                >
                                  Supplier
                                </InputLabel>
                                <Select
                                  labelId="sale_supplier_id_label"
                                  id="sale_supplier_id"
                                  // value={age}
                                  label="Supplier"
                                  autoWidth
                                  style={{ minWidth: "100px" }}
                                  required
                                  // onChange={handleChange}
                                  defaultValue={saleSupplier}
                                  onChange={(e) => {
                                    setSaleSupplier(e.target.value);
                                  }}
                                >
                                  {suppliers?.map((ele) => {
                                    return (
                                      <MenuItem value={ele?.id}>
                                        {ele.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                              <FormControl
                                key={
                                  payment_status
                                    ? `payment_status-${payment_status}`
                                    : `payment_status-${0}`
                                }
                                required
                              >
                                <InputLabel id="sale_payment_status_id-label">
                                  Payment Status
                                </InputLabel>
                                <Select
                                  labelId="sale_payment_status_id-label"
                                  id="sale_payment_status_id"
                                  // value={age}
                                  defaultValue={payment_status}
                                  label="Payment Status"
                                  autoWidth
                                  style={{ minWidth: "150px" }}
                                  required
                                  onChange={(e) => {
                                    setPayment_status(e.target.value);

                                    console.log(
                                      "Payment Status",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <MenuItem value={1}>Paid</MenuItem>
                                  <MenuItem value={2}>Pending</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                              <FormControl
                                key={
                                  payment_type
                                    ? `payment_type-${payment_type}`
                                    : `payment_type-${0}`
                                }
                                required
                              >
                                <InputLabel id="sale_payment_type_id-label">
                                  Payment Type
                                </InputLabel>
                                <Select
                                  labelId="sale_payment_type_id-label"
                                  id="sale_payment_type_id"
                                  // value={age}
                                  label="Payment Type"
                                  autoWidth
                                  style={{ minWidth: "150px" }}
                                  required
                                  defaultValue={payment_type}
                                  onChange={(e) => {
                                    setPayment_type(e.target.value);
                                  }}
                                >
                                  <MenuItem value={1}>Cash</MenuItem>
                                  <MenuItem value={2}>Credit Card</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item xs={6}>
                        <Paper elevation={8} className="p4">
                          <Grid container>
                            <Grid item xs={12}>
                              <TextField fullWidth label="Barcode" />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Divider />

                  {/* <Paper elevation={8} className="m2 p2">
                    <SaleTable saleRows={saleRows} setSaleRows={setSaleRows} />
                  </Paper> */}
                  <Paper elevation={8} className="m2 p2">
                    <SaleEditable
                      saleRows={saleRows}
                      setSaleRows={setSaleRows}
                    />
                  </Paper>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<Add />}
                  type="submit"
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      ),
    },
  ];
  return (
    <>
      {/* <FormControlLabel
        control={
          <Switch
            checked={true}
            // onChange={handleChange}
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label="Enabled"
      /> */}

      <Divider />
      <TabCustom TabList={TabList} />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="error">No products are selected.</Alert>
      </Snackbar>
    </>
  );
};
export default SaleCreate;
