import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Snackbar } from "@material-ui/core";
import { createUploadLink } from "apollo-upload-client";
import {
  AdminCompany,
  AdminDashboard,
  AdminLogin,
  Category,
  CompanyCreate,
  Facet,
  FacetsCreate,
  Product,
  ProductCreate,
} from "Components/AdminContent";
import Accounts from "Components/AdminContent/Accounts";
import Customer from "Components/AdminContent/Customer/customer";
import CustomerCreate from "Components/AdminContent/Customer/customer.create";
import Purchase from "Components/AdminContent/ReferenceBill/Purchase/Purchase";
import PurchasesCreate from "Components/AdminContent/ReferenceBill/Purchase/Purchase.create";
import Sale from "Components/AdminContent/ReferenceBill/Sale/Sale";
import SalesCreate from "Components/AdminContent/ReferenceBill/Sale/Sale.create";
import Report from "Components/AdminContent/Report";
import StockList from "Components/AdminContent/StockList/stockList";
import Supplier from "Components/AdminContent/Supplier/supplier";
import SupplierCreate from "Components/AdminContent/Supplier/supplier.create";
import Logout from "Components/Logout/Logout";
import AdminUser from "Components/User/AdminUser";
import { createBrowserHistory } from "history";
import AdminLayout from "Layouts/AdminLayout";
import LoginLayout from "Layouts/LoginLayout";
import MasterLayout from "Layouts/MasterLayout";
import NoRoute from "Layouts/NoRoute";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import AdminRoute from "Router/AdminRoute";
import LoginRoute from "Router/LoginRoute";
import PublicRoute from "Router/PublicRoute";
import { BaseUrl } from "Services/utils";
import ThemeProvider from "Theme/Context";
import ThemeSetter from "Theme/Setter";
import LandingPage from "./Components/LandingPage/landingPage";
import Model from "./Components/Model/model";
import AccountCreate from "Components/AdminContent/Accounts/AccountList/create";
import {
  setAdminJsession,
  setInitialStatePersistentState,
} from "./Redux/Action/action";
import Accounting from "Components/AdminContent/Accounting";
import AccountingCreate from "Components/AdminContent/Accounting/AccountingList/create";
import AccountCategoryCreate from "Components/AdminContent/Accounting/AccountingCategory/create";
import AccountBillCreate from "Components/AdminContent/Accounting/AccountingBills/create";
function App() {
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [snackbarMessage, setOpenSnackBarMessage] = useState("");

  const handleOpenSnackbar = () => {
    setOpenSnackBar(true);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
  };
  const { adminJsession } = useSelector(
    (state) => ({
      adminJsession: state.auth.adminJsession,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    return {
      headers: {
        ...headers,
        authorization: adminJsession ? `Bearer ${adminJsession}` : "",
        "keep-alive": "true",
      },
    };
  });
  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    console.log("graphQLErrors", graphQLErrors);
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, extensions }) => {
        if (extensions.code === "UNAUTHENTICATED") {
          console.log("UNAUTHENTICATED");
          dispatch(setAdminJsession(""));
          dispatch(setInitialStatePersistentState());
        }
        if (extensions.code === "BAD_USER_INPUT") {
          console.log("BAD_USER_INPUT");
          console.log("graphQLErrors", graphQLErrors);
          if (graphQLErrors?.length > 0) {
            graphQLErrors?.map((ele) => {
              setOpenSnackBarMessage(ele?.message);
            });
          }
          handleOpenSnackbar();
        }
      });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });
  const httpLink = authLink.concat(
    createUploadLink({
      uri: BaseUrl,
    })
  );
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, httpLink]),
  });
  return (
    <ApolloProvider client={client}>
      <ThemeProvider>
        <ThemeSetter />
        <AppRoutes />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </ThemeProvider>
    </ApolloProvider>
  );
}

function AppRoutes() {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <Switch>
        {/*
         *if jsession present then it redirects to /requests else login page
         */}
        <PublicRoute
          exact
          path="/"
          component={LandingPage}
          layout={MasterLayout}
        />
        <LoginRoute
          exact
          path="/admin/login"
          component={AdminLogin}
          layout={LoginLayout}
        />
        <AdminRoute
          exact
          path="/admin/dashboard"
          component={AdminDashboard}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin"
          component={AdminDashboard}
          layout={AdminLayout}
        />

        <AdminRoute
          exact
          path="/admin/settings"
          component={NoRoute}
          layout={AdminLayout}
        />

        <AdminRoute
          exact
          path="/admin/account"
          component={AdminUser}
          layout={AdminLayout}
        />
        {/* PRODUCT */}
        <AdminRoute
          exact
          path="/admin/products"
          component={Product}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/products/create"
          component={ProductCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/products/:id"
          component={ProductCreate}
          layout={AdminLayout}
        />
        {/* PRODUCT END */}
        {/* REPORT */}
        <AdminRoute
          exact
          path="/admin/reports"
          component={Report}
          layout={AdminLayout}
        />
        {/* <AdminRoute
          exact
          path="/admin/reports/create"
          component={ProductCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/reports/:id"
          component={ProductCreate}
          layout={AdminLayout}
        /> */}
        {/* REPORT END */}

        {/* SUPPLIER */}
        <AdminRoute
          exact
          path="/admin/suppliers"
          component={Supplier}
          layout={AdminLayout}
        />

        <AdminRoute
          exact
          path="/admin/suppliers/create"
          component={SupplierCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/suppliers/:id"
          component={SupplierCreate}
          layout={AdminLayout}
        />
        {/* SUPPLIER END*/}
        {/* SUPPLIER */}
        <AdminRoute
          exact
          path="/admin/customers"
          component={Customer}
          layout={AdminLayout}
        />

        <AdminRoute
          exact
          path="/admin/customers/create"
          component={CustomerCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/customers/:id"
          component={CustomerCreate}
          layout={AdminLayout}
        />
        {/* SUPPLIER END*/}

        {/* COMAPNY */}
        {/* <PublicRoute
          exact
          path="/company/:id"
          component={CompanyProfile}
          layout={MasterLayout}
        /> */}
        <AdminRoute
          exact
          path="/admin/companies"
          component={AdminCompany}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/companies/create"
          component={CompanyCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/companies/:id"
          component={CompanyCreate}
          layout={AdminLayout}
        />
        {/* COMAPNY END*/}
        {/* FACETS */}
        <AdminRoute
          exact
          path="/admin/facets"
          component={Facet}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/facets/create"
          component={FacetsCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/facets/:id"
          component={FacetsCreate}
          layout={AdminLayout}
        />
        {/* FACETS END*/}
        {/* PURCHASES */}
        <AdminRoute
          exact
          path="/admin/purchases"
          component={Purchase}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/purchases/:id"
          component={PurchasesCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/purchases/create"
          component={PurchasesCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/sales"
          component={Sale}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/sales/:id"
          component={SalesCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/sales/create"
          component={SalesCreate}
          layout={AdminLayout}
        />
        {/* PURCHASES END*/}
        <PublicRoute
          exact
          path="/admin/stock-list"
          component={StockList}
          layout={AdminLayout}
        />
        {/* ACCOUNTING */}
        <AdminRoute
          exact
          path="/admin/accountings"
          component={Accounting}
          layout={AdminLayout}
        />

        <AdminRoute
          exact
          path="/admin/accountings/create"
          component={AccountingCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/accountings/:id"
          component={AccountingCreate}
          layout={AdminLayout}
        />
        {/* ACCOUNTING END*/}
        {/* ACCOUNTING CATEGORY*/}
        {/* <AdminRoute
          exact
          path="/admin/accountingCategories"
          component={Accounting}
          layout={AdminLayout}
        /> */}

        <AdminRoute
          exact
          path="/admin/accountingBills/create"
          component={AccountBillCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/accountingBills/:id"
          component={AccountBillCreate}
          layout={AdminLayout}
        />
        {/* ACCOUNTING CATEGORY END*/}
        {/* ACCOUNTING CATEGORY*/}
        {/* <AdminRoute
          exact
          path="/admin/accountingCategories"
          component={Accounting}
          layout={AdminLayout}
        /> */}

        <AdminRoute
          exact
          path="/admin/accountingCategories/create"
          component={AccountCategoryCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/accountingCategories/:id"
          component={AccountCategoryCreate}
          layout={AdminLayout}
        />
        {/* ACCOUNTING CATEGORY END*/}

        {/* ACCOUNTS */}
        <AdminRoute
          exact
          path="/admin/accounts"
          component={Accounts}
          layout={AdminLayout}
        />

        <AdminRoute
          exact
          path="/admin/accounts/create"
          component={AccountCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/admin/accounts/:id"
          component={AccountCreate}
          layout={AdminLayout}
        />
        {/* ACCOUNTS END*/}
        <PublicRoute
          exact
          path="/login"
          component={NoRoute}
          layout={LoginLayout}
        />
        <AdminRoute
          exact
          path="/admin/logout"
          component={Logout}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/supplier"
          component={Supplier}
          layout={MasterLayout}
        />

        <PublicRoute
          exact
          path="/category"
          component={Category}
          layout={MasterLayout}
        />
        <PublicRoute
          exact
          path="/model"
          component={Model}
          layout={MasterLayout}
        />

        <PublicRoute component={NoRoute} layout={LoginLayout} />
      </Switch>
    </Router>
  );
}
export default App;
