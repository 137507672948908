import { useMutation } from "@apollo/client";
import EnhancedTable from "Components/Table/EnhancedTable";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { delCustomers, setLoaded } from "Redux/Action/action";
import API from "Services/API";
import CustomerContextProvider, { CustomerContext } from "./customer.context";

const CUSTOMERS = API.QUERY.CUSTOMERS;
const DELETE_CUSTOMER = API.MUTATION.DELETE_CUSTOMER;
const Customer = () => {
  return (
    <CustomerContextProvider>
      <CustomerContent />
    </CustomerContextProvider>
  );
};
const CustomerContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER);
  const { customers } = useContext(CustomerContext);
  const headRows = [
    // { id: "id", numeric: true, disablePadding: true, label: "id" },

    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "170px",
    },

    {
      id: "phone_no",
      numeric: false,
      disablePadding: true,
      label: "Phone Number",
    },
    {
      id: "note",
      numeric: false,
      disablePadding: true,
      label: "Note",
    },
  ];
  const handleOpen = () => {};
  const customer_data = customers;
  const rows = customer_data;
  function addFn(event) {
    event.preventDefault();
    history.push("customers/create");
    dispatch(setLoaded({ customers: false }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    history.push(`customers/${id}`);
    // dispatch(
    //   setAccountModal({
    //     modal: true,
    //     data: { id: id },
    //     type: "update"
    //   })
    // );
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteCustomer({ variables: { id } });
    if (deleteFn?.data?.deleteCustomer) {
      // refetch();
      if (id) dispatch(delCustomers(id));
      // dispatch(setLoaded({ Customers: false }));
    }
  }
  const refetchFn = () => {
    // refetch();
    dispatch(setLoaded({ customers: false }));
  };
  return (
    <>
      <br />
      <EnhancedTable
        baseUrl={"/equipments"}
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Customers"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default Customer;
