import { Paper, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import StockListContextProvider, {
  StockListContext
} from "./stockList.context";
import StockListTable from "./stockListTable";
const StockListContent = () => {
  const { productStockLists } = useContext(StockListContext);
  const productStockListsTemp = productStockLists?.slice();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper className="p4">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h2>STOCK LIST</h2>
            </Grid>
            <Grid item xs={12}>
              <StockListTable rows={productStockListsTemp} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const StockList = () => {
  return (
    <>
      <StockListContextProvider>
        <StockListContent />
      </StockListContextProvider>
    </>
  );
};
export default StockList;
