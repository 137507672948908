import { useMutation, useQuery } from "@apollo/client";
import { Grid, Modal, Paper, TextField, Button } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setAccountModal } from "Redux/Action/action";
import API from "Services/API";
import UploadFile from "Components/AdminContent/FileUpload/uploadFile";
import UploadFileW from "Components/AdminContent/FileUpload/uploadFileW";
const ADD_ACCOUNT = API.MUTATION.ADD_ACCOUNT;
const UPDATE_ACCOUNT = API.MUTATION.UPDATE_ACCOUNT;
const SUPPLIER = API.QUERY.ACCOUNT;
const SupplierModal = ({ type, modal_data }) => {
  const dispatch = useDispatch();
  const inputEl = useRef([]);

  const [imageData, setImageData] = useState(null);
  const handleClose = () => {
    dispatch(setAccountModal({ modal: false, data: null, type: null }));
  };
  const [addSupplier] = useMutation(ADD_ACCOUNT);
  const [updateSupplier] = useMutation(UPDATE_ACCOUNT);

  const { data, error, loading } = useQuery(SUPPLIER, {
    skip: type === "add",
    variables: {
      id: modal_data?.id,
    },
  });
  const handleAdd = (e) => {
    e.preventDefault();
    const fields = {
      name: inputEl?.current["supplier_name"]?.value,
      adminId: 1,
    };
    console.log(fields);
    addSupplier({ variables: fields });
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    const fields = {
      id: modal_data?.id,
      name: inputEl?.current["supplier_name"]?.value,
      adminId: 1,
    };
    // console.log(fields);
    if (modal_data?.id) updateSupplier({ variables: fields });
  };
  if (loading) return "Loading";
  const supplier_data = data?.supplier;
  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-modal-a"
        aria-describedby="simple-modal-descraiption"
      >
        <div className="modalCenter">
          <Paper>
            <h1>{type === "add" ? "ADD SUPPLIER" : "UPDATE SUPPLIER"}</h1>
            <div className="flex flex.wrap">
              <Grid container spacing={3}>
                <Grid item xs={23}>
                  <UploadFileW />
                </Grid>
                <Grid item xs={4}>
                  <div>
                    <img
                      src={
                        imageData
                          ? URL.createObjectURL(imageData)
                          : supplier_data?.image_file_url
                          ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/${supplier_data?.image_file_url}`
                          : "https://picsum.photos/125/125"
                      }
                      class="circle-animation"
                      alt={
                        supplier_data?.image_file_url
                          ? supplier_data?.image_file_url
                          : "SUPPLIER"
                      }
                    />
                  </div>
                </Grid>

                <Grid item xs={8}>
                  <UploadFile setImageData={setImageData} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="standard-full-width"
                    label="Name"
                    placeholder="Write supplier name."
                    helperText="Supplier name must be unique."
                    defaultValue={supplier_data?.name}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={(el) => (inputEl.current["supplier_name"] = el)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Year"
                    id="margin-none"
                    defaultValue={supplier_data?.year}
                    helperText="Some important text"
                    inputRef={(el) => (inputEl.current["supplier_year"] = el)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Country"
                    id="margin-none"
                    defaultValue={supplier_data?.country}
                    helperText="Some important text"
                    inputRef={(el) =>
                      (inputEl.current["supplier_country"] = el)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Province"
                    id="margin-none"
                    defaultValue={supplier_data?.province}
                    helperText="Some important text"
                    inputRef={(el) =>
                      (inputEl.current["supplier_province"] = el)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="District"
                    id="margin-none"
                    defaultValue={supplier_data?.district}
                    helperText="Some important text"
                    inputRef={(el) =>
                      (inputEl.current["supplier_district"] = el)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {type === "add" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAdd}
                    >
                      ADD
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdate}
                    >
                      UPDATE
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
      </Modal>
    </>
  );
};
export default SupplierModal;
