import React from "react";
import "../../SCSS/LandingPage.scss";
import BodyContainer from "../BodyContainer/BodyContainer";
import ParallaxItem from "../BodyContainer/ParralaxItem";
import TopBanner from "../TopBanner/TopBanner";
const LandingPage = () => {
  return (
    <>
      {/* <Container className="m1 p1 "> */}
      <ParallaxItem>
        <TopBanner />
      </ParallaxItem>
      <BodyContainer />
      {/* </Container> */}
    </>
  );
};
export default LandingPage;
