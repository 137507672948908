import { useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import { delCompanies, setLoaded, setAccountModal } from "Redux/Action/action";
import API from "Services/API";

import EnhancedTable from "Components/Table/EnhancedTable";
import CompanyContextProvider, { CompanyContext } from "./company.context";
import { useHistory } from "react-router-dom";
const COMPANIES = API.QUERY.COMPANIES;
const DELETE_COMPANY = API.MUTATION.DELETE_COMPANY;
const AdminCompany = () => {
  return (
    <CompanyContextProvider>
      <AdminCompanyContent />
    </CompanyContextProvider>
  );
};
const AdminCompanyContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteCompany] = useMutation(DELETE_COMPANY);
  const { companies } = useContext(CompanyContext);
  // const { data, error, loading, refetch } = useQuery(COMPANIES);
  const headRows = [
    // { id: "id", numeric: true, disablePadding: true, label: "id" },

    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "170px",
    },
    {
      id: "pan_no",
      numeric: false,
      disablePadding: true,
      label: "Pan Number",
    },
    {
      id: "phone_no",
      numeric: false,
      disablePadding: true,
      label: "Phone Number",
    },
    {
      id: "note",
      numeric: false,
      disablePadding: true,
      label: "Note",
    },
  ];
  const handleOpen = () => {};

  const supplier_data = companies;
  const rows = supplier_data;
  function addFn(event) {
    event.preventDefault();
    history.push("companies/create");

    // dispatch(setAccountModal({ modal: true, data: null, type: "add" }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    history.push(`companies/${id}`);

    // dispatch(
    //   setAccountModal({
    //     modal: true,
    //     data: { id: id },
    //     type: "update"
    //   })
    // );
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteCompany({ variables: { id } });
    if (deleteFn?.data?.deleteCompany) {
      dispatch(delCompanies(id));
    }
  }
  const refetchFn = () => {
    dispatch(setLoaded({ companies: false }));
  };
  console.log("companies", companies);
  return (
    <>
      <br />
      {rows.length > 0 && (
        <EnhancedTable
          baseUrl={"/equipments"}
          headRow={headRows}
          rows={rows}
          // history={this.props.history}
          title={"Companies"}
          addFn={addFn}
          updateFn={updateFn}
          deleteFn={deleteFn}
          refetchFn={refetchFn}
        />
      )}
    </>
  );
};
export default AdminCompany;
