import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useDispatch } from "react-redux";
import { setModelModal, setAccountModal } from "Redux/Action/action";
import API from "Services/API";

import EnhancedTable from "Components/Table/EnhancedTable";
const MODELS = API.QUERY.MODELS;
const DELETE_MODEL = API.MUTATION.DELETE_MODEL;
const Model = () => {
  const dispatch = useDispatch();
  const [deleteModel] = useMutation(DELETE_MODEL);
  const { data, error, loading, refetch } = useQuery(MODELS);
  const headRows = [
    // { id: "id", numeric: true, disablePadding: true, label: "id" },
    {
      id: "image_file_url",
      numeric: false,
      disablePadding: true,
      label: "Image",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "170px",
    },

    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: "Description",
    },

    {
      id: "category",
      numeric: false,
      disablePadding: true,
      label: "Category",
    },
    {
      id: "supplier",
      numeric: false,
      disablePadding: true,
      label: "Supplier",
    },
  ];
  const handleOpen = () => {};
  if (loading) return <>LOADING</>;
  console.log("DATA", data);
  const childOneCategories_data = data?.childOneCategories;
  const rows = childOneCategories_data?.map((ele) => {
    return {
      id: ele.id,
      name: ele.name,
      description: ele.description,
      image_file_url: ele.image_file_url,
      supplier: ele.Supplier?.name,
      category: ele.ParentCat?.name,
    };
  });
  function addFn(event) {
    event.preventDefault();
    dispatch(setModelModal({ modal: true, data: null, type: "add" }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    dispatch(
      setModelModal({
        modal: true,
        data: { id: id },
        type: "update",
      })
    );
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteModel({ variables: { id } });
    if (deleteFn?.data?.deleteChildOneCategory?.success) {
      refetch();
    }
  }
  const refetchFn = () => {
    refetch();
  };
  return (
    <>
      <br />
      <EnhancedTable
        baseUrl={"/equipments"}
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Model"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default Model;
