import { Button, TextField } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useSelector } from "react-redux";
import { AccountingSale_Context } from "../Context/Accounting.context";

function ProductSearch() {
  const { handleSaleAdd } = useContext(AccountingSale_Context);
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { products } = useSelector(
    (state) => ({
      products: state.persistent.products,
    }),
    shallowEqual
  );
  const {
    productName: productNameSearch,
    productCode,
    rate,
    quantity,
  } = watch();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const onSubmit = (data, e) => {
    const dataModel = {
      product_id: data.productId,
      rate: data.rate,
      quantity: data.quantity,
      price: data.quantity * data.rate,
    };
    handleSaleAdd(dataModel);
  };
  const onError = (errors, e) => console.log(errors, e);

  const handleProductChange = (ele) => {
    setOpenDropdown(false);
    setValue("productName", ele.name);
    setSelectedProduct(ele);
  };
  const handleCodeChange = (productCode) => {
    const findProduct = products?.find(
      (ele) => ele.barcode_symbology === productCode
    );
    if (findProduct) {
      setSelectedProduct(findProduct);
    } else {
      setSelectedProduct({
        barcode_symbology: productCode,
      });
    }
  };
  useEffect(() => {
    if (productCode) {
      handleCodeChange(productCode);
    }
  }, [productCode]);
  useMemo(() => {
    if (selectedProduct) {
      setValue("productId", selectedProduct.id);
      setValue("productName", selectedProduct.name);
      setValue("productCode", selectedProduct.barcode_symbology);
      setValue("rate", selectedProduct.marketPrice);
      if (!quantity) {
        console.log(quantity);
        setValue("quantity", 1);
      }
    }
  }, [selectedProduct]);

  return (
    <div className="container-account-body-productSearch">
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        style={{ display: "contents" }}
      >
        <input hidden {...register("productId")} />
        <TextField
          variant="outlined"
          label="Product Code"
          id="productCode"
          size="small"
          className="product-search-productCode"
          InputLabelProps={{ shrink: productCode ? true : false }}
          {...register("productCode")}
        />
        <div className="product-search-container">
          <div>
            <TextField
              variant="outlined"
              label="Product Name"
              name="productName"
              id="productName-"
              size="small"
              autoComplete="off"
              className="product-search-productName"
              InputLabelProps={{ shrink: true }}
              onFocus={(e) => {
                setOpenDropdown(true);
              }}
              {...register("productName")}
              //after register for blur effect
              onBlur={(e) => {
                setOpenDropdown(false);
              }}
            />
          </div>
          {openDropdown && (
            <div className="product-search-content">
              <div className="product-search-dropdown-heading">
                <div>
                  <span
                    style={{
                      color: "#262626",

                      fontWeight: 600,
                    }}
                  >
                    {/* Recent */}
                  </span>
                </div>
                <div
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    // dispatch(setRemoveConnectionURLAll());
                  }}
                >
                  <span style={{ color: "#0095f6" }} className="pointer-cursor">
                    Add
                  </span>
                </div>
              </div>
              <ul>
                {products
                  ?.sort(function (a, b) {
                    return moment
                      .utc(a.timeStamp)
                      .diff(moment.utc(b.timeStamp));
                  })
                  ?.map((ele) => {
                    let position = ele?.name
                      ?.toLowerCase()
                      ?.search(productNameSearch?.toLowerCase());
                    if (position >= 0)
                      return (
                        <div
                          className="connection-url-dropdown-content "
                          onMouseDown={(e) => {
                            // dispatch(
                            //   setAddConnectionURL({
                            //     timestamp: moment.now(),
                            //     url: ele.url,
                            //   })
                            // );

                            handleProductChange(ele);
                          }}
                        >
                          <span>{ele.name}</span>
                        </div>
                      );
                  })}
              </ul>
            </div>
          )}
        </div>
        <TextField
          variant="outlined"
          label="Rate"
          InputLabelProps={{ shrink: true }}
          id="rate"
          size="small"
          type={"number"}
          className="product-search-rate"
          {...register("rate")}
        />
        <TextField
          variant="outlined"
          label="Quantity"
          id="quantity"
          size="small"
          type={"number"}
          InputLabelProps={{ shrink: true }}
          className="product-search-quantity"
          {...register("quantity")}
        />
        <Button
          className="product-search-btn-submit"
          variant="contained"
          startIcon={<Add />}
          type="submit"
        >
          Add
        </Button>
      </form>
    </div>
  );
}

export default ProductSearch;
