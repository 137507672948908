import { useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { delFacets, setLoaded } from "Redux/Action/action";
import API from "Services/API";

import EnhancedTable from "Components/Table/EnhancedTable";
import FacetContextProvider, { FacetContext } from "./facets.context";
const FACETS = API.QUERY.FACETS;
const DELETE_FACET = API.MUTATION.DELETE_FACET;

const Facet = () => {
  return (
    <FacetContextProvider>
      <FacetContent />
    </FacetContextProvider>
  );
};
const FacetContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteFacet] = useMutation(DELETE_FACET);
  const { facets } = useContext(FacetContext);

  // const { data, error, loading, refetch } = useQuery(FACETS);
  const headRows = [
    {
      id: "code",
      numeric: false,
      disablePadding: true,
      label: "code",
      width: "170px",
    },
    {
      id: "FacetValues",
      numeric: false,
      disablePadding: true,
      label: "Values",
    },
  ];
  const rows = facets;

  function addFn(event) {
    event.preventDefault();
    history.push("facets/create");
  }
  function updateFn(event, id) {
    event.preventDefault();
    history.push(`facets/${id}`);
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteFacet({ variables: { id } });
    if (deleteFn?.data?.deleteFacet) {
      // refetch();

      dispatch(delFacets(id));
    }
  }
  const refetchFn = () => {
    dispatch(setLoaded({ facets: false }));
    // refetch();
  };
  return (
    <>
      <br />
      <EnhancedTable
        baseUrl={"/facets"}
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Facets"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
        extraFn={rows[{ title: "values", component: <>Hello World</> }]}
      />
    </>
  );
};
export default Facet;
