import React, { useContext } from "react";
import { ThemeContext } from "Theme/Context";

export default function ThemeSetter() {
  const { setTheme } = useContext(ThemeContext);

  return (
    <>
      <div className="theme-btn-container">
        {themeOptions.map((option, idx) => {
          return (
            <div
              class="theme-btn"
              style={{
                background: option.color,
              }}
              key={idx}
              onClick={() => {
                setTheme(option.text);
              }}
            ></div>
          );
        })}
      </div>
      {/* <select value={theme} onChange={(e) => setTheme(e.currentTarget.value)}>
        {themeOptions.map((option, idx) => (
          <option value={option.value} key={idx}>
            {option.value}
          </option>
        ))}
      </select> */}
    </>
  );
}

const themeOptions = [
  {
    text: "theme-1",
    color: "#1a1a2e",
  },
  {
    text: "theme-2",
    color: "#461220",
  },
  {
    text: "theme-3",
    color: "#f7b267",
  },
  {
    text: "theme-4",
    color: "#f25f5c",
  },
  {
    text: "theme-5",
    color: "#231f20",
  },
  {
    text: "theme-6",
    color: "#f1f1f1",
  },
];
